import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { getMonthFromString } from "../../helpers/utils";
import VisaCard from "../../../assets/dashboard/visa.svg";
import MasterCard from "../../../assets/dashboard/mastercard.svg";
import DiscoverCard from "../../../assets/dashboard/discover.svg";
import AmericanCard from "../../../assets/dashboard/american-express.svg";
import CustomizedRadios from "../../common/customDesignedComponents/customRadiobutton";
import "../../../styles/lennarSubscription.scss";

const Paperstyles = {
  "&.MuiPaper-elevation0": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
    borderRadius: "4px",
    p: 3,
    mb: 2,
  },
};

const CreditCardCRUD = ({ cardData = () => {} }) => {
  // store
  const { cardList, cardLoader } = useSelector(
    (state) => state?.lennarSubscription
  );

  const [selectedCardId, setSelectedCardId] = useState("");

  const selectCredit = (e) => {
    console.log(cardList?.data, "Select creditBalance", e.target.value);
    let selectedCardData = (cardList?.data).filter(
      (i) => i.id === parseInt(e.target.value)
    );
    console.log("selectedCardData==>", selectedCardData);
    setSelectedCardId(selectedCardData?.[0]?.id);

    cardData(selectedCardData);
  };

  useEffect(() => {
    cardData(
      (cardList?.success && cardList?.data?.find((i) => i.default === 1)?.id
        ? cardList?.data?.find((i) => i.default === 1)?.exp_year <=
          new Date().getFullYear()
          ? getMonthFromString(
              cardList?.data?.find((i) => i.default === 1)?.exp_month,
              cardList?.data?.find((i) => i.default === 1)?.exp_year
            ) <
            new Date().getMonth() + 1
            ? ""
            : [cardList?.data?.find((i) => i.default === 1)]
          : [cardList?.data?.find((i) => i.default === 1)]
        : "") ||
        (cardList?.data?.[0]?.id
          ? cardList?.data?.[0]?.exp_year <= new Date().getFullYear()
            ? getMonthFromString(
                cardList?.data?.[0]?.exp_month,
                cardList?.data?.[0]?.exp_year
              ) <
              new Date().getMonth() + 1
              ? ""
              : [cardList?.data?.[0]]
            : [cardList?.data?.[0]]
          : "") ||
        null
    );

    setSelectedCardId(
      (cardList?.data?.find((i) => i?.default === 1)?.id
        ? cardList?.data?.find((i) => i?.default === 1)?.exp_year <=
          new Date().getFullYear()
          ? getMonthFromString(
              cardList?.data?.find((i) => i?.default === 1)?.exp_month,
              cardList?.data?.find((i) => i?.default === 1)?.exp_year
            ) <
            new Date().getMonth() + 1
            ? ""
            : cardList?.data?.find((i) => i?.default === 1)?.id
          : cardList?.data?.find((i) => i?.default === 1)?.id
        : "") ||
        (cardList?.data?.[0]?.id
          ? cardList?.data?.[0]?.exp_year <= new Date().getFullYear()
            ? getMonthFromString(
                cardList?.data?.[0]?.exp_month,
                cardList?.data?.[0]?.exp_year
              ) <
              new Date().getMonth() + 1
              ? ""
              : cardList?.data?.[0]?.id
            : cardList?.data?.[0]?.id
          : "") ||
        ""
    );
  }, [cardData, cardList?.data, cardList?.success]);

  return (
    <Box className="step3 documentSteps paymentStep mt-2 lennar-subscription">
      <Paper elevation={0} className="payment-info p-0 p-sm-3" sx={Paperstyles}>
        <RadioGroup
          value={selectedCardId}
          aria-labelledby="payment-types"
          name="payment-modes"
          onChange={selectCredit}
        >
          {Object.keys(cardList).length > 0 &&
            cardList?.data?.map((data, key) => (
              <>
                {/* desktop view */}
                <Box
                  className="d-none d-sm-flex justify-content-center align-items-center"
                  sx={{
                    p: 1,
                    my: 1,
                    mx: 1.5,
                    boxShadow: "10px 10px 10px rgba(52, 75, 91, 0.1)",
                    borderRadius: "4px",
                    backgroundColor: data?.id
                      ? data?.exp_year <= new Date().getFullYear()
                        ? getMonthFromString(data?.exp_month, data?.exp_year) <
                          new Date().getMonth() + 1
                          ? "rgba(204, 204, 204, 0.5)"
                          : false
                        : false
                      : "rgba(204, 204, 204, 0.5)",
                    maxHeight: "65px",
                  }}
                >
                  <FormControlLabel
                    value={data?.id}
                    disableTypography={true}
                    disabled={
                      data?.id
                        ? data?.exp_year <= new Date().getFullYear()
                          ? getMonthFromString(
                              data?.exp_month,
                              data?.exp_year
                            ) <
                            new Date().getMonth() + 1
                            ? true
                            : false
                          : false
                        : true
                    }
                    control={<CustomizedRadios />}
                    sx={{ width: "100%" }}
                    label={
                      <>
                        {/* desktop view */}
                        <Grid container className="pt-2 w-100" key={key}>
                          <Grid
                            className="d-flex justify-content-start"
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            mb={1}
                            // className="d-flex align-items-center"
                          >
                            {data.brand === "MasterCard" ? (
                              <img
                                width={"10%"}
                                src={MasterCard}
                                alt="Master Card"
                              />
                            ) : data.brand === "Visa" ? (
                              <img
                                width={"10% "}
                                src={VisaCard}
                                alt="Visa Card"
                              />
                            ) : data.brand === "American Express" ? (
                              <img
                                width={"10%"}
                                src={AmericanCard}
                                alt="American Card"
                              />
                            ) : data.brand === "Discover" ? (
                              <img
                                width={"10%"}
                                src={DiscoverCard}
                                alt="Discover Card"
                              />
                            ) : (
                              <CreditCardIcon fontSize="large" />
                            )}

                            <Typography
                              variant="caption"
                              className="visa-num ms-3 text-uppercase "
                            >
                              {data.brand} - **** {data.last4}
                              {data.default === 1 && (
                                <>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Chip
                                    sx={{ backgroundColor: "#46b14c33" }}
                                    className="fw-600 text-success display-chip"
                                    label="DEFAULT"
                                  />
                                  <IconButton
                                    className="display-icon"
                                    sx={{ color: "#43D551 !important" }}
                                    disabled={true}
                                  >
                                    <CheckCircleIcon />
                                  </IconButton>
                                </>
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            className="d-flex justify-content-center align-items-center"
                          >
                            Expiry Date, {data.exp_month} {data.exp_year}
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </Box>

                {/* mobile view */}
                <Box
                  className="d-flex d-sm-none"
                  sx={{
                    p: 1,
                    my: 1,
                    mx: 1.5,
                    borderRadius: "4px",
                    backgroundColor: data?.id
                      ? data?.exp_year <= new Date().getFullYear()
                        ? getMonthFromString(data?.exp_month, data?.exp_year) <
                          new Date().getMonth() + 1
                          ? "rgba(204, 204, 204, 0.5)"
                          : false
                        : false
                      : "rgba(204, 204, 204, 0.5)",
                    maxHeight: "65px",
                  }}
                >
                  <FormControlLabel
                    value={data?.id}
                    control={<CustomizedRadios />}
                    disabled={
                      data?.id
                        ? data?.exp_year <= new Date().getFullYear()
                          ? getMonthFromString(
                              data?.exp_month,
                              data?.exp_year
                            ) <
                            new Date().getMonth() + 1
                            ? true
                            : false
                          : false
                        : true
                    }
                    label={
                      <>
                        {/* mobile view */}
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="space-between"
                        >
                          <Stack direction="column">
                            <Box
                              sx={{
                                minWidth: data.default === 1 ? "200px" : "",
                              }}
                            >
                              {data.brand === "MasterCard" ? (
                                <img
                                  width={"25px"}
                                  src={MasterCard}
                                  alt="Master Card"
                                />
                              ) : data.brand === "Visa" ? (
                                <img
                                  width={"25px"}
                                  src={VisaCard}
                                  alt="Visa Card"
                                />
                              ) : data.brand === "American Express" ? (
                                <img
                                  width={"25px"}
                                  src={AmericanCard}
                                  alt="American Card"
                                />
                              ) : data.brand === "Discover" ? (
                                <img
                                  width={"25px"}
                                  src={DiscoverCard}
                                  alt="Discover Card"
                                />
                              ) : (
                                <CreditCardIcon fontSize="large" />
                              )}
                              <Typography
                                variant="caption"
                                className="visa-num ms-2 text-uppercase"
                                sx={{ mr: 1 }}
                              >
                                **** {data.last4}
                              </Typography>
                              {data.default === 1 && (
                                <IconButton
                                  sx={{ color: "#43D551 !important" }}
                                  disabled={true}
                                >
                                  <CheckCircleIcon />
                                </IconButton>
                              )}
                            </Box>
                            <Typography fontSize="12px">
                              Expiry Date, {data.exp_month} {data.exp_year}
                            </Typography>
                          </Stack>
                        </Stack>
                      </>
                    }
                  />
                </Box>
              </>
            ))}

          {!cardList?.data?.length > 0 && (
            <Typography className="p-5 text-center" variant="h6">
              No Card Found. Please add the Credit card.
            </Typography>
          )}
        </RadioGroup>
      </Paper>
      <Loader open={cardLoader} />
    </Box>
  );
};

export default CreditCardCRUD;
