import React, { lazy, Suspense, useEffect } from "react";

import Slider from "react-slick";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import { Stack } from "@mui/material";

import { Loader } from "../common/loader";
import { reactPixelEvent } from "../helpers/utils";
import InvestorsPlansContent from "./InvestorsPlansContent";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../styles/pricing.scss";

const RealStoryDesign = lazy(() => import("../common/RealStoryDesign"));
const Testimonials = lazy(() => import("../common/Testimonials"));
const HowItWorksSection = lazy(() => import("../common/HowItWorksSection"));

const InvestorPricing = () => {
  const dispatch = useDispatch();

  // store data
  const { statesLoader } = useSelector((state) => state?.signUpUser);

  useEffect(() => {
    dispatch(
      userSigupAction.getSubscriptionPlan({
        url: "get-plans",
        data: {},
        type: "post",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    reactPixelEvent("Investor Pricing", {});
  }, []);

  var settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="home-content investor_pricing">
        <section className="pricing_background_img">
          <Container fluid className="h-100 text-white">
            <Stack
              className="top_row"
              display={"flex"}
              justifyContent={"center"}
              direction={"row"}
            >
              <div className="header_sections">
                <h1 className="title_texts">
                  Affordable Investment Protection Pricing
                </h1>
              </div>
              <div className="header_sections">
                <p className="subHeader_texts p__1">
                  Most property title lock companies charge around{" "}
                  <span className="fw-700">$250 per year just to monitor</span>{" "}
                  your property. Over five years, that's $1,000 spent-only to be
                  notified if something happens.
                </p>
                <p className="subHeader_texts">
                  <span className="fw-700">EquityProtect</span> does more. We
                  don't just alert you-we stop financial crimes before they
                  happen.
                </p>
                <p className="subHeader_texts">
                  For as little as $6 per month, you get real protection, with
                  exclusive discounts for investors with multiple properties.
                </p>
                <p className="subHeader_texts">
                  Safeguard your investments affordably with{" "}
                  <span className="fw-700">EquityProtect.</span>
                </p>
              </div>
            </Stack>
          </Container>
        </section>
        <section className="reviews_wrapper mb-4">
          <Container fluid className="mx-150">
            {!statesLoader && (
              <Slider {...settings} className="plans_pricing">
                <div className=" planCard card__1 d-flex flex-column card card-col mb-0 mb-lg-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <LazyLoadComponent threshold={100}>
                      <InvestorsPlansContent plan1={true} plan2={false} />
                    </LazyLoadComponent>
                  </Suspense>
                </div>
                <div className=" planCard card__2 d-flex flex-column card card-col mb-0 mb-lg-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <LazyLoadComponent threshold={100}>
                      <InvestorsPlansContent plan1={false} plan2={true} />
                    </LazyLoadComponent>
                  </Suspense>
                </div>
              </Slider>
            )}
          </Container>
        </section>

        <section className="pt-5 howWorks_section">
          <Container fluid className="px-3 px-md-5">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyLoadComponent threshold={100}>
                <HowItWorksSection />
              </LazyLoadComponent>
            </Suspense>
          </Container>
        </section>

        <section className="pt-150 banner_section">
          <Container fluid className="px-3 px-md-5">
            <div className=" text-center">
              <div className="mb-5">
                <p className="notify_header">People trust us</p>
                <h2 className="sub_header">You’re in good company with us</h2>
              </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyLoadComponent threshold={100}>
                <Testimonials />
              </LazyLoadComponent>
            </Suspense>
          </Container>
        </section>

        <section className="pt-150 pb-150 story_section">
          <Container fluid className="mx-150">
            <div>
              <Suspense fallback={<div>Loading...</div>}>
                <LazyLoadComponent threshold={100}>
                  <RealStoryDesign />
                </LazyLoadComponent>
              </Suspense>
            </div>
          </Container>
        </section>
      </div>
      <Loader open={statesLoader} />
    </>
  );
};

export default React.memo(InvestorPricing);
