import { createSlice } from "@reduxjs/toolkit";
import { encryptPayload } from "../../../../components/helpers/utils";

const initialPropertyRiskManagementState = {
  riskManagementLoader: false,
  propertyLoanAmountData: {},
  riskManagementData: {},
  riskManagementPayload: {},
  countyLoader: false,
  propertyData: {},
  countyData: {},
  monitoredPropertyListLoader: false,
  monitoredPropertyList: {},
  navbarColored: false,
  resendReportMailData: {},
  investorReportResultData: {},
  investorMonitoringData: {},
  investorReportResultsLoader: false,
  investorFormDetails: {},
  optionType: null,
  premiumMonitoringProperties: [],
  selectedPropertiesData: {},
  investorProeprtyMonitoringData: {},
  investorLoginData: {},
  investorBindEmailData: {},
  investorLoginPayload: {},
  investorsDataFromEmail: {},
  addInnvestorsPropertyData: {},
  invToken: null,
  sendWelcomeEmailData: {},
};

const propertyRiskManagement = createSlice({
  name: "propertyRiskManagement",
  initialState: initialPropertyRiskManagementState,
  reducers: {
    getPropertyLoanAmountData: (state, action) => {
      state.riskManagementLoader = true;
      state.riskManagementPayload = action?.payload?.data;
      console.log("LoanAmount slice", action.payload);
    },
    fetchPropertyLoanAmountData: (state, action) => {
      console.log("fetch LoanAmount data slice", action.payload);
      state.riskManagementLoader = false;
      state.propertyLoanAmountData = action.payload;
    },
    propertyRiskManagementData: (state, action) => {
      state.riskManagementLoader = true;
      state.riskManagementPayload = action?.payload?.data;
      console.log("propertyRiskManagement slice", action.payload);
    },
    fetchPropertyRiskManagementData: (state, action) => {
      console.log("fetch propertyRiskManagement data slice", action.payload);
      state.propertyLoanAmountData = {};
      state.riskManagementLoader = false;
      if (action?.payload?.success) {
        state.propertyData = action.payload;
      }
      state.riskManagementData = action.payload;
    },
    clearPropertyRiskManagementPayload: (state, action) => {
      state.riskManagementPayload = {};
      // state.riskManagementLoader = false;
    },
    clearPropertyRiskManagementData: (state, action) => {
      state.propertyData = {};
      state.riskManagementLoader = false;
      state.riskManagementData = {};
      state.investorLoginPayload = {};
      state.investorsDataFromEmail = {};
    },
    countyData: (state, action) => {
      state.countyLoader = true;
      console.log("countyData slice", action.payload);
    },
    fetchCountyData: (state, action) => {
      console.log("fetch countyData data slice", action.payload);
      state.countyLoader = false;
      if (action.payload?.success) {
        localStorage.setItem("scrp_hp", 1);
        localStorage.setItem(
          "county-code",
          encryptPayload(action.payload?.data?.county_domain_name)
        );
      } else {
        localStorage.removeItem("scrp_hp");
        localStorage.removeItem("county-code");
      }
      state.countyData = action.payload;
    },
    saveEmailDetails: (state, action) => {
      state.riskManagementLoader = true;
      console.log("saveEmailDetails slice", action.payload);
    },
    fetchSaveEmailDetails: (state, action) => {
      console.log("fetcho saveEmailDetails slice", action.payload);
      state.riskManagementLoader = false;
      state.saveEmailDetails = action.payload;
    },
    subscribeFreeMonitoring: (state, action) => {
      state.riskManagementLoader = true;
      console.log("subscribeFreeMonitoring slice", action.payload);
    },
    fetchSubscribeFreeMonitoring: (state, action) => {
      console.log("fetcho subscribeFreeMonitoring slice", action.payload);
      state.riskManagementLoader = false;
      state.subscribeFreeMonitoring = action.payload;
    },
    freeMonitoringList: (state, action) => {
      state.monitoredPropertyListLoader = true;
      console.log("subscribeFreeMonitoring slice", action.payload);
    },
    fetchFreeMonitoringList: (state, action) => {
      console.log("fetcho subscribeFreeMonitoring slice", action.payload);
      state.monitoredPropertyListLoader = false;
      state.monitoredPropertyList = action.payload;
    },
    deleteMonitoredProperty: (state, action) => {
      state.riskManagementLoader = true;
      console.log("subscribeFreeMonitoring slice", action.payload);
    },
    fetchDeletedMonitoredPropertyData: (state, action) => {
      console.log("fetcho deleteMonitoredProperty slice", action.payload);
      state.riskManagementLoader = false;
      state.deleteMonitoredPropertyData = action.payload;
    },
    setNavbarColor: (state, action) => {
      state.navbarColored = action.payload;
    },
    fetchResendReportMailData: (state, action) => {
      state.riskManagementLoader = true;
      console.log("subscribeFreeMonitoring slice", action.payload);
    },
    getResendReportMailData: (state, action) => {
      console.log("fetcho deleteMonitoredProperty slice", action.payload);
      state.riskManagementLoader = false;
      state.resendReportMailData = action.payload;
    },
    getInvestorReportResultData: (state, action) => {
      console.log("fetcho getInvestorReportResultData slice", action.payload);
      state.investorReportResultsLoader = true;
      state.invToken = action.payload?.data?.token;
    },
    fetchInvestorReportResultData: (state, action) => {
      state.investorReportResultData = action.payload;
      state.investorReportResultsLoader = false;
      console.log("getInvestorReportResultData slice", action.payload);
    },
    getInvestorMonitoringData: (state, action) => {
      console.log("fetcho InvestorMonitoringData slice", action.payload);
      state.investorFormDetails = action.payload?.data;
      state.investorReportResultsLoader = true;
    },
    resetInvestorFormDetails: (state, action) => {
      state.investorFormDetails = {};
    },
    fetchInvestorMonitoringData: (state, action) => {
      state.investorMonitoringData = action.payload;
      state.investorReportResultsLoader = false;
      console.log("InvestorMonitoringData slice", action.payload);
    },
    setOptionType: (state, action) => {
      state.optionType = action.payload;
    },
    selectedPropertiesForPremiumMonitoring: (state, action) => {
      state.premiumMonitoringProperties = action.payload;
    },
    selectedPropertiesData: (state, action) => {
      state.selectedPropertiesData = action.payload;
    },
    getInvestorsPropertyServiceData: (state, action) => {
      state.investorReportResultsLoader = true;
    },
    fetchInvestorsPropertyServiceData: (state, action) => {
      state.investorProeprtyMonitoringData = action.payload;
      state.investorReportResultsLoader = false;
    },
    getInvestorsLoginData: (state, action) => {
      state.investorReportResultsLoader = true;
      state.investorLoginPayload = action.payload?.data;
    },
    fetchInvestorsLoginData: (state, action) => {
      state.investorLoginData = action.payload;
      state.investorReportResultsLoader = false;
    },
    getInvestorsBindEmailData: (state, action) => {
      state.investorReportResultsLoader = true;
    },
    fetchInvestorsBindEmailData: (state, action) => {
      state.investorBindEmailData = action.payload;
      state.investorReportResultsLoader = false;
    },
    getInvestorsData: (state, action) => {
      state.investorReportResultsLoader = true;
    },
    fetchInvestorsData: (state, action) => {
      state.investorsDataFromEmail = action.payload;
      state.investorReportResultsLoader = false;
    },
    getInvestorsAddPropertyData: (state, action) => {
      state.investorReportResultsLoader = true;
    },
    fetchInvestorsAddPropertyData: (state, action) => {
      state.addInnvestorsPropertyData = action.payload;
      state.investorReportResultsLoader = false;
    },
    getSendWelcomeEmailData: (state, action) => {
      state.investorReportResultsLoader = true;
    },
    fetchSendWelcomeEmailData: (state, action) => {
      state.sendWelcomeEmailData = action.payload;
      state.investorReportResultsLoader = false;
    },
  },
});
export const propertyRiskManagementAction = propertyRiskManagement.actions;
export default propertyRiskManagement.reducer;
