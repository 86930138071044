import { call, put, takeEvery } from "redux-saga/effects";

import { getAPIPayload } from "../../../apis/getApi";
import { postAPIPayload } from "../../../apis/postApi";
import { userSigupAction } from "../../slices/user/userSignUpSlice";

export function* getUserSignupSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigupAction.fetchSuccess(response));
}

export function* getFormStatesSaga({ payload }) {
  console.log("in sagas--", payload);
  console.log("state api called in saga", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("state response==>.", response);
  yield put(userSigupAction.fetchSateData(response));
}

export function* verifyotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigupAction.fetchOtpData(response));
}

export function* resendotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigupAction.fetchNewOtpData(response));
}

export function* verifyEmailotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigupAction.fetchEmailOtpData(response));
}

export function* verifyResumeProcessSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigupAction.fetchResumeData(response));
}

export function* resendEmailotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigupAction.fetchNewEmailOtpData(response));
}

export function* getStatesCodesSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("county response==>.", response);
  yield put(userSigupAction.fetchSateCodeData(response));
}

export function* getCountyListSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("statecodes response==>.", response);
  yield put(userSigupAction.fetchCountyData(response));
}

export function* getUserPropertySaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Property response==>.", response);
  yield put(userSigupAction.fetchUserPropertyData(response));
}

export function* getgenerateQuestionsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Property response==>.", response);
  yield put(userSigupAction.fetchGeneratedQuestionsData(response));
}

export function* getVerifyAnswersSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Property response==>.", response);
  yield put(userSigupAction.fetchVerifedAnswersData(response));
}

export function* getSkipVerifyAnswersSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Property response==>.", response);
  yield put(userSigupAction.fetchSkipVerifyAnswers(response));
}

export function* getPropertiesListSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Property response==>.", response);
  yield put(userSigupAction.fetchgetPropertiesListData(response));
}
export function* getDeletedPropertiesListSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Property response==>.", response);
  yield put(userSigupAction.deletePropertiesResponse(response));
}

export function* skipRegisterFaceStepsaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user skip  RegisterFace response==>.", response);
  yield put(userSigupAction.skipRegisterFaceStepData(response));
}

export function* getRegisterFaceSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user RegisterFace response==>.", response);
  yield put(userSigupAction.fetchRegisterFace(response));
}

export function* getRegisterSignatureSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user RegisterFace response==>.", response);
  yield put(userSigupAction.fetchRegisterSignature(response));
}

export function* getMailingAddressSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Mailing Address response==>.", response);
  yield put(userSigupAction.fetchMailingAddress(response));
}

export function* saveCreditCardInfoSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Mailing Address response==>.", response);
  yield put(userSigupAction.fetchCreditCardInfo(response));
}

export function* getSubscriptionPlanSaga({ payload }) {
  console.log("in sagas--", payload);
  let response;
  if (payload?.type === "post") {
    response = yield call(postAPIPayload, payload);
  } else {
    response = yield call(getAPIPayload, payload);
  }
  console.log("SubscriptionPlan response==>.", response);
  yield put(userSigupAction.fetchSubscriptionPlan(response));
}

export function* getTotalPaymentDetailSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user fetchTotalPaymentDetails response==>.", response);
  yield put(userSigupAction.fetchTotalPaymentDetails(response));
}

export function* getPaymentSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user fetchPaymentData response==>.", response);
  yield put(userSigupAction.fetchPaymentData(response));
}

export function* getIsPaymentDoneSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("user fetchPaymentData response==>.", response);
  yield put(userSigupAction.fetchIsPaymentDone(response));
}

export function* addBeneficiaryDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set data in redux store
  yield put(userSigupAction.setBeneficiaryDetails(response));
}

export function* fetchBeneficiaryListSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set data in redux store
  yield put(userSigupAction.setBeneficiaryList(response));
}

export function* skipBeneficiaryStepsaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user skip  Beneficiary response==>.", response);
  yield put(userSigupAction.skipBeneficiaryStepData(response));
}

export function* sendSignUpMailSaga({ payload }) {
  console.log("send api payload", payload);
  const response = yield call(postAPIPayload, payload);

  // set data in redux store
  yield put(userSigupAction.setSendMailDetails(response));
}

export function* verifyResumeEmailotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigupAction.fetchResumeProcessData(response));
}

export function* userSecurityPinSaga({ payload }) {
  console.log("send api payload", payload);
  const response = yield call(postAPIPayload, payload);
  // set data in redux store
  yield put(userSigupAction.fetchUserSecurityPin(response));
}

export function* userLoggedInSaga({ payload }) {
  console.log("send api payload", payload);
  const response = yield call(postAPIPayload, payload);
  // set data in redux store
  yield put(userSigupAction.fetchUserLoggedInData(response));
  // yield put(userSigInAction.fetchUserData(response));
}

export function* fetchInvitationDetailsSaga({ payload }) {
  console.log("send api payload", payload);
  const response = yield call(postAPIPayload, payload);
  yield put(userSigupAction.fetchInvitationDetails(response));
}

export function* checkreCAPTCHASaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(userSigupAction.setreCAPTCHA(response));
}

export function* fetchRiskReportDetails({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(userSigupAction.fetchRiskReportDetails(response));
}

export function* fetchOTPVerificationData({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(userSigupAction.fetchOTPVerificationData(response));
}

export function* fetchInvestorsAnnualPlanDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(userSigupAction.fetchInvestorsAnnualPlanData(response));
}

export function* watchSignupUsersAsync() {
  yield takeEvery(userSigupAction.userSignupData, getUserSignupSaga);
  yield takeEvery(userSigupAction.getState, getFormStatesSaga);
  yield takeEvery(userSigupAction.userVerifyOTP, verifyotpSaga);
  yield takeEvery(userSigupAction.resedOTP, resendotpSaga);
  yield takeEvery(userSigupAction.userVerifyEmailOTP, verifyEmailotpSaga);
  yield takeEvery(
    userSigupAction.userVerifyResumeEmail,
    verifyResumeProcessSaga
  );
  yield takeEvery(userSigupAction.resedEmailOTP, resendEmailotpSaga);
  yield takeEvery(userSigupAction.getCountyList, getCountyListSaga);
  yield takeEvery(userSigupAction.getStateCode, getStatesCodesSaga);
  yield takeEvery(userSigupAction.getUserProperty, getUserPropertySaga);
  yield takeEvery(userSigupAction.generateQuestions, getgenerateQuestionsSaga);
  yield takeEvery(userSigupAction.verifyAnswers, getVerifyAnswersSaga);
  yield takeEvery(userSigupAction.skipVerifyAnswers, getSkipVerifyAnswersSaga);
  yield takeEvery(userSigupAction.getPropertiesList, getPropertiesListSaga);
  yield takeEvery(
    userSigupAction.deleteProperties,
    getDeletedPropertiesListSaga
  );
  yield takeEvery(userSigupAction.getRegisterFace, getRegisterFaceSaga);
  yield takeEvery(
    userSigupAction.skipRegisterFaceStep,
    skipRegisterFaceStepsaga
  );
  yield takeEvery(
    userSigupAction.getRegisterSignature,
    getRegisterSignatureSaga
  );
  yield takeEvery(userSigupAction.getMailingAddress, getMailingAddressSaga);
  yield takeEvery(userSigupAction.saveCreditCardInfo, saveCreditCardInfoSaga);
  yield takeEvery(userSigupAction.getSubscriptionPlan, getSubscriptionPlanSaga);
  yield takeEvery(
    userSigupAction.getTotalPaymentDetails,
    getTotalPaymentDetailSaga
  );
  yield takeEvery(userSigupAction.getPayment, getPaymentSaga);
  yield takeEvery(userSigupAction.getIsPaymentDone, getIsPaymentDoneSaga);
  yield takeEvery(
    userSigupAction.addBeneficiaryDetails,
    addBeneficiaryDetailsSaga
  );
  yield takeEvery(
    userSigupAction.fetchBeneficiaryList,
    fetchBeneficiaryListSaga
  );
  yield takeEvery(userSigupAction.skipBeneficiaryStep, skipBeneficiaryStepsaga);
  yield takeEvery(userSigupAction.sendSignUpMail, sendSignUpMailSaga);
  yield takeEvery(
    userSigupAction.getResumeProcessEmail,
    verifyResumeEmailotpSaga
  );
  yield takeEvery(userSigupAction.userSecurityPin, userSecurityPinSaga);
  yield takeEvery(userSigupAction.userLoginData, userLoggedInSaga);
  yield takeEvery(
    userSigupAction.getInvitationDetails,
    fetchInvitationDetailsSaga
  );
  yield takeEvery(userSigupAction.checkreCAPTCHA, checkreCAPTCHASaga);
  yield takeEvery(userSigupAction.getRiskReportDetails, fetchRiskReportDetails);
  yield takeEvery(
    userSigupAction.getOTPVerificationData,
    fetchOTPVerificationData
  );
  yield takeEvery(
    userSigupAction.getInvestorsAnnualPlanData,
    fetchInvestorsAnnualPlanDataSaga
  );
}
