import React, { useCallback, useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  Box,
  Collapse,
  Container,
  TableCell,
  TableHead,
} from "@mui/material";
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import DialogBox from "../common/DialogBox";
import AlertDialog from "../common/AlertDialog";
import { PER_PAGE } from "../helpers/constants";
import ToggleSwitch from "../common/OnOffSwitch";
import BrokerTopSection from "./BrokerTopSection";
import AddAgentProperties from "./AddAgentProperties";
import { BrokerSignupPage } from "./BrokerSignupPage";
import SettingsIconSVG from "../common/SettingsIconSVG";
import CustomTextField from "../common/CustomTextField";
import checkImg from "../../assets/icons/check_blk.svg";
import PlainAlertMessage from "../common/PlainAlertMessage";
import BrokersPublicPageFooter from "./BrokersPublicPageFooter";
import { brokerAgentsAction } from "../../redux/slices/brokers";
import CustomPhoneNumberInput from "../common/CustomPhoneNumberInput";
import { StyledTableCell, StyledTableRow } from "../investor/list/ListTable";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";
import {
  getFormatedPhoneNumber,
  getObjLength,
  riskLevelCount,
} from "../helpers/utils";

import "./BrokerSignupPage.scss";
import "../county-recorder/ReportResultsInvestor.scss";

const proeprtyValidationSchema = Yup.object().shape({
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  phone: Yup.string("Please enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Mobile number is required"),
});

export const BrokerProperties = ({ base_route = "broker" }) => {
  const dispatch = useDispatch();
  const { broker } = useParams();
  const navigate = useNavigate();
  const agent_id = localStorage.getItem("agent_id");
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [finalProperties, setFinalProperties] = useState([]);
  const [isOwnerInfoAdded, setIsOwnerInfoAddeds] = useState(false);
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [addPropertyModalOpen, setAddPropertyModalOpen] = useState(false);
  const [agentProfileModalOpen, setAgentProfileModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [payload, setPayload] = useState({ offset: 0, limit: PER_PAGE });

  const {
    saveFreeMonitortingData,
    agentPropertyListData,
    agentProfileLoader,
    startMarketringMailData,
    addNewAgentPropertyData,
    agentProfileData,
    updateAgentProfileData,
    profileFormData,
    agentPropertyLoader,
  } = useSelector((state) => state?.brokerAgents);
  let freeMonitoringToggleValue = {};

  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({
      ...prevState,
      offset: (p - 1) * PER_PAGE,
    }));
  };

  const isSelected = (properties = selectedProperties, id) => {
    // Check if the row is selected based on its id
    return properties?.some((selectedRow) => selectedRow?.id === id);
  };

  const addOwnerDataToselectProperties = (row, ownerInfo, isEdit) => {
    console.log("row selected==>", row);
    setSelectedProperties((prevSelectedProperties) =>
      prevSelectedProperties.map(
        (property) =>
          property.id === row.id
            ? { ...property, ...ownerInfo, isEdit } // Update the 'enable' value
            : property // Keep other properties unchanged
      )
    );
  };

  const selectProperties = (toggleVal, row, ownerInfo) => {
    console.log(toggleVal, "row selected==>", row);

    // Add the row to the selected rows (full row data)
    if (!isSelected(selectedProperties, row.id)) {
      console.log("adding row");

      setSelectedProperties((prevSelectedProperties) => [
        ...prevSelectedProperties,
        { ...row, enable: toggleVal },
      ]);
    } else {
      console.log("remove row");
      // If the row is already in the state, update its 'enable' value immutably
      setSelectedProperties((prevSelectedProperties) =>
        prevSelectedProperties.map(
          (property) =>
            property.id === row.id
              ? { ...property, enable: toggleVal } // Update the 'enable' value
              : property // Keep other properties unchanged
        )
      );
    }
  };

  const closeApprovalModal = () => {
    setApprovalModalOpen(false);
    propertyListAPICall();
  };

  const startMarketringMail = () => {
    setApprovalModalOpen(false);
    const property_ids = finalProperties?.map((id) => id?.id);
    dispatch(
      brokerAgentsAction.getStartMarketringMailData({
        url: `agent/update-property-status`,
        data: { property_ids: [property_ids], source: base_route },
        brokertoken: true,
      })
    );
  };

  const handleSaveChange = () => {
    const enabledData = selectedProperties.filter((property, id) =>
      property?.risk_report ? property?.risk_report : property?.enable
    );
    const data = selectedProperties.filter(
      (property, id) => property?.email?.length > 0
    );
    console.log(enabledData, "final data ==>", data);
    if (enabledData?.length !== data?.length) {
      setIsOwnerInfoAddeds(true);
    } else {
      setIsOwnerInfoAddeds(false);
      const payload = data?.map(
        ({ id, property_id, enable, email, phone }) => ({
          id,
          property_id,
          enable,
          email,
          phone,
        })
      );
      console.log("payload==>", payload);
      if (getObjLength(payload)) {
        setFinalProperties(payload);
        dispatch(
          brokerAgentsAction.getSaveFreeMonitortingData({
            url: `agent/update-agent-properties-status/${agent_id}`,
            data: { free_monitoing: payload, source: base_route },
            brokertoken: true,
          })
        );
      }
    }
  };

  const handleAddNewProperty = () => {
    setAddPropertyModalOpen(true);
  };

  const closeAddPropertyModal = () => {
    setAddPropertyModalOpen(false);
    setAgentProfileModalOpen(false);
    dispatch(brokerAgentsAction.fetchAddNewAgentPropertyData({}));
    dispatch(brokerAgentsAction.fetchUpdateAgentProfileData({}));
  };

  const updateAgentProfile = (values, existingValues) => {
    let formData = { ...values };
    console.log("logoo==>", values);

    delete formData.new_password;
    if (formData?.old_password?.length === 0) {
      delete formData.old_password;
      delete formData?.password;
    }
    if (getObjLength(existingValues)) {
      if (existingValues?.logo === formData?.logo) {
        delete formData.logo;
      }
      if (existingValues?.agent_profile_img === formData?.profile_img) {
        delete formData.profile_img;
      }
      if (base_route !== "title") {
        delete formData.representative;
      }
    }
    console.log(values, "formData==>", formData);

    dispatch(
      brokerAgentsAction.getUpdateAgentProfileData({
        url: `agent/profile`,
        data: { ...formData, source: base_route },
        brokertoken: true,
      })
    );
  };

  const propertyListAPICall = useCallback(() => {
    let data = {
      limit: payload?.limit,
      offset: payload?.offset,
      domain: broker,
    };
    dispatch(
      brokerAgentsAction.getAgentPropertyListData({
        url: `agent/agent-properties/${agent_id}`,
        data: data,
        brokertoken: true,
        source: base_route,
      })
    );
  }, [payload?.limit, payload?.offset, dispatch]);

  const agentProfileAPICall = () => {
    dispatch(
      brokerAgentsAction.getAgentProfileData({
        url: `agent/profile`,
        data: {},
        brokertoken: true,
        source: base_route,
      })
    );
  };
  useEffect(() => {
    propertyListAPICall();
  }, [propertyListAPICall]);

  useEffect(() => {
    agentProfileAPICall();
  }, []);

  console.log("agentPropertyListData==>", agentPropertyListData);
  const propertyData = agentPropertyListData?.success
    ? agentPropertyListData?.data
    : [];

  const isCollapse = (id, risk_report) => {
    let data = {};
    if (getObjLength(risk_report)) {
      data = selectedProperties?.find((selectedId) => selectedId?.id === id);
    } else {
      data = selectedProperties?.find(
        (selectedId) => selectedId?.id === id && selectedId?.enable
      );
    }
    return getObjLength(data) ? true : false;
  };

  const isOwnerDetailsAvailable = (row, isEdit = false) => {
    let data = {};
    if (row?.risk_report) {
      data = selectedProperties?.filter(
        (data) => data?.id === row?.id && data?.risk_report?.email
      );
    } else {
      data = selectedProperties?.filter(
        (data) => data?.id === row?.id && data?.email && !isEdit
      );
    }
    console.log("selected propertiespppp", data);

    return getObjLength(data) ? true : false;
  };

  const findData = (row) => {
    let data = {};
    if (row?.risk_report) {
      data = {};
    } else {
      data = selectedProperties?.filter((data) => data?.id === row?.id);
    }
    return getObjLength(data) ? data : null;
  };

  useEffect(() => {
    console.log("saveFreeMonitortingData==>", saveFreeMonitortingData);

    if (
      getObjLength(saveFreeMonitortingData) &&
      saveFreeMonitortingData?.success
    ) {
      setIsOwnerInfoAddeds(false);
      setSelectedProperties([]);

      const openMarketingApproalModal = finalProperties?.filter(
        (data) => data?.enable
      );
      if (getObjLength(openMarketingApproalModal)) {
        setApprovalModalOpen(true);
      } else {
        propertyListAPICall();
      }
    }
  }, [saveFreeMonitortingData]);

  useEffect(() => {
    if (
      getObjLength(startMarketringMailData) &&
      startMarketringMailData?.success
    ) {
      propertyListAPICall();
      setApprovalModalOpen(false);
    }
  }, [startMarketringMailData]);

  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  useEffect(() => {
    console.log("addNewAgentPropertyData==>", addNewAgentPropertyData);
    if (getObjLength(addNewAgentPropertyData)) {
      if (addNewAgentPropertyData?.success) {
        setAddPropertyModalOpen(false);
        propertyListAPICall();
      } else {
        setAddPropertyModalOpen(true);
      }
    }
  }, [addNewAgentPropertyData]);

  useEffect(() => {
    console.log("updateAgentProfileData==>", updateAgentProfileData);
    if (getObjLength(updateAgentProfileData)) {
      if (updateAgentProfileData?.success) {
        agentProfileAPICall();
      }
    }
  }, [updateAgentProfileData]);

  useEffect(() => {
    if (getObjLength(agentPropertyListData)) {
      if (agentPropertyListData?.success) {
        if (pageCount === 0)
          setPageCount(Math.ceil(agentPropertyListData?.count / PER_PAGE));
      }
    }
  }, [agentPropertyListData]);

  return (
    <>
      <>
        <div className="broker-create-account">
          <BrokerTopSection
            headerTexts={"Your Properties"}
            subHeaderTexts={
              "Below are Properties from the MLS that you represented the Buyer on. Please select which Properties you would like to add monitoring to and have the owner receive Monthly Update Emails"
            }
            hide_my_acc_btn={true}
            logout_btn={true}
            isAgentProfileBtn={true}
            base_route={base_route}
            openAgentProfileModal={() => setAgentProfileModalOpen(true)}
          />
          <section className="create_acc_form_wrapper mb-4 investor-list">
            <div className="agent_button">
              <Button onClick={() => setAgentProfileModalOpen(true)}>
                <Avatar
                  src={agentProfileData?.data?.agent_profile_img}
                  alt={agentProfileData?.data?.first_name}
                  sx={{ width: 30, height: 30 }} // Adjust size as needed
                />
                User settings
              </Button>
            </div>
            <Container
              fluid
              className="bg-white properties-main-container mt-2"
            >
              {/* Desktop Table */}
              <TableContainer
                component={Paper}
                className="d-none d-sm-block"
                sx={{
                  //maxHeight: 885,
                  boxShadow: "0px 1px 0px 0px #344B5B0F",
                }}
              >
                <Table
                  sx={{ minWidth: 1100 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>Address</StyledTableCell>
                      <StyledTableCell>City</StyledTableCell>
                      <StyledTableCell>State</StyledTableCell>
                      <StyledTableCell>Zip</StyledTableCell>
                      {/* <StyledTableCell>Unit</StyledTableCell> */}
                      {/* <StyledTableCell>Sold In</StyledTableCell> */}
                      <StyledTableCell width={150}>Owner</StyledTableCell>
                      <StyledTableCell>APN</StyledTableCell>
                      <StyledTableCell minwidth={180}>
                        Risk Level
                      </StyledTableCell>
                      <StyledTableCell>Free Monitoring</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {propertyData?.map((row, index) => {
                      // For remain Toggle values same as before changing page
                      freeMonitoringToggleValue = selectedProperties?.find(
                        (data) => data?.id === row?.id
                      );
                      let letEdit = row?.risk_report
                        ? true
                        : isOwnerDetailsAvailable(row) &&
                          !findData(row)?.[0]?.isEdit
                        ? true
                        : false;
                      return (
                        <>
                          <StyledTableRow
                            key={index}
                            className={row?.risk_report ? "cursor-pointer" : ""}
                            onClick={() => handleCollapsibleRow(row)}
                          >
                            <StyledTableCell>
                              {" "}
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  //handleMenuClick(event, row);
                                }}
                                size="small"
                                className="settings-button"
                                aria-haspopup="true"
                              >
                                <SettingsIconSVG color={"#15BE53"} />
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell width={250}>
                              {row?.street_address}
                            </StyledTableCell>
                            <StyledTableCell>{row?.city}</StyledTableCell>
                            <StyledTableCell>{row?.state}</StyledTableCell>
                            <StyledTableCell>{row?.zip}</StyledTableCell>
                            <StyledTableCell width={150}>
                              {row?.owner_name || "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.parcel_number || "-"}
                            </StyledTableCell>
                            <StyledTableCell minWidth={180}>
                              <Stack
                                direction="column"
                                spacing={0.5}
                                className=""
                              >
                                {row?.risk_score ? (
                                  <Typography className="value">
                                    <div className="d-flex justify-content-start align-items-center">
                                      {
                                        <>
                                          <div
                                            class={` color-box ${
                                              riskLevelCount(row?.risk_level) >
                                                0 && " color-low"
                                            } `}
                                          ></div>
                                          <div
                                            class={` color-box ${
                                              riskLevelCount(row?.risk_level) >
                                                1 && " color-medium-low"
                                            } `}
                                          ></div>
                                          <div
                                            class={` color-box ${
                                              riskLevelCount(row?.risk_level) >
                                                2 && " color-medium"
                                            } `}
                                          ></div>
                                          <div
                                            class={` color-box ${
                                              riskLevelCount(row?.risk_level) >
                                                3 && " color-medium-high"
                                            } `}
                                          ></div>
                                          <div
                                            class={` color-box ${
                                              riskLevelCount(row?.risk_level) >
                                                4 && " color-high"
                                            } `}
                                          ></div>
                                        </>
                                      }
                                      <span className="ps-1 fw-500">
                                        {row?.risk_level}
                                      </span>
                                    </div>
                                  </Typography>
                                ) : (
                                  "-"
                                )}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell width={155}>
                              <div className="d-flex justify-content-between align-items-center">
                                {row?.free_monitoring_request_status &&
                                row?.free_monitoring_request_status !==
                                  "rejected" ? (
                                  <span
                                    className={`proeprty_status ${row?.free_monitoring_request_status}`}
                                  >
                                    {row?.free_monitoring_request_status}
                                  </span>
                                ) : (
                                  <>
                                    <ToggleSwitch
                                      loader={agentPropertyLoader}
                                      index={index}
                                      disable={!row?.risk_score}
                                      initialState={
                                        freeMonitoringToggleValue
                                          ? freeMonitoringToggleValue?.enable
                                          : row?.free_subscription
                                      }
                                      onChange={(toggleVal) => {
                                        selectProperties(toggleVal, row);
                                      }}
                                    />
                                  </>
                                )}
                                {row?.risk_report && (
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => handleCollapsibleRow(row)}
                                  >
                                    {selectedCollapsibleRow?.id === row?.id &&
                                    isCollapsibleRow ? (
                                      <KeyboardArrowUpIcon
                                        style={{ color: " #15BE53" }}
                                      />
                                    ) : (
                                      <KeyboardArrowDownIcon
                                        style={{ color: " #15BE53" }}
                                      />
                                    )}
                                  </IconButton>
                                )}{" "}
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow
                            className="collapsible-row"
                            sx={{
                              background: " #F6F9FC",
                            }}
                          >
                            <StyledTableCell
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                paddingLeft: 0,
                              }}
                              colSpan={12}
                            >
                              {" "}
                              <Collapse
                                in={
                                  row?.risk_report
                                    ? selectedCollapsibleRow?.id === row?.id &&
                                      isCollapsibleRow
                                    : isCollapse(row?.id, row?.risk_report)
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box
                                  sx={{
                                    margin: 1,
                                    minHeight: "50px",
                                  }}
                                >
                                  {letEdit ? (
                                    <Stack
                                      direction="row"
                                      display={"flex"}
                                      justifyContent={"start"}
                                      alignItems={"center"}
                                      spacing={4}
                                      paddingLeft={"80px"}
                                    >
                                      <Stack direction="column">
                                        <Typography
                                          variant="h6"
                                          className="content-heading"
                                          component="div"
                                        >
                                          Owner Email Address
                                        </Typography>
                                        <Typography
                                          className="content-text m-0"
                                          component="div"
                                          maxWidth="300px !important"
                                        >
                                          <span className="">
                                            {findData(row)?.[0]?.email ||
                                              row?.risk_report?.email ||
                                              "-"}
                                          </span>
                                        </Typography>
                                      </Stack>
                                      <Stack direction="column">
                                        <Typography
                                          variant="h6"
                                          className="content-heading"
                                          component="div"
                                        >
                                          Owner Phone Number
                                        </Typography>
                                        <Typography
                                          className="content-text m-0"
                                          component="div"
                                        >
                                          <span className="">
                                            {getFormatedPhoneNumber(
                                              findData(row)?.[0]?.phone
                                            ) ||
                                              getFormatedPhoneNumber(
                                                row?.risk_report?.phone
                                              ) ||
                                              "-"}
                                          </span>
                                        </Typography>
                                      </Stack>
                                      {!getObjLength(row?.risk_report) && (
                                        <Stack direction="column">
                                          <div>
                                            <Button
                                              className="popup_btn other_popup_btn"
                                              type="submit"
                                              variant="contained"
                                              onClick={() =>
                                                addOwnerDataToselectProperties(
                                                  row,
                                                  {
                                                    email:
                                                      findData(row)?.[0]?.email,
                                                    phone:
                                                      findData(row)?.[0]?.phone,
                                                  },
                                                  true
                                                )
                                              }
                                            >
                                              Edit Details
                                            </Button>
                                          </div>
                                        </Stack>
                                      )}
                                    </Stack>
                                  ) : (
                                    <Formik
                                      enableReinitialize
                                      validateOnChange
                                      initialValues={{
                                        email:
                                          findData(row)?.[0]?.email ||
                                          row?.risk_report?.email ||
                                          "",
                                        phone:
                                          findData(row)?.[0]?.phone ||
                                          row?.risk_report?.phone ||
                                          "",
                                      }}
                                      validationSchema={
                                        proeprtyValidationSchema
                                      }
                                      onSubmit={(values) => {
                                        console.log("submit-->", values);
                                        addOwnerDataToselectProperties(
                                          row,
                                          values
                                        );
                                      }}
                                    >
                                      {({
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                        isSubmitting,
                                      }) => {
                                        return (
                                          <Form className="owner_form">
                                            <Typography
                                              variant="h6"
                                              className="content-heading"
                                              component="div"
                                              sx={{ width: "50%" }}
                                            >
                                              Please enter the following
                                            </Typography>
                                            <Box className="property-textfields">
                                              <CustomTextField
                                                name="email"
                                                placeholder="Owner’s email address"
                                                validation={{
                                                  values,
                                                  setFieldValue,
                                                  touched,
                                                  errors,
                                                }}
                                              />
                                            </Box>
                                            <Box className="property-textfields">
                                              <CustomPhoneNumberInput
                                                name="phone"
                                                placeholder={
                                                  "Owner’s mobile number"
                                                }
                                                values={values}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                otherProps={{
                                                  onChange: (event) => {
                                                    setFieldValue(
                                                      "phone",
                                                      event
                                                    );
                                                    if (!event) {
                                                      setFieldValue(
                                                        "phone",
                                                        ""
                                                      );
                                                    }
                                                  },
                                                }}
                                              />
                                            </Box>
                                            <div>
                                              <Button
                                                className="popup_btn other_popup_btn"
                                                type="submit"
                                              >
                                                Add
                                              </Button>
                                            </div>
                                          </Form>
                                        );
                                      }}
                                    </Formik>
                                  )}
                                </Box>
                              </Collapse>
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>

                {!propertyData?.length > 0 && (
                  <Typography className="p-5 text-center" variant="h6">
                    {"No Data Found."}
                  </Typography>
                )}
              </TableContainer>
              <Box className="mt-5" id="pagination-container">
                {pageCount > 1 && (
                  <CustomPagination
                    count={pageCount}
                    page={page}
                    handlePageChange={handlePageChange}
                  />
                )}
              </Box>
              <Box className="agents_buttons">
                <Button
                  className="add-property-btn"
                  onClick={() => handleAddNewProperty()}
                >
                  Click Here to Add a Property Not Listed
                </Button>
                <Button
                  className="save-agents-btn"
                  onClick={() => handleSaveChange()}
                >
                  Save changes <img src={checkImg} alt="checkImg" />
                </Button>
              </Box>
              {isOwnerInfoAdded && (
                <p className="text-danger text-center fw-500 mt-3">
                  Missing owner details detected for some selected properties.
                  Please ensure all selected properties have their owner
                  information added.
                </p>
              )}
            </Container>
          </section>
        </div>
        <BrokersPublicPageFooter />
      </>

      {getObjLength(agentPropertyListData) &&
      !agentPropertyListData?.success ? (
        <AlertDialog
          type="Error"
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(brokerAgentsAction.resetBrokerAgentsState({}));
            navigate(`/${base_route}/${broker}/agent-login`);
          }}
          mesage={agentPropertyListData?.data?.message}
        />
      ) : null}

      {approvalModalOpen && (
        <AlertDialog
          type="Warning"
          title={<p className="approval_title">Approval</p>}
          openPopup={approvalModalOpen}
          onClose={closeApprovalModal}
          onCloseFun={closeApprovalModal}
          mesage={
            <p className="approval_texts">
              By "Agree"ing below, you give approval to EquityProtect to send
              monitoring marketing emails on your behalf to the clients you have
              selected to have monitoring turned on for.
            </p>
          }
          buttons={
            <div>
              <Button
                className="popup_Btn other_popup_btn mx-2"
                onClick={closeApprovalModal}
              >
                Hm, not good
              </Button>
              <Button
                className="popup_Btn success_popup_btn mx-2"
                onClick={startMarketringMail}
              >
                ok, All Good
              </Button>
            </div>
          }
        />
      )}

      {addPropertyModalOpen && (
        <DialogBox
          width={"md"}
          title="Add Property to Agent list"
          contentClassName={"bg-white p-0"}
          openPopup={addPropertyModalOpen}
          setOpenPopup={setAddPropertyModalOpen}
          onClose={closeAddPropertyModal}
          content={
            <AddAgentProperties
              handleClose={closeAddPropertyModal}
              base_route={base_route}
            />
          }
        />
      )}
      {agentProfileModalOpen && (
        <DialogBox
          width={
            getObjLength(updateAgentProfileData) &&
            updateAgentProfileData?.success
              ? "sm"
              : "lg"
          }
          contentClassName={"bg-white p-0"}
          openPopup={agentProfileModalOpen}
          setOpenPopup={setAgentProfileModalOpen}
          onClose={
            getObjLength(updateAgentProfileData) &&
            updateAgentProfileData?.success
              ? false
              : closeAddPropertyModal
          }
          content={
            !getObjLength(updateAgentProfileData) ||
            !updateAgentProfileData?.success ? (
              <BrokerSignupPage
                hide_top_footer_section={true}
                update_agent_profile={true}
                updateAgentProfile={updateAgentProfile}
                closeAgentProfileModal={closeAddPropertyModal}
                base_route={base_route}
              />
            ) : (
              <PlainAlertMessage
                type="Success"
                openPopup={true}
                onClose={true}
                onCloseFun={() => {
                  closeAddPropertyModal();
                }}
                message={updateAgentProfileData?.message}
                footer={false}
              />
            )
          }
        />
      )}

      <Loader open={agentProfileLoader || agentPropertyLoader} />
    </>
  );
};
