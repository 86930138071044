import React from "react";

import { Col, Row } from "react-bootstrap";

import { Stack, Typography } from "@mui/material";

import Sync from "../../assets/price_F&Q/sync.svg";
import Support_agent from "../../assets/price_F&Q/support_agent.svg";
import Workspace from "../../assets/price_F&Q/workspace_premium.svg";

import "./Style.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SubscriptionPlansFeatures = ({
  fullPage,
  icons_classname = "",
  plans_title = "Both plans include",
}) => {
  return (
    <>
      <div className={`${fullPage ? "spacing_full_pp" : "spacing_pp"}`}>
        <Row
          className="bg-white "
          style={{ borderRadius: "10px", border: "1px solid #DEE3EA" }}
        >
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="text-start"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="both-plan-include-box">
              <ul>
                <li>
                  <Typography className="pt-2">
                    We’re Your Private Guard.
                  </Typography>
                </li>
                <li>
                  <Typography className="pt-2">
                    The FBI reported that in 2017 there were 9,654 cases of real
                    estate fraud, resulting in more than $56 million in losses.
                    Home title fraud has grown considerably since then, with
                    11,578 cases of real estate fraud reported in 2021, with
                    losses totaling more than $350 million.
                  </Typography>
                </li>
                <li>
                  <Typography className="pt-2">
                    We’re here for one thing only, ensuring this kind of equity
                    ownership fraud will never happen to you.
                  </Typography>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="points px-4 py-2 p-sm-5"
          >
            <div className="">
              <Typography
                className="fw-600 text-black mb-4 d-flex justify-content-start plan_title"
                variant="h3"
              >
                {plans_title}
              </Typography>
              <ul
                className="features_list mb-4"
                style={{ listStyleType: "none", textAlign: "left" }}
              >
                <li className="position-relative mb-3">
                  24/7 Property Monitoring
                </li>
                <li className="position-relative mb-3">
                  SmartPolicy insuring your home ownership
                </li>
                <li className="position-relative mb-3">
                  Access to your recorded property information at anytime
                </li>
                <li className="position-relative mb-3">
                  Blockchain Protection of your property
                </li>
                <li className="position-relative mb-3">Live Support</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
      <div className={`${fullPage ? "" : "spacing_icons"} ${icons_classname}`}>
        <Row>
          <Col xs={12} sm={4} md={4} lg={4} xl={4}>
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"flex-start"}
              alignItems={"start"}
            >
              <LazyLoadImage
                className="p-3"
                src={Workspace}
                alt="Property Theft Protection"
              />
              {/* <img
                className="p-3"
                src={Workspace}
                alt="Property Theft Protection"
              /> */}
              <p className="pt-3">US Patented Technology</p>
            </Stack>
          </Col>
          <Col xs={12} sm={4} md={4} lg={4} xl={4}>
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"flex-start"}
              alignItems={"start"}
            >
              <LazyLoadImage
                className="p-3"
                src={Support_agent}
                alt="Property Theft Protection"
              />
              {/* <img
                className="p-3"
                src={Support_agent}
                alt="Property Theft Protection"
              /> */}
              <p className="pt-3">24/7 US-Based Premium Support</p>
            </Stack>
          </Col>
          <Col xs={12} sm={4} md={4} lg={4} xl={4}>
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"flex-start"}
              alignItems={"start"}
            >
              <LazyLoadImage
                className="p-3"
                src={Sync}
                alt="Property Theft Protection"
              />
              {/* <img className="p-3" src={Sync} alt="Property Theft Protection" /> */}
              <p className="pt-3">60-Day Money Back Guarantee</p>
            </Stack>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SubscriptionPlansFeatures;
