import React, { useEffect, useState } from "react";

import { Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";

import CardPayment from "./CardPayment";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import SubscriptionPlans from "./SubscriptionPlans";
import { encryptPayload, getObjLength } from "../../helpers/utils";
import { lennarSignUpAction } from "../../../redux/slices/lennar/signUp";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";

const AddSubcription = ({
  selectedData,
  callListingAPI = () => {},
  closePopup,
  propertiesIds = [],
  ids = [],
  uid = null,
  tabComponent = false,
  offsetNum,
  isProvider = false,
  isBulkSubscription = false,
}) => {
  const [screen, setScreen] = useState("subscriptions");
  const [planId, setPlanId] = useState("");
  const [yearlyPlan, setYearlyPlan] = useState();
  const [planDetails, setPlanDetails] = useState(yearlyPlan);
  const [finalAmount, setFinalAmount] = useState(yearlyPlan);
  const [isLoader, setIsLoader] = useState(false);
  const [generateLinkAlert, setGenerateLinkAlert] = useState(false);

  const { lennarGenerateLinkDetails } = useSelector(
    (state) => state?.lennarSignUp
  );
  const { userProfileDetails } = useSelector((state) => state?.userProfile);
  const { isAnualPlanScreen } = useSelector((state) => state?.signUpUser);

  const dispatch = useDispatch();

  const pathName = window.location.pathname;
  let path = pathName.split("/")?.[1];
  console.log("path==>", path);

  // handle close property modal
  const handleCloseAddPropertyModal = (type) => {
    dispatch(propertiesAction.clearPropertiesList());
    dispatch(userSigupAction.fetchTotalPaymentDetails({}));
    if (type === "success") {
      setScreen("subscriptions");
      closePopup(false);
      callListingAPI();
    }
  };

  const cardListAPI = () => {
    dispatch(
      userProfileAction.userCardDetails({
        url: `admin/users/${uid}/card`,
        data: {},
        token: true,
      })
    );
  };

  const goToPayment = () => {
    if (getObjLength(offsetNum)) {
      localStorage.setItem("offset", offsetNum);
    }

    let domain =
      selectedData?.provider?.domain || selectedData?.provider?.custom_domain;
    dispatch(
      lennarSignUpAction.generateLink({
        url: `providers/${domain}/${uid}/ep/generate-link`,
        data: {
          domain,
          create_by_ep: true,
          generated_by: userProfileDetails?.data?.user_id,
        },
        toekn: true,
      })
    );
    setIsLoader(true);
    setGenerateLinkAlert(false);
  };
  const handleCloseAddSubscriptionModal = () => {
    closePopup(false);
  };
  useEffect(() => {
    if (screen === "cardsInfo") {
      cardListAPI();
    }
  }, [screen]);

  useEffect(() => {
    localStorage.removeItem("promoCode");
  }, []);

  useEffect(() => {
    console.log("lennarGenerateLinkDetails==>", lennarGenerateLinkDetails);
    if (getObjLength(lennarGenerateLinkDetails)) {
      if (lennarGenerateLinkDetails?.success) {
        let orignalURL = lennarGenerateLinkDetails?.data?.link;
        // ?.replace(
        //   OTHER_PROVIDERS_URL,
        //   DEFAULT_URL
        // );
        window.location.href = orignalURL + "&from=" + encryptPayload(path);
        dispatch(lennarSignUpAction?.resetLennarSignUpState({}));
        setGenerateLinkAlert(false);
      } else {
        setIsLoader(false);
        setGenerateLinkAlert(true);
      }
    }
  }, [dispatch, lennarGenerateLinkDetails]);

  console.log(ids, "propertiesIds==>", propertiesIds);

  return (
    <>
      {/* <p style={{top:"20px",right:"33px"}} className="close-text">Close</p> */}
      <Button
        onClick={() => closePopup(false)}
        className="modal-close-btn close-text hide-outer-close"
        style={{
          position: "absolute",
          right: 10,
          background: "transparent",
          textTransform: "none",
        }}
      >
        Close
        <CloseIcon
          className="hide-outer-close"
          sx={{ fontSize: "2.5rem", color: "#15be53" }}
        />
      </Button>
      <Box
        className={`mt-5 ${isAnualPlanScreen && "border_clasess"} ${
          !isProvider && `zoom-container`
        }`}
      >
        {screen === "subscriptions" &&
          (isProvider ? (
            //  <ProviderPlanDisplayForAdmin selectedData={selectedData} />
            <section
              className="text-center content-spacing"
              style={{ background: " " }}
            >
              <Stack direction={"column"} className="py-5 ">
                <Typography variant="h6" className="text-center fw-400 m-0">
                  You will be redirected to{" "}
                  <span className="text-capitalize">
                    {selectedData?.provider?.domain ||
                      selectedData?.provider?.custom_domain}{" "}
                  </span>
                  platform for payment processing.
                </Typography>
                <br />
                <span className="text-danger fw-500">
                  Please note that all properties associated with this account
                  on{" "}
                  <span className="text-capitalize">
                    {selectedData?.provider?.domain}
                  </span>{" "}
                  will be included in this transaction.
                </span>

                <>
                  <div className="mt-3">
                    <Button
                      onClick={() => closePopup(false)}
                      className="popup_Btn  other_popup_btn mt-2 mx-1"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={goToPayment}
                      className="popup_Btn success_popup_btn mt-2 mx-1"
                    >
                      Continue to payment
                    </Button>
                  </div>
                </>
              </Stack>
            </section>
          ) : (
            <SubscriptionPlans
              handleCloseAddPropertyModal={handleCloseAddSubscriptionModal}
              planId={setPlanId}
              onChangeModalScreen={setScreen}
              isAdminCreateUser={false}
              setFinalAmount={setFinalAmount}
              selectedData={selectedData}
              from={"add-subscriptions"}
              uid={uid}
              propertiesIds={propertiesIds}
              ids={ids}
              tabComponent={tabComponent}
              isBulkSubscription={isBulkSubscription}
            />
          ))}

        {screen === "cardsInfo" && (
          <CardPayment
            handleCloseAddPropertyModal={handleCloseAddSubscriptionModal}
            selectedData={selectedData}
            planId={planId}
            onChangeModalScreen={setScreen}
            onCloseModalHandler={handleCloseAddPropertyModal}
            from={"add-subscriptions"}
            callListingAPI={cardListAPI}
            addPromoCode={true}
            propertiesIds={propertiesIds}
            uid={uid}
            tabComponent={tabComponent}
          />
        )}
      </Box>

      {getObjLength(lennarGenerateLinkDetails) && generateLinkAlert ? (
        <AlertDialog
          type={"Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            setGenerateLinkAlert(false);
            dispatch(lennarSignUpAction?.resetLennarSignUpState({}));
          }}
          mesage={
            lennarGenerateLinkDetails?.data?.message || "Something went wrong"
          }
        />
      ) : null}

      <Loader open={isLoader} />
    </>
  );
};

export default AddSubcription;
