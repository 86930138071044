import * as React from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import { Button } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

import PhotoIdScreen from "./photoIdScreen";
import SignatureScreen from "./signatureScreen";
import BeneficiaryForm from "./BeneficiaryForm";
import { getObjLength } from "../helpers/utils";
import SecurityInfoScreen from "./securityInfoScreen";
import FinalStepCompleted from "./finalStepCompleted";
import PropertySearchTabs from "./propertySearchTabs";
import PersonalTokenScreen from "./personalTokenScreen";
import CreditCardForm from "./React-stripe/creditCardForm";
import SecurityPinGeneration from "./SecurityPinGeneration";
import BiometricForNativeApp from "./biometricForNativeApp";
import SecureConnectionTemplate from "../common/secureConnectionTemplate";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
const steps = [
  "Personal token",
  "Find property",
  "Security info",
  "Credit card info",
  "Security Pin",
  "Photo ID",
  "Signature",
  "Beneficiary",
];

export default function HorizontalLinearStepper({
  resumeCount,
  signupStep,
  reduceErrorCount,
  errorPopup,
  isIOS,
  investor_id,
}) {
  console.log("resumeCount==>", resumeCount);
  // const { initialActiveStep } = useSelector((state) => state.signUpUser);
  const initialActiveStep = useSelector(
    (state) => state.signUpUser.initialActiveStep
  );
  const [activeStep, setActiveStep] = React.useState(
    resumeCount > 0 ? resumeCount : initialActiveStep
  );

  const errorFlagCount = useSelector(
    (state) => state.signUpUser.errorFlagCount
  );
  const showSecurityInfoScreen = useSelector(
    (state) => state.signUpUser.showSecurityInfoScreen
  );

  const propertiesList = useSelector(
    (state) => state.signUpUser.verifiedProperties
  );

  const crerateAccData = useSelector(
    (state) => state.signUpUser.createAccResponse
  );

  const { isAnualPlanScreen } = useSelector((state) => state?.signUpUser);

  // eslint-disable-next-line no-unused-vars
  const [skipped, setSkipped] = React.useState(new Set());
  const [retake, setRetake] = React.useState(false);
  const [fromErr, setFormErr] = React.useState("err");
  const [backButton, setBackButton] = React.useState("");
  const securityInfo = useSelector((state) => state.signUpUser.step4);
  const dispatch = useDispatch();
  const skipStep = JSON.parse(localStorage.getItem("skip"));
  const [hideSkipBtn, setHideSkipBtn] = React.useState(true);
  const [finalScreen, setFinalScreen] = React.useState(false);
  const [propertyListScreen, setPropertyListScreen] = React.useState(false);

  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  React.useEffect(() => {
    window.onbeforeunload = function () {
      console.log("refreshed...");
      return true;
    };

    return () => {
      console.log("refreshed stop...");
      // dispatch(userSigupAction.clearData());
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const onSubmit = (values, errors) => {
    console.log(values, "<=== Form data==>", errors);
    // if (Object.keys(fromErr).length === 0) {
    //   handleNext();
    // }
  };

  const onClickSubmit = (err) => {
    console.log("error==>", err);
    setFormErr(err);
  };

  const handleNext = (e, action) => {
    console.log("parent action==>.", activeStep);
    signupStep(activeStep + 1);
    if (action === undefined) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setRetake(false);
    } else if (action === "owner_verification") {
      setActiveStep(2);
      localStorage.setItem("isOwnerVerified", 1);
      setRetake(false);
    } else if (action === "back") {
      setRetake(true);
    } else {
      setRetake(false);
    }
  };

  const handleBack = (setTouched) => {
    console.log("parent action==>.", activeStep);
    dispatch(userSigupAction.addNewProperty({}));
    setHideSkipBtn(true);
    setActiveStep(1);
    if (parseInt(propertiesList) > 0) {
      if (showSecurityInfoScreen) {
        console.log("propertiesList in back ==>", showSecurityInfoScreen);
        localStorage.setItem("button", "skip");
      }
    }
  };
  const handleSkip = (setTouched) => {
    console.log("parent action==>.", activeStep);
    if (activeStep === 1) {
      setActiveStep(2);
      dispatch(userSigupAction.showSecurityInfoScreen(false));
      localStorage.setItem("currentStep", "2");
      localStorage.setItem("isOwnerVerified", "1");
    }
    if (activeStep === 5) {
      dispatch(
        userSigupAction.skipRegisterFaceStep({
          url: "skip-face",
          data: {
            user_id: getObjLength(crerateAccData)
              ? crerateAccData.data?.user_id
              : userIdFromSession,
          },
        })
      );
      setActiveStep(6);
      localStorage.setItem("currentStep", "6");
      localStorage.removeItem("isOwnerVerified");
    }
    if (activeStep === 7) {
      dispatch(
        userSigupAction.skipBeneficiaryStep({
          url: "skip-beneficiary",
          data: {
            user_id: getObjLength(crerateAccData)
              ? crerateAccData.data?.user_id
              : userIdFromSession,
          },
        })
      );
      setFinalScreen(true);
      localStorage.setItem("completed", "1");
    }
    localStorage.setItem("skip", "0");
    dispatch(userSigupAction.setErrorCount(parseInt(errorFlagCount) - 1));
    localStorage.setItem("errorCount", parseInt(errorFlagCount) - 1);
  };
  console.log(activeStep, "===", steps, "reatek==>.", securityInfo);

  const backtoAddProperty = (val, action) => {
    console.log(val, "backtoAddProperty==>", action);
    setBackButton(action);
    setHideSkipBtn(true);
    setActiveStep(val);
  };

  // defualt scroll bottom to top
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [activeStep]);

  let isSkipped = skipStep === 1 ? true : false;
  console.log(isSkipped, ",==isSkipped===hideSkipBtn==>", hideSkipBtn);
  console.log(backButton, "Backbutton==>", localStorage.getItem("button"));
  function formContent() {
    return (
      <>
        {
          // activeStep === steps.length ? (
          securityInfo ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                <FinalStepCompleted />
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={{ position: "relative" }} mt={2} mb={1}>
                {activeStep + 1 === 1 ? (
                  <PersonalTokenScreen
                    onBtnClick={handleNext}
                    reduceErrorCount={reduceErrorCount}
                    errorPopup={errorPopup}
                  />
                ) : activeStep + 1 === 2 ? (
                  <PropertySearchTabs
                    onBtnClick={handleNext}
                    handleBack={handleBack}
                    HideSkipBtn={setHideSkipBtn}
                    reduceErrorCount={reduceErrorCount}
                    errorPopup={errorPopup}
                    setPropertyListScreen={setPropertyListScreen}
                  />
                ) : // <FindProperty
                //   onBtnClick={handleNext}
                //   handleBack={handleBack}
                //   HideSkipBtn={setHideSkipBtn}
                //   reduceErrorCount={reduceErrorCount}
                //   errorPopup={errorPopup}
                // />
                activeStep + 1 === 3 ? (
                  <SecurityInfoScreen
                    onBtnClick={handleNext}
                    handleBack={handleBack}
                    backtoAddProperty={backtoAddProperty}
                    reduceErrorCount={reduceErrorCount}
                    errorPopup={errorPopup}
                    propertyListScreen={propertyListScreen}
                    setPropertyListScreen={setPropertyListScreen}
                    investor_id={investor_id}
                  />
                ) : activeStep + 1 === 4 ? (
                  <CreditCardForm
                    resumeCount={resumeCount}
                    onBtnClick={handleNext}
                    reduceErrorCount={reduceErrorCount}
                    errorPopup={errorPopup}
                    investor_id={investor_id}
                  />
                ) : activeStep + 1 === 5 ? (
                  <SecurityPinGeneration
                    onBtnClick={handleNext}
                    reduceErrorCount={reduceErrorCount}
                    errorPopup={errorPopup}
                  />
                ) : activeStep + 1 === 6 ? (
                  isIOS === "ios" || isIOS === "android" ? (
                    <BiometricForNativeApp
                      onBtnClick={handleNext}
                      isIOS={isIOS}
                    />
                  ) : (
                    <PhotoIdScreen
                      onBtnClick={handleNext}
                      HideSkipBtn={setHideSkipBtn}
                      reduceErrorCount={reduceErrorCount}
                      errorPopup={errorPopup}
                    />
                  )
                ) : activeStep + 1 === 7 ? (
                  <SignatureScreen
                    onBtnClick={handleNext}
                    HideSkipBtn={setHideSkipBtn}
                    reduceErrorCount={reduceErrorCount}
                    errorPopup={errorPopup}
                  />
                ) : activeStep + 1 === 8 ? (
                  <BeneficiaryForm
                    onBtnClick={handleNext}
                    finalScreen={finalScreen}
                    HideSkipBtn={setHideSkipBtn}
                    reduceErrorCount={reduceErrorCount}
                    errorPopup={errorPopup}
                    isIOS={isIOS}
                  />
                ) : null}

                {activeStep === 2 &&
                  backButton !== "hide" &&
                  // backButton !== "propertyList" &&
                  localStorage.getItem("button") === "back" && (
                    <Typography position="relative">
                      <Button
                        color="primary"
                        disabled={activeStep === 0}
                        onClick={() => handleBack()}
                        sx={{
                          mr: 1,
                          position: "absolute",
                          top: "-75px",
                          left: "25px",
                          marginTop: "20px",
                        }}
                      >
                        Back
                      </Button>
                    </Typography>
                  )}
                {activeStep === 2 &&
                  backButton === "propertyList" &&
                  localStorage.getItem("isOwnerVerified") === "1" &&
                  localStorage.getItem("button") !== "noButton" &&
                  localStorage.getItem("button") === "propertyList" && (
                    <Typography position="relative">
                      <Button
                        className="propertyListFloatButton"
                        color="primary"
                        disabled={activeStep === 0}
                        onClick={() => {
                          setPropertyListScreen(true);
                          // dispatch(userSigupAction.clearPropertyVerified());
                        }}
                        sx={{ mr: 1 }}
                      >
                        Property List
                      </Button>
                    </Typography>
                  )}
                {activeStep === 1 &&
                  localStorage.getItem("button") === "skip" && (
                    <Typography position="relative" className="w-100">
                      <Button
                        className="floatButton"
                        color="primary"
                        disabled={activeStep === 0}
                        onClick={() => handleSkip()}
                        sx={{
                          mr: 1,
                          // position: "absolute",
                        }}
                      >
                        Skip
                      </Button>
                    </Typography>
                  )}

                {hideSkipBtn &&
                  // activeStep === 1 ||
                  activeStep === 5 &&
                  isSkipped && (
                    <Typography position="relative">
                      <Button
                        className="bimetricSkipBtn mx-2 px-5 py-2"
                        // sx={{ py: 1.5 }}
                        color="primary"
                        disabled={activeStep === 0}
                        onClick={() => handleSkip()}
                      >
                        Skip
                      </Button>
                    </Typography>
                  )}
                {hideSkipBtn &&
                  // activeStep === 1 ||
                  activeStep === 7 &&
                  isSkipped && (
                    <Typography position="relative">
                      <Button
                        className="skipBtn mx-3"
                        // sx={{
                        //   top: "-26px !important",
                        //   left: "25px !important",
                        // }}
                        color="primary"
                        disabled={activeStep === 0}
                        onClick={() => handleSkip()}
                      >
                        Skip
                      </Button>
                    </Typography>
                  )}
              </Box>
            </React.Fragment>
          )
        }
      </>
    );
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        className="stepWrapper"
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step className="stepWrapperItem" key={label} {...stepProps}>
              <StepLabel className="stepWrapperLabel" {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <SecureConnectionTemplate
        content={formContent()}
        footer={false}
        isAnualPlanScreen={isAnualPlanScreen}
      />
    </Box>
  );
}
