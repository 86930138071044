import * as React from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ProfileIcon from "../../assets/dashboard/Dummy_Profile_photo.png";
import { serviceProviderSignInAction } from "../../redux/slices/serviceProvider/SPSignINSlice";
import { serviceProviderProfileAction } from "../../redux/slices/serviceProvider/servicerProfile/servicerProfileSlice";
import {
  clearLocalStoarge,
  windowsAndroidNativeMethods,
} from "../helpers/utils";

export default function ServicerAccountMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { servicerProfileDetails } = useSelector(
    (state) => state.servicerProfile
  );

  // handle My Account Button
  const handleMyAccountBtn = () => {
    // if (servicerProfileDetails?.success) {
    navigate("/servicer-profile");
    // } else {
    //   navigate("/login");
    // }
  };

  // handle sign out button
  const handleSignOut = () => {
    dispatch(
      serviceProviderSignInAction.userSignoutData({
        url: "service-provider/logout",
        data: {},
        token: true,
      })
    );
    dispatch(serviceProviderProfileAction.logoutPasswordRequired());
    dispatch(serviceProviderSignInAction.clearSigninData());
    // localStorage.removeItem("authToken");
    // localStorage.removeItem("authorised");
    clearLocalStoarge();

    // logout native method call for mobile app
    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.invokeNativeLogout
    ) {
      console.log("Logout Native App got called...");
      window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
    }

    navigate("/login");
  };
  // call Android App Logout method
  windowsAndroidNativeMethods("logout");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        className="px-3 account-dropdown d-none d-md-block"
        style={{
          borderLeft: "1px solid #E8ECF0",
          borderRight: "1px solid #E8ECF0",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableFocusRipple
            disableRipple
          >
            <Avatar
              sx={{ width: 40, height: 40, mr: 1 }}
              src={
                servicerProfileDetails?.data?.profile_img
                  ? servicerProfileDetails?.data?.profile_img
                  : ProfileIcon
              }
            ></Avatar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography variant="h6" className="userName">
                {servicerProfileDetails?.success ? (
                  <>
                    {servicerProfileDetails?.data?.first_name}{" "}
                    {servicerProfileDetails?.data?.last_name}
                  </>
                ) : (
                  "-"
                )}
                <ArrowDropDownIcon
                  sx={{ ms: 1, fontSize: "20px", color: "#15BE53" }}
                />
              </Typography>
              {/* <Typography variant="caption" className="companyName">
                Broker Company, LLC
                
              </Typography> */}
            </Box>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          className="d-none d-md-block"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 24,
                height: 24,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar
              fontSize="medium"
              src={
                servicerProfileDetails?.data?.profile_img
                  ? servicerProfileDetails?.data?.profile_img
                  : ProfileIcon
              }
            />{" "}
            Profile
          </MenuItem>
          {/* <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar /> My account
          </MenuItem> */}
          {/* <MenuItem className="profile-items">
            <ListItemIcon>
              <Settings fontSize="medium" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          <MenuItem className="profile-items" onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="medium" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
      <div
        className="px-3 account-dropdown mobile d-block d-md-none"
        style={{ borderBottom: "1px solid #E8ECF0" }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableFocusRipple
            disableRipple
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography variant="h6" className="userName">
                {servicerProfileDetails?.success ? (
                  <>
                    {servicerProfileDetails?.data?.first_name}
                    <ArrowDropDownIcon
                      sx={{ ms: 1, fontSize: "20px", color: "#15BE53" }}
                    />
                  </>
                ) : (
                  "-"
                )}
              </Typography>
            </Box>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          className="d-block d-md-none"
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 24,
                height: 24,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar
              fontSize="medium"
              src={
                servicerProfileDetails?.data?.profile_img
                  ? servicerProfileDetails?.data?.profile_img
                  : ProfileIcon
              }
            />{" "}
            Profile
          </MenuItem>
          {/* <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar /> My account
          </MenuItem> */}
          <Divider className="separator" />
          {/* <MenuItem className="profile-items">
            <ListItemIcon>
              <Settings fontSize="medium" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          <MenuItem className="profile-items" onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="medium" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
