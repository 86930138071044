import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import { Stack } from "@mui/material";

import soc2 from "../../assets/footer/soc2_logo.WebP";
import { BBB_REVIEW_LINK, EP_ADDRESS } from "../helpers/constants";
import BBB2 from "../../assets/leadpage/family_img_bedge.WebP";
import footerLogo from "../../assets/footer/equity_grayscale.WebP";

import "../../styles/footer.scss";
import "./BrokerSignupPage.scss";
const BrokersPublicPageFooter = () => {
  return (
    <section className="footer_body">
      <Container fluid className="mx-150">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="column" alignItems="center" spacing={3}>
              <img
                className="footer_logo"
                src={footerLogo}
                alt="footerLogo"
                style={{ width: "200px", height: "100%" }}
              />
              <div className="footer_texts">
                <p className="mb-2">
                  In addition to monitoring title changes, EquityProtect offers
                  a premium service that goes beyond alerts—it actively
                  safeguards property owners from financial crime before it
                  happens. Unlike most "property title locking" services that
                  simply notify you after fraud has occurred, leaving homeowners
                  to face costly and time-consuming legal disputes,
                  EquityProtect provides proactive protection to prevent the
                  crime altogether.
                </p>
              </div>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                gap={2}
                className="align-items-center"
              >
                <a href={BBB_REVIEW_LINK} target="_blank" rel="noreferrer">
                  <img
                    className="footer_logo"
                    src={BBB2}
                    alt="bbbLogo"
                    style={{
                      width: "auto",
                      height: "60px",
                    }}
                  />
                </a>
                <img
                  className="footer_logo"
                  src={soc2}
                  alt="soc2Logo"
                  style={{
                    width: "auto",
                    height: "90px",
                  }}
                />
              </Stack>
            </Stack>
          </Col>
        </Row>
        <div className="coyright_bar pb-4">
          <p className="mb-2">
            © {new Date().getFullYear()} EquityProtect, Inc. All rights reserved
          </p>
          <p className="mb-0">EquityProtect Inc. - {EP_ADDRESS}</p>
        </div>
      </Container>
    </section>
  );
};

export default BrokersPublicPageFooter;
