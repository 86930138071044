import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";

import PropertySearchType from "./PropertySearchType";
import Aurthorised from "../../../assets/price_F&Q/aurthorize.png";
import SecurityInfoAndCardPayment from "./SecurityInfoAndCardPayment";
import PersonalTokenScreen from "../../user-signup/personalTokenScreen";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";

import "../../../styles/properties.scss";
const AddPropertyPopup = ({
  getPropertyList,
  classes,
  showBtn = true,
  initialyAddPropertyModal = false,
  setIsAddPropertyModalOpen = () => {},
  handleCloseModal = () => {},
  isByPassOptionAvailable = false,
  from = "",
  isUserCreateProperty = false,
  setIsMapDisplay = () => {},
  isMapDisplay,
  callListingAPI = true,
  selectedRowData = {},
  step = null,
  user_id = null,
  showSecurityHeaderToCheckout = false,
  isAdminCreateUser = false,
  isResumeSignup = false,
  closeAfterAddingProperty = false,
  promoCode = undefined,
}) => {
  const dispatch = useDispatch();
  const dialogContentRef = useRef(null);

  // states
  const [addPropertyModal, setAddPropertyModal] = useState(
    initialyAddPropertyModal
  );
  const [screen, setScreen] = useState(step || "security");
  const [selectedData, setSelectedData] = useState({});
  const [skipVerification, setSkipVerification] = useState(false);
  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));
  const { propertyQuestion, getPropertyData, selectedProperty } = useSelector(
    (state) => state.properties
  );
  const { data: userData } = useSelector(
    (state) => state?.userProfile?.userProfileDetails
  );
  const { servicerProfessionalDetails } = useSelector(
    (state) => state?.servicerProfile
  );
  const { isAnualPlanScreen } = useSelector((state) => state?.signUpUser);
  console.log(addPropertyModal, "YYYY ->", initialyAddPropertyModal);

  const handleOpenAddPropertyModal = () => {
    setScreen("security");
    dispatch(propertiesAction.clearPropertiesList());
    setAddPropertyModal(true);
    setIsMapDisplay(false);
    setSkipVerification(false);
  };
  // handle close property modal
  const handleCloseAddPropertyModal = (type) => {
    console.log("YYY close called");

    if (type === "error") {
      dispatch(propertiesAction.fethPayment());
    } else {
      dispatch(userSigupAction.fetchTotalPaymentDetails({}));
      dispatch(propertiesAction.clearPropertiesList());
      dispatch(propertiesAction.generateQuestionsInputParams({}));

      setIsMapDisplay(true);

      setSkipVerification(false);
      // if (selectedRowData?.json_last_step?.step < 4) {
      //   console.log("YYY payment completed");
      //   setScreen("security-pin");
      // } else {
      setAddPropertyModal(false);
      setIsAddPropertyModalOpen(false);
      getPropertyList();
      setScreen("security");
      handleCloseModal();
      // }
    }
  };
  console.log("YYY Screen -->", screen);

  const completedProcess = () => {
    setScreen("security");
    handleCloseModal();
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
    localStorage.removeItem("county_data");
    dispatch(userSigupAction.userAddedByAdmin(false));
    dispatch(userSigupAction.clearData());
    dispatch(propertyUsersAction.clearData({}));
    dispatch(propertiesAction.resetState());
  };

  useEffect(() => {
    localStorage.removeItem("promoCode");
    localStorage.removeItem("referralCode");
  }, []);

  useEffect(() => {
    console.log("propertyQuestion  ==>", propertyQuestion);
    if (propertyQuestion?.success && propertyQuestion?.data?.is_verified) {
      console.log(
        selectedProperty,
        "subscription screen called getPropertyData, 2",
        getPropertyData
      );
      if (Object.keys(selectedProperty).length > 0) {
        let propertyData = selectedProperty?.json_response;
        setSelectedData({
          property_id: selectedProperty?.property_id,
          state_code: propertyData.mm_fips_state_code,
          user_id:
            userData?.user_id || servicerProfessionalDetails?.data?.user_id,
        });

        console.log("answers object==>", selectedData);
      }
      if (isAdminCreateUser) {
        setScreen("propertyList");
      } else {
        setScreen("subscriptions");
      }
    } else {
      setSelectedData({});
    }

    if (
      propertyQuestion?.data?.questions?.length > 0 &&
      propertyQuestion?.success &&
      !propertyQuestion?.data?.is_verified
    ) {
      console.log("security screen called");
      setScreen("questions");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyQuestion]);

  useEffect(() => {
    // Scroll the dialog content to the top whenever the screen changes
    if (dialogContentRef?.current) {
      dialogContentRef.current.scrollTop = 0;
    }
  }, [screen]);

  return (
    <>
      {showBtn && (
        <Button
          variant="contained"
          className={classes}
          onClick={(e) => {
            e.preventDefault();
            handleOpenAddPropertyModal();
          }}
        >
          Add property
        </Button>
      )}

      <Dialog
        open={addPropertyModal}
        fullWidth={true}
        maxWidth="lg"
        onClose={handleCloseAddPropertyModal}
        className="add-propery-modal zoom-modal-wrapper"
      >
        <DialogContent
          className="bg-white"
          dividers={true}
          sx={{ px: "2 !important", position: "relative" }}
          ref={dialogContentRef}
        >
          {(screen === "security-pin" ||
            screen === "2fa" ||
            screen === "propertyList" ||
            screen === "bio-metric" ||
            screen === "signature" ||
            (showSecurityHeaderToCheckout && screen === "cardsInfo") ||
            screen === "welcome" ||
            screen === "beneficiaries") && (
            <>
              <Typography
                align="center"
                variant="subtitle1"
                className="py-1 formHeader zoom-container"
                color={"white"}
                //  sx={{maxWidth:"880px"}}
                sx={{ position: "relative" }}
              >
                <LockIcon sx={{ fontSize: 12 }} className="me-1" />
                Secure connection
              </Typography>
              <Button
                onClick={handleCloseAddPropertyModal}
                className="modal-close-btn close-text hide-inner-close"
                style={{
                  position: "absolute !important",
                  top: 14,
                  right: 76,
                  background: "transparent",
                  textTransform: "none",
                }}
              >
                <CloseIcon
                  className="hide-inner-close"
                  sx={{ fontSize: 20, color: "white" }}
                />
              </Button>
            </>
          )}

          <Button
            onClick={handleCloseAddPropertyModal}
            className="modal-close-btn close-text hide-outer-close"
            style={{
              position: "absolute",
              right: 5,
              top: 5,
              background: "transparent",
              textTransform: "none",
            }}
          >
            Close
            <CloseIcon
              className="hide-outer-close"
              sx={{ fontSize: "2.5rem", color: "#15be53" }}
            />
          </Button>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
            className={`${
              isAnualPlanScreen && "border_clasess"
            } zoom-container`}
            sx={isPadding && { paddingBottom: "110px" }}
          >
            {screen === "2fa" && (
              <PersonalTokenScreen
                onBtnClick={() => {
                  dispatch(propertiesAction.clearPropertiesList());
                  if (selectedRowData?.investor_id) {
                    setScreen("propertyList");
                  } else {
                    setScreen("security");
                  }
                }}
                missingEmail={false}
                missingPhone={false}
                user_id={user_id}
                selectedRowData={selectedRowData}
                isAdmin={true}
              />
            )}
            {screen === "security" ? (
              <PropertySearchType
                onBtnClick={(e) => {
                  // e.preventDefault();
                  console.log("submitted");
                }}
                handleCloseAddPropertyModal={handleCloseAddPropertyModal}
                isByPassOptionAvailable={isByPassOptionAvailable}
                onChangeModalScreen={() => setScreen("questions")}
                onCloseModalHandler={() => {
                  if (!isByPassOptionAvailable) {
                    getPropertyList();
                  }
                  if (isResumeSignup) {
                    setScreen("propertyList");
                  } else {
                    setScreen("subscriptions");
                  }
                  // handleCloseAddPropertyModal();
                }}
                isAdminCreateUser={isAdminCreateUser}
                user_id={user_id}
              />
            ) : (
              <SecurityInfoAndCardPayment
                screen={screen}
                onChangeModalScreen={setScreen}
                backToSearchProperty={setScreen}
                handleCloseAddPropertyModal={handleCloseAddPropertyModal}
                from={from}
                onCloseModalHandler={(type) => {
                  if (
                    !isByPassOptionAvailable &&
                    (type === "success" || callListingAPI)
                  ) {
                    getPropertyList();
                  }
                  if (
                    !closeAfterAddingProperty &&
                    selectedRowData?.json_last_step?.step < 4
                  ) {
                    setScreen("security-pin");
                  } else {
                    handleCloseAddPropertyModal(type);
                  }
                }}
                isByPassOptionAvailable={isByPassOptionAvailable}
                selectedData={selectedData}
                addPromoCode={true}
                skipVerification={skipVerification}
                setSkipVerification={setSkipVerification}
                isUserCreateProperty={isUserCreateProperty}
                completedProcess={completedProcess}
                user_id={user_id}
                isAdminCreateUser={isAdminCreateUser}
                selectedRowData={selectedRowData}
                isResumeSignup={isResumeSignup}
                promoCode={promoCode}
              />
            )}
          </DialogContentText>
        </DialogContent>
        {!showSecurityHeaderToCheckout && screen === "cardsInfo" && (
          <DialogActions sx={{ paddingBottom: "50px" }}>
            <div className="text-center mt-3 mb-0 w-100">
              <img src={Aurthorised} alt="Aurthorised" />
            </div>
          </DialogActions>
        )}
      </Dialog>
      {/* {openAlertModal && !cardDetails?.data?.length > 0 && (
        <>
          <AlertDialog
            type="Warning"
            openPopup={openAlertModal}
            onClose={true}
            onCloseFun={() => {
              setOpenAlertModal(false);
            }}
            buttons={
              <>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  sx={{
                    backgroundColor: "#ffffff !important",
                    color: "black !important",
                    border: "1px #9cb8a6 solid !important",
                  }}
                  onClick={() => {
                    setOpenAlertModal(false);
                  }}
                >
                  ok, close
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={() => {
                    setOpenAlertModal(false);
                    if (
                      userData?.user_type?.includes(ROLES?.professional) &&
                      !userData?.role?.includes(ROLES?.customer)
                    ) {
                      navigate("/professional-profile", {
                        replace: true,
                        state: { isAddPaymentModal: true },
                      });
                    } else {
                      navigate("/user-profile", {
                        replace: true,
                        state: { isAddPaymentModal: true },
                      });
                    }
                  }}
                >
                  ok, let's go
                </Button>
              </>
            }
            mesage={
              "You don't have any cards added. Please add the card first from the user profile page."
            }
            footer={false}
          />
        </>
      )} */}
    </>
  );
};

export default AddPropertyPopup;
