/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Loader } from "../../common/loader";
import PhotoIdScreen from "../../common/capture-image/photoIdScreen";
import { userSigInAction } from "../../../redux/slices/user/userSignInSlice";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { decryptPayload, subUsersLoginCount } from "../../helpers/utils";
import { ROLES } from "../../helpers/constants";
import useCloseCameraStream from "../../hooks/useCloseCameraStream";

const BioMetricAuth = ({ backToAuth, code }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isCamera, setIsCamera] = useState(false);
  const [faceErr, setFaceErr] = useState("");
  const [isCameraResponseChecked, setIsCameraResponseChecked] = useState(false);

  const { updateCameraState } = useCloseCameraStream();
  const userCredentials = useSelector(
    (state) => state.signInUser.userCredentials
  );

  const verifyFace = useSelector((state) => state.signInUser.verifyFace);

  useEffect(() => {
    console.log("Face verify -->", verifyFace);
    if (Object.keys(verifyFace).length > 0) {
      console.log("validate 1");
      handleClose();
    }
  }, [verifyFace]);

  const handleClose = () => {
    const previousPath = localStorage.getItem("previousPath");
    if (Object.keys(verifyFace).length > 0) {
      let uID = verifyFace?.data?.user?.address?.user_id;
      if (
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.secondaryUser
        )
      ) {
        subUsersLoginCount(uID);
      }
      localStorage.setItem("uID", uID);
      if (!verifyFace?.success) {
        setFaceErr(verifyFace?.data?.message);
      } else {
        dispatch(userSigInAction.clearStoreData({}));
        localStorage.setItem("stanupPopupCount", 1);
        if (code === "ios" || code === "android") {
          localStorage.setItem("isIos", true);
        } else {
          localStorage.removeItem("isIos");
        }
        if (
          previousPath &&
          parseInt(uID) === parseInt(localStorage.getItem("previousUserID"))
        ) {
          navigate(previousPath, { replace: false });
          localStorage.removeItem("previousPath");
        } else {
          localStorage.setItem("paymentPopup", 0);

          if (localStorage.getItem("plan_renewal")) {
            navigate("/subscriptions", { replace: false });
          } else {
            if (localStorage.getItem("code") === "req_doc_email") {
              navigate("/requested-documents", { replace: false });
              localStorage.removeItem("code");
            } else {
              navigate("/user-profile", { replace: false });
            }
          }
        }
        setFaceErr("");
      }
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const onsubmit = (image) => {
    let inputParam = {
      face_image: image,
      ...userCredentials,
    };
    console.log("on submit==>.", inputParam);
    handleToggle();
    dispatch(
      userSigInAction.getVerifyFace({
        url: "verify-face",
        data: inputParam,
      })
    );
  };
  const removeValidationMsg = () => {
    setFaceErr("");
  };
  const checkMediaAccess = async () => {
    console.log("navigator", navigator);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setIsCamera(true);
        setIsCameraResponseChecked(true);
        updateCameraState(stream);
      }
    } catch (err) {
      if (err.name === "NotAllowedError") {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
      } else if (
        err.name === "NotFoundError" ||
        err.name === "NotReadableError"
      ) {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
      } else {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
        console.log("Error accessing camera:", err);
      }
    }

    // navigator.mediaDevices
    //   .enumerateDevices()
    //   .then((devices) =>
    //     devices.forEach((device) => {
    //       if (device.kind?.includes("videoinput")) {
    //         setIsCamera(true);
    //         console.log("YY Has Video Access");
    //       }
    //     })
    //   )
    //   .catch((err) => {
    //     if (err) {
    //       console.log("error occurred", err);
    //       setIsCamera(false);
    //     }
    //   });
  };

  useEffect(() => {
    console.log("useEffect");
    checkMediaAccess();
  }, []);
  return (
    <>
      <PhotoIdScreen
        biometric={true}
        backBtn={true}
        backToAuth={backToAuth}
        onsubmit={onsubmit}
        faceErr={faceErr}
        isCamera={isCamera}
        removeValidationMsg={removeValidationMsg}
        isCameraResponseChecked={isCameraResponseChecked}
      />
      <Loader open={open} />
    </>
  );
};

export default BioMetricAuth;
