import { useEffect, useState } from "react";

export default function ToggleSwitch({
  initialState = false,
  onChange = () => {},
  loader = false,
  disable,
}) {
  const [isOn, setIsOn] = useState(initialState);

  const handleToggle = () => {
    const newState = !isOn;
    setIsOn(newState);
    onChange(newState);
  };
  useEffect(() => {
    setIsOn(initialState);
  }, [loader]);

  return (
    <button
      role="switch"
      disabled={disable}
      aria-checked={isOn}
      onClick={handleToggle}
      className="btn d-inline-flex align-items-center justify-content-between position-relative"
      style={{
        width: "70px",
        height: "32px",
        borderRadius: "16px",
        backgroundColor: isOn ? "#84E4AA" : "#e9ecef",
        padding: "4px",
        border: "none",
        transition: "background-color 0.2s",
      }}
      onFocus={(e) => e.target.blur()}
    >
      {/* Text ON/OFF */}
      <span
        className={`position-absolute top-50 translate-middle-y fw-bold small ${
          isOn ? "text-dark" : "text-secondary"
        }`}
        style={{
          left: isOn ? "12px" : "auto",
          right: isOn ? "auto" : "12px",
          transition: "color 0.2s",
        }}
      >
        {isOn ? "ON" : "OFF"}
      </span>

      {/* Thumb/Knob */}
      <span
        className="position-absolute rounded-circle bg-white shadow"
        style={{
          width: "24px",
          height: "24px",
          top: "50%",
          transform: `translate(${isOn ? "38px" : "0px"}, -50%)`,
          transition: "transform 0.2s",
        }}
      />
    </button>
  );
}
