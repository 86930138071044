import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";

import auto_activity1 from "../../assets/home/auto_activity_zone.svg";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";

import "./Style.scss";
import { Loader } from "./loader";
import { getObjLength } from "../helpers/utils";

const InvestorsAnnaulSubscriptionPlan = ({
  uid = null,
  paySecure = () => {},
  propertiesIds = [],
  promoCode = undefined,
  selectedData = undefined,
  isBack_btn = false,
  backBtnFunction = () => {},
}) => {
  const dispatch = useDispatch();
  const [yearlyPlanData, setYearlyPlanData] = useState({});
  const [pricingBreakdown, setPricingBreakdown] = useState({});
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const { propertyIds, investorsAnnualPlanData, userLoginLoader } = useSelector(
    (state) => state.signUpUser
  );

  const activatePlan = () => {
    dispatch(userSigupAction.updateInvestorsPricingUI(false));
    paySecure(yearlyPlanData);
  };
  useEffect(() => {
    dispatch(userSigupAction.updateInvestorsPricingUI(true));
    dispatch(
      userSigupAction.getInvestorsAnnualPlanData({
        url: "investors-price-details",
        data: {
          user_id: uid,
          property_id: propertyIds?.length > 0 ? propertyIds : propertiesIds,
          property_ids: getObjLength(selectedData)
            ? [
                {
                  address: selectedData?.address,
                  property_id: selectedData?.property_id,
                  state: selectedData?.state,
                  city: selectedData?.city,
                  county: selectedData?.county,
                  zip: selectedData?.zip,
                },
              ]
            : undefined,
          promo_code: promoCode,
        },
        type: "post",
      })
    );
  }, []);

  useEffect(() => {
    if (getObjLength(investorsAnnualPlanData)) {
      if (investorsAnnualPlanData?.success) {
        setYearlyPlanData(investorsAnnualPlanData?.data?.[0]);
        setPricingBreakdown(
          investorsAnnualPlanData?.data?.[0]?.price_breakdown
        );
        setPropertiesCount(
          parseInt(
            investorsAnnualPlanData?.data?.[0]?.price_breakdown
              ?.no_of_properties
          ) || "-"
        );
        setIsError(false);
      } else {
        setIsError(true);
      }
    }
  }, [investorsAnnualPlanData]);

  return (
    <Box className="investors_plans">
      <Box className="accountBoxWrapper">
        <Box className="investors_header">
          <Typography align="center" className="plan_header px-2">
            Equity Protect Yearly
          </Typography>
          <div className="px-2">
            <img src={auto_activity1} alt="auto_activity_zone" />
          </div>
        </Box>
        <Divider />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {isError ? (
            <Box sx={{ width: "100%" }} className="text-center">
              <Stack direction="row" mt={3}>
                <p className="text-danger">
                  {investorsAnnualPlanData?.data?.message}
                </p>
              </Stack>
            </Box>
          ) : (
            <Box sx={{ width: "100%" }} className="text-center">
              <Stack direction="row" mt={3}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    order={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                    mb={2}
                  >
                    <Box
                      className="paymentMode plans-block w-100 d-flex justify-content-center align-items-center"
                      sx={{ margin: 0 }}
                    >
                      <div className={`planCard d-flex flex-column `}>
                        <div className={`card_inner w-100 break-word`}>
                          <div className="pricing_Wrapper text-center">
                            <p className="para mb-1 activation_text mt-2">
                              No Set-up or Activation Fee
                            </p>
                            <h5 className={`mb-1 plan_price`}>
                              {getObjLength(yearlyPlanData)
                                ? "$" +
                                  (yearlyPlanData?.amount / 100).toFixed(2)
                                : "-"}
                            </h5>
                            <p className="para mb-3">for the first Year</p>
                            <p className="per_year_price mb-1 mt-0">
                              {getObjLength(yearlyPlanData) ? (
                                <>
                                  + <span className="fw-500">$</span>
                                  {(
                                    yearlyPlanData?.recurring_amount / 100
                                  ).toFixed(2)}
                                  /yr
                                </>
                              ) : (
                                "-"
                              )}
                            </p>
                            <p className="para mb-0 mt-0">
                              per year for annual recertification
                            </p>
                            <p className="para mb-0 mt-0">
                              + applicable sales tax
                            </p>
                            <p className="para mb-0 w-100">
                              applicable Deposit for recording fee (One time
                              fee)
                            </p>
                            <p className="para mt-3 activation_text">
                              Savings of{" "}
                              {yearlyPlanData?.first_year_savings || "-"} for
                              the first Year
                              <br />
                              and {yearlyPlanData?.yearly_savings || "-"} every
                              year after.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    order={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                    mb={2}
                  >
                    <Box
                      className="paymentMode plans-block w-100 d-flex justify-content-center align-items-center"
                      sx={{ margin: 0 }}
                    >
                      <div className={`planCard d-flex flex-column`}>
                        <div
                          className={`price_breakdown_card card_inner w-100 break-word`}
                        >
                          <div className="pricing_Wrapper text-start">
                            <p className="para mb-0 price_breakdown_text">
                              Price breakdown
                            </p>
                            <div className="pricing_cards">
                              <p className={`para mb-0 price_breakdown_left`}>
                                {propertiesCount || "-"}{" "}
                                {propertiesCount === 1
                                  ? "Property"
                                  : "Properties"}{" "}
                                x{" "}
                                {getObjLength(yearlyPlanData)
                                  ? "$" +
                                    (yearlyPlanData?.amount / 100).toFixed(2)
                                  : "-"}
                              </p>
                              <p className="para mb-0 price_breakdown_right">
                                $
                                {pricingBreakdown?.subscription_amount_actual ||
                                  "-"}
                              </p>
                            </div>
                            <div className="pricing_cards discount_section">
                              <p className={`para mb-0 price_breakdown_left`}>
                                {propertiesCount > 4 && propertiesCount < 9
                                  ? "20%"
                                  : propertiesCount > 9
                                  ? "30%"
                                  : "10%"}{" "}
                                multi-property Discount
                              </p>
                              <p className="para mb-0 price_breakdown_right">
                                -${pricingBreakdown?.discount || ""}
                              </p>
                            </div>
                            {promoCode && (
                              <div className="pricing_cards discount_section">
                                <p className={`para mb-0 price_breakdown_left`}>
                                  Applied Promo Code Discount
                                </p>
                                <p className="para mb-0 price_breakdown_right">
                                  -${pricingBreakdown?.promocode_discount || ""}
                                </p>
                              </div>
                            )}
                            <div className="pricing_cards">
                              <p className={`para mb-0 price_breakdown_left`}>
                                {propertiesCount || ""}{" "}
                                {propertiesCount === 1
                                  ? "Property"
                                  : "Properties"}{" "}
                                Total
                              </p>
                              <p className="para mb-0 price_breakdown_right">
                                ${pricingBreakdown?.sub_amount || ""}
                              </p>
                            </div>
                            <div className="pricing_cards">
                              <p className={`para mb-0 price_breakdown_left`}>
                                Recording Fee per county
                              </p>
                              <p className="para mb-0 price_breakdown_right">
                                ${pricingBreakdown?.recording_charge || ""}
                              </p>
                            </div>
                            <div className="pricing_cards">
                              <p className={`para mb-0 price_breakdown_left`}>
                                State Sales Tax
                              </p>
                              <p className="para mb-0 price_breakdown_right">
                                ${pricingBreakdown?.sales_tax || ""}
                              </p>
                            </div>
                            <Divider className="total_amt_divider" />
                            <div className="pricing_cards total_amount">
                              <p className={`para mb-0 price_breakdown_left `}>
                                Total to be charged
                              </p>
                              <p className="para mb-0 price_breakdown_right">
                                ${pricingBreakdown?.total || ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
              {isBack_btn && (
                <Button
                  className="other_popup_btn popup_Btn m-1"
                  onClick={backBtnFunction}
                >
                  Back
                </Button>
              )}
              <Button
                className="success_popup_btn popup_Btn m-1"
                onClick={activatePlan}
              >
                Activate plan
              </Button>
            </Box>
          )}
        </Grid>
      </Box>
      <Loader open={userLoginLoader} />
    </Box>
  );
};

export default InvestorsAnnaulSubscriptionPlan;
