import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";

import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { Backdrop } from "@mui/material";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FacebookIcon from "@mui/icons-material/Facebook";
import ListItemButton from "@mui/material/ListItemButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";

import AccountMenu from "./accountMenu";
import { Loader } from "../common/loader";
import CallUsNow from "../common/CallUsNow";
import NotificationMenu from "./notifications";
import AlertDialog from "../common/AlertDialog";
import pusher from "../../notifications/Pusher";
import LogoIcon from "../../assets/dashboard/icon.svg";
import { ROUTESFORSITEMAP } from "../../routes/routerLists";
import MenuIconImg from "../../assets/dashboard/menu_open.svg";
import SidebarLogo from "../../assets/footer/equity black.png";
import Facebook_Icon from "../../assets/articles/facebook.svg";
import Instagram_Icon from "../../assets/articles/instagram.svg";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import VerifyQRCode from "../userProfile/Dashboard/StannupVerification/VerifyQRCode";
import { notificationActions } from "../../redux/slices/notification/notificationSlice";
import {
  DEFAULT_URL,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  ROLES,
} from "../helpers/constants";
import {
  decryptPayload,
  encryptPayload,
  getObjLength,
  isMobileDeviceCheck,
  reactPixelEventOnlyForCustomer,
} from "../helpers/utils";

import "../../styles/appbar.scss";
import "../../styles/sidebar.scss";
const drawerWidth = 203;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  boxShadow: "0px 0px 2px rgba(52, 75, 91, 0.3)",
  border: "none",
  zIndex: "999",
  overflowX: "hidden",
  overflowY: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: "0px 0px 2px rgba(52, 75, 91, 0.3)",
  border: "none",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("md")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down("md")]: {
    width: "0",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 3),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  marginLeft: theme.spacing(7),
  width: `calc(100% - ${theme.spacing(8)} - 1.5px)`,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: "0",
  },
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 0px 2px rgba(52, 75, 91, 0.3)",
  // zIndex: theme.zIndex.drawer + 1,
  zIndex: "9",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ comp }) {
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  // states
  const [open, setOpen] = useState(true);
  const [openAlert, setOpenAlert] = useState(true);
  const [loader, setLoader] = useState(true);
  const [qrPopup, setQRPopup] = useState(false);
  const [classExists, setClassExists] = useState(false);
  const [opacity, setOpacity] = useState(1);

  // store data
  const {
    userProfileDetails,
    loggedInEmail,
    qrCodeLoader,
    QRCodePopup,
    loginAsSpouse: isSecondaryUser,
    reRenderCount,
  } = useSelector((state) => state?.userProfile);
  // const { notificationData } = useSelector((state) => state?.notifications);

  const params = window.location.search;
  let code = params?.split("=")[1];
  if (code === "ios" || code === "android") {
    localStorage.setItem("source", encryptPayload(code));
  }

  const clearUserData = () => {
    dispatch(userProfileAction.clearUserProfileData());
    reactPixelEventOnlyForCustomer("Signout", { email: loggedInEmail });
  };

  const handleModalClosing = (path) => {
    if (path === window.location.pathname) {
      dispatch(userProfileAction.setReRenderCount(reRenderCount + 1));
    }
    if (path !== "/requested-documents") {
      dispatch(userProfileAction.setDefaultSelectedTab(1));
    }
    if (window.innerWidth < 575) {
      setOpen((prevState) => !prevState);
    }
  };

  // pull to refresh
  const handleRefresh = async () => {
    console.log("Refreshing called...");
    if (!opacity) {
      dispatch(userProfileAction.setReRenderCount(reRenderCount + 1));
    }
  };

  useEffect(() => {
    setOpen(true);
    setLoader(true);
    dispatch(
      userProfileAction.userProfileData({
        url: "profile",
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (getObjLength(userProfileDetails) && userProfileDetails?.success) {
      if (userProfileDetails?.data?.status === "inactive") {
        localStorage.clear();
        localStorage.setItem("preference", true);
        window.location.href = `${DEFAULT_URL}login`;
      }
    }
    dispatch(userSigupAction.updateInvestorsPricingUI(true));
    dispatch(
      userSigupAction.fetchInvestorID(userProfileDetails?.data?.investor_id)
    );
    if (userProfileDetails?.data) {
      if (window.innerWidth < 575) {
        setOpen(false);
      }
      setLoader(false);
    }
    const addressId = localStorage.getItem("addressId");

    if (addressId !== null || addressId?.length > 0) {
      console.log("addressId==>", addressId);
      dispatch(
        userProfileAction.getQRCodeVerificationData({
          url: "addressVerifyQRcode?id=" + addressId,
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch, userProfileDetails]);

  useEffect(() => {
    console.log("location name", pathname);
    if (pathname?.length > 0) {
      localStorage.setItem("path", pathname);
    }
  }, [pathname]);

  useEffect(() => {
    console.log("qrCodeAddData==>", QRCodePopup);
    // if(qrCodeAddData?.data?.data?.is_verified === 0){
    setQRPopup(QRCodePopup);
    // }
  }, [QRCodePopup]);

  // fetch opacity of the backdrop and set the pull to Refresh feacture
  useEffect(() => {
    // Create a new MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver((mutationsList) => {
      // Check if the class exists in the DOM
      const classExistsInDOM =
        document.querySelector(".MuiBackdrop-root") !== null;

      // Update the state based on the class existence
      setClassExists(classExistsInDOM);
    });

    // Start observing changes in the entire document
    observer.observe(document, { subtree: true, childList: true });

    // Check the opacity of the .MUI-Backdrop element periodically
    const opacityCheckInterval = setInterval(() => {
      const backdrop = document.querySelector(".MuiBackdrop-root");
      if (backdrop) {
        const computedStyle = getComputedStyle(backdrop);
        const backdropOpacity = computedStyle.getPropertyValue("opacity");
        const currentOpacity = Number(backdropOpacity);

        // console.log("backdropOpacity===>", backdropOpacity);
        // Only update the state if opacity has changed
        if (currentOpacity !== opacity) {
          setOpacity(currentOpacity);
        }
      } else {
        setOpacity(null);
      }
    }, 1000); // Check every second

    // Clean up the observer and interval when the component unmounts
    return () => {
      observer.disconnect();
      clearInterval(opacityCheckInterval);
    };
  }, [opacity]);

  const userPreference = (access) => {
    localStorage.setItem("preference", access);
    setOpenAlert(false);
  };

  // console.log("notification echo", echo);

  useEffect(() => {
    if (userProfileDetails?.data?.user_id) {
      pusher
        .subscribe(`App.User.${userProfileDetails?.data?.user_id}`)
        .unbind("equity_protect")
        .bind("equity_protect", (data) => {
          console.log("in-app data", data);
          dispatch(
            notificationActions?.appendLiveNotifications({
              // appned new notifications in existing notifications
              ...data,
              read_at: null,
              // created_at: new Date(),
            })
          );
          // write native methods here..
          dispatch(notificationActions?.setIsNewNotificationArrive(true)); // popup notifications
        });
    }
  }, [dispatch, userProfileDetails?.data?.user_id]);

  // take routes with the lastmodified date and path for sitemap.xml function
  // uncomment only when you have to get the list of routes with the above parameters
  // console.log("ROUTESFORSITEMAP", ROUTESFORSITEMAP);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          className={open ? "open" : "close"}
        >
          <Toolbar className="toolbar">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
            >
              <img
                src={MenuIconImg}
                alt="Menu Icon"
                className="d-none d-md-block"
              />
              <MenuIcon color="action" className="d-block d-md-none" />
            </IconButton>
            <Link
              className="text-white text-center text-decoration-none d-block d-md-none"
              to="/"
            >
              <IconButton
              // onClick={() => {
              //   navigate("/");
              // }}
              >
                <img
                  src={SidebarLogo}
                  alt="Logo"
                  className="d-block d-md-none"
                  style={{ height: "30px", width: "84px" }}
                />
              </IconButton>
            </Link>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                ml: "auto",
              }}
            >
              <NotificationMenu />
              <AccountMenu />
              <div className="px-1 px-md-3 cursor-pointer">
                <CallUsNow
                  type="help"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                />
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          className={
            open
              ? window.innerWidth < 575
                ? "open position-absolute"
                : "open"
              : window.innerWidth < 575
              ? "close position-absolute"
              : "close"
          }
        >
          <DrawerHeader sx={{ minHeight: "24px" }} className="mt-2">
            <div
              className="sidebarLogoWrapper "
              style={{ width: open ? "80%" : "100%", cursor: "pointer" }}
              // onClick={() => {
              //   navigate("/");
              // }}
            >
              <Link
                className="text-white text-center text-decoration-none"
                to="/"
              >
                <Box sx={{ display: open ? "visible" : "none" }}>
                  <img src={SidebarLogo} alt="Sidebar Logo" />
                </Box>
                <Box sx={{ display: open ? "none" : "visible" }}>
                  <img
                    src={LogoIcon}
                    alt="Logo Icon"
                    // style={{ maxHeight: "24px" }}
                  />
                </Box>
              </Link>
            </div>
          </DrawerHeader>
          <List
            className="menuItemsList d-flex flex-column"
            sx={{ flex: "1 0 auto" }}
          >
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname.split("/").includes("properties")
                    ? "active"
                    : ""
                }`}
                onClick={() => handleModalClosing("/properties")}
                to="/properties"
              >
                <ListItemButton
                  sx={{
                    width: 250,
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={`menu-item`}
                    primary={"Properties"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname.split("/").includes("subscriptions")
                    ? "active"
                    : ""
                }`}
                onClick={() => handleModalClosing("/subscriptions")}
                to="/subscriptions"
              >
                <ListItemButton
                  sx={{
                    width: 250,
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AccountBalanceWalletOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Subscriptions"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname.split("/").includes("documents")
                    ? "active"
                    : ""
                }`}
                onClick={() => handleModalClosing("/documents")}
                to="/documents"
              >
                <ListItemButton
                  sx={{
                    width: 250,
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FolderCopyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Documents"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            {/* <ListItem disablePadding>
            <Link
              className={`text-white text-center text-decoration-none ${
                window.location.pathname.split("/").includes("market-value")
                  ? "active"
                  : ""
              }`}
              to="/market-value"
            >
              <ListItemButton
                sx={{
                  width: 250,
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <MarketValueIconSVG />
                </ListItemIcon>
                <ListItemText
                  className="menu-item"
                  primary={"Market Value"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem> */}
            {!isSecondaryUser &&
              // add this condition temporarily till switch user functionality not completed
              !decryptPayload(localStorage.getItem("roles"))?.includes(
                ROLES.secondaryUser
              ) && (
                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname.split("/").includes("sub-user")
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleModalClosing("/sub-user")}
                    to="/sub-user"
                  >
                    <ListItemButton
                      sx={{
                        width: 250,
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SupervisedUserCircleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Allow Access"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              )}
            {!isSecondaryUser &&
              !userProfileDetails?.data?.parent_type?.includes(
                ROLES.secondaryUser
              ) && (
                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname
                        .split("/")
                        .includes("professionals")
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleModalClosing("/professionals")}
                    to="/professionals"
                  >
                    <ListItemButton
                      sx={{
                        width: 250,
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <GroupOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Professionals"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              )}

            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname
                    .split("/")
                    .includes("requested-documents")
                    ? "active"
                    : ""
                }`}
                onClick={() => handleModalClosing("/requested-documents")}
                to="/requested-documents"
              >
                <ListItemButton
                  sx={{
                    width: 250,
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FileOpenOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Requested Documents"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>

            {/* <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname.split("/").includes("logs")
                    ? "active"
                    : ""
                }`}
                onClick={() => handleModalClosing("/logs")}
                to="/logs"
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    width: 250,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Logs"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem> */}

            <ListItem disablePadding className="mt-auto">
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname.split("/").includes("settings")
                    ? "active"
                    : ""
                }`}
                onClick={() => handleModalClosing("/settings")}
                to="/settings"
              >
                <ListItemButton
                  sx={{
                    width: 250,
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <img src={SettingsIcon} alt="Settings" /> */}
                    <SettingsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Settings"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
          <Stack
            direction={open ? "row" : "column"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            className="text-center border-top pt-2"
            sx={{}}
          >
            <a
              href={INSTAGRAM_LINK}
              target="_blank"
              rel="noreferrer"
              className="px-2 pt-2"
            >
              <img
                src={Instagram_Icon}
                alt="Facebook"
                style={{ height: "25px" }}
              />
              {/* <InstagramIcon
                style={{ color: "#E4405F" }}
                fontSize={!open ? "large" : "medium"}
              /> */}
            </a>

            <a
              href={FACEBOOK_LINK}
              target="_blank"
              rel="noreferrer"
              className="px-2 pt-2"
            >
              <img
                src={Facebook_Icon}
                alt="Facebook"
                style={{ height: "25px" }}
              />
              {/* <FacebookIcon fontSize={!open ? "large" : "medium"} /> */}
            </a>
          </Stack>
          <Box
            className="text-center"
            // p={3}
            sx={{ paddingBottom: "9.5rem", display: open ? "visible" : "none" }}
          >
            <CallUsNow
              type="text"
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            />
          </Box>
          <Box
            className="text-center p-0 py-2"
            sx={{ display: open ? "none" : "block" }}
          >
            <div className="call cursor-pointer">
              <CallUsNow
                type="icon"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              />
            </div>
          </Box>
        </Drawer>

        <Box
          component="main"
          className="main p-4 p-md-5 mt-5 mt-md-0 pb-0 pb-md-0 position-relative"
          sx={{ flexGrow: 1, overflowX: "hidden" }}
          key={reRenderCount}
        >
          <DrawerHeader sx={{ minHeight: "40px !important" }} />
          {userProfileDetails?.success ? (
            isMobileDeviceCheck() ? (
              <PullToRefresh isPullable={!opacity} onRefresh={handleRefresh}>
                {comp}
              </PullToRefresh>
            ) : (
              comp
            )
          ) : (
            <Box className="text-center">
              <Button
                className="text-danger text-capitalize"
                onClick={clearUserData}
              >
                {userProfileDetails?.data?.message ? (
                  <>
                    {`${userProfileDetails?.data?.message}`}
                    <Link to={"/login"} className="bold text-decoration-none">
                      &nbsp;Click here to Login again.
                    </Link>
                  </>
                ) : null}
              </Button>
            </Box>
          )}
        </Box>

        <Loader open={loader || qrCodeLoader} />
        {(localStorage.getItem("preference") === null ||
          localStorage.getItem("preference")?.length === 0) && (
          <AlertDialog
            type="Warning"
            // width="md"
            openPopup={openAlert}
            onClose={false}
            mesage={
              <Box className="text-start">
                <Typography className=" fw-600 text-center m-3" variant="h6">
                  This website uses cookies
                </Typography>
                <p className="m-4 fw-500">
                  We use essential cookies to make our site work. With your
                  consent, we may also use non-essential cookies to improve user
                  experience, share information about your use of our site with
                  our social media partners, advertising, analytics partners and
                  analyze website traffic. By clicking “ACCEPT” you agree to our
                  website's cookie use. You can change your cookie settings at
                  any time by clicking “Preferences.”
                </p>
              </Box>
            }
            buttons={
              <>
                <Button
                  className="popup_Btn other_popup_btn m-2"
                  onClick={() => userPreference(false)}
                >
                  Deny
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => userPreference(true)}
                >
                  Accept
                </Button>
              </>
            }
          />
        )}

        {
          // (localStorage.getItem("addressId") !== null ||
          //   localStorage.getItem("addressId")?.length > 0) &&
          //   (localStorage.getItem("authToken") !== null ||
          //     localStorage.getItem("authToken")?.length > 0) &&
          qrPopup && !localStorage.getItem("stanupPopupCount") && (
            <VerifyQRCode
              openPopup={true}
              addressId={localStorage.getItem("addressId")}
            />
          )
        }
      </Box>

      {open && window.innerWidth < 575
        ? ReactDOM.createPortal(
            <>
              <Backdrop
                sx={{ zIndex: 995 }}
                open={open}
                onClick={() => {
                  setOpen((prevState) => !prevState);
                }}
              />
            </>,
            document.body
          )
        : null}
    </>
  );
}
