import React, { useState } from "react";

import { useSelector } from "react-redux";

import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Avatar, Box, Tooltip, Zoom } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";

import { DEFAULT_URL } from "../../helpers/constants";
import { getLocalFormatedDate } from "../../helpers/utils";
import SettingsIconSVG from "../../common/SettingsIconSVG";
import ViewIconSVG from "../../common/customSVGs/ViewIconSVG";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";

const columns = [
  {
    key: 1,
    id: "no",
    disablePadding: true,
    label: "No.",
    minWidth: 50,
  },
  {
    key: 2,
    id: "logo",
    disablePadding: true,
    label: "Logo",
    minWidth: 115,
  },
  {
    key: 3,
    id: "company_name",
    disablePadding: true,
    label: "Company",
    minWidth: 115,
  },
  {
    key: 4,
    id: "email",
    disablePadding: true,
    label: "Email",
    sorting: true,
    minWidth: 115,
  },
  //   {
  //     key: 5,
  //     id: "phone",
  //     disablePadding: true,
  //     label: "Phone",
  //     sorting: true,
  //     minWidth: 80,
  //   },
  {
    key: 6,
    id: "created_at",
    disablePadding: true,
    label: "Created",
    // sorting: true,
    minWidth: 115,
  },

  {
    key: 7,
    id: "updated_at",
    disablePadding: true,
    label: "Modified",
    // sorting: true,
    minWidth: 115,
  },
  {
    key: 8,
    id: "url",
    disablePadding: true,
    label: "URL",
    sorting: true,
    minWidth: 115,
  },
  {
    key: 9,
    id: "setting",
    disablePadding: true,
    label: "Action",
    minWidth: 20,
  },
];

const TitleCompanyListTable = ({
  handleViewRepresentativeBtn = () => {},
  setSelectedRow = () => {},
  selectedRow,
}) => {
  const { titleCompanyListData } = useSelector((state) => state.professionals);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  const rowsData = titleCompanyListData?.success
    ? titleCompanyListData?.data
    : [];
  const PER_PAGE = 10;
  const open = Boolean(anchorEl);

  const handleMenuClick = (event, data) => {
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "100%" }} className="mt-5">
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            stickyHeader
          >
            <TableHeaderForSorting
              columns={columns}
              //   handleSorting={handleSorting}
              //   directionFlow={directionFlow}
              //   setSelectedColumn={setSelectedColumn}
              //   selectedColumn={selectedColumn}
            />
            <TableBody>
              {rowsData?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.address}
                  >
                    <TableCell align="left">
                      {(page - 1) * 10 + index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <span className="title_company-logo">
                        <Avatar
                          src={row?.logo}
                          className="title_company_logo"
                        />
                      </span>
                    </TableCell>

                    <TableCell align="left" className="text-capitalize">
                      {row?.full_name || "-"}
                    </TableCell>
                    <TableCell align="left">{row?.email || "-"}</TableCell>
                    {/* <TableCell align="left">{row?.phone || "-"}</TableCell> */}

                    <TableCell align="left">
                      {row?.created_at
                        ? getLocalFormatedDate(row?.created_at)
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row?.created_at
                        ? getLocalFormatedDate(row?.updated_at)
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip
                        title={`Copy ${row?.first_name} Homepage Link`}
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                      >
                        <IconButton
                          sx={{
                            color: "#0395FF",
                            fontSize: "1.4rem",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(
                              DEFAULT_URL + row?.source + "/" + row?.domain
                            );
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={(e) => handleMenuClick(e, row)}
                          size="small"
                          className="settings-button"
                          aria-controls={open ? "settings-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <SettingsIconSVG />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {!rowsData.length > 0 && (
            <Typography className="p-5 text-center" variant="h6">
              No Data Found.
            </Typography>
          )}
        </TableContainer>
      </Paper>

      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          key="view"
          onClick={() => handleViewRepresentativeBtn(selectedRow)}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <ViewIconSVG />
            <Typography className="setting-menu-text">
              View Representative
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
      {/* {recordingFeesList?.count > PER_PAGE && (
            <Box id="pagination-container">
              <CustomPagination
                count={count}
                page={page}
                handlePageChange={handlePageChange}
              />
            </Box>
          )} */}
    </Box>
  );
};

export default TitleCompanyListTable;
