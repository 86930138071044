import { useState } from "react";
import { useEffect } from "react";
import React, { useRef } from "react";

import { Cropper } from "react-cropper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery, useTheme } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import CardCRUD from "../../common/CardCRUD";
import FileUpload from "../../common/FileUpload";
import BiometricAndSign from "./BiometricAndSign";
import AlertDialog from "../../common/AlertDialog";
import { ServicerProfileForm } from "./ServicerProfileForm";
import StannupInfoModal from "../../common/StannupInfoModal";
import SupportEmailLink from "../../common/SupportEmailLink";
import { closeDeleteIconStyle } from "../signup/SPSignupForm";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import ReferralLink from "../../userProfile/Dashboard/ReferralLink";
import { ServicerProfessionalForm } from "./ServicerProfessionalForm";
import PaymentFailed from "../../userProfile/Dashboard/PaymentFailed";
import ProfileDP from "../../../assets/dashboard/Dummy_Profile_photo.png";
import UploadProfilePictureModal from "../../common/UploadProfilePictureModal";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import pdfPlaceholder from "../../../assets/service-provider/pdf_placeholder.webp";
import {
  clearLocalStoarge,
  createFileUrl,
  getObjLength,
  windowsAndroidNativeMethods,
} from "../../helpers/utils";
import wordDocPlaceholder from "../../../assets/service-provider/document_placeholder.webp";
import { serviceProviderSignInAction } from "../../../redux/slices/serviceProvider/SPSignINSlice";
import PropertiesListing from "../../userProfile/Dashboard/StannupVerification/PropertiesListing";
import { serviceProviderProfileAction } from "../../../redux/slices/serviceProvider/servicerProfile/servicerProfileSlice";
import {
  AddressVerificationColumns,
  SERVICE_PROVIDERS_URL,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../../helpers/constants";

const styles = {
  "&.MuiButton-outlined": {
    background: "#FFFFFF",
    border: "1px solid #15BE53",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#15BE53",
    padding: "11px",
    minWidth: "auto",
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
};

const Paperstyles = {
  "&.MuiPaper-elevation0": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
    borderRadius: "4px",
    p: 3,
    mb: 2,
  },
};

const ServicerProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const cropperRef = useRef();
  const addressVerificationRef = useRef(null);

  // states
  const [edit, setEdit] = useState(false);
  const [editProfessional, setEditProfessional] = useState(false);
  const [isEditProfilePictureModalOpen, setIsEditProfilePictureModalOpen] =
    useState(false);
  const [openStanupPopup, setOpenStanupPopup] = useState(false);
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploadedServiceAgreement, setUploadedServiceAgreement] =
    useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));

  // store data
  const {
    servicerProfileDetails,
    editProfileLoader,
    editedServicerProfileDetails,
    servicerProfessionalDetails,
    editedServicerProfessionalDetails,
    isResetPasswordRequired,
    professionalLoader,
    editProfessionalLoader,
    profileLoader,
    uploadedLogoDetails,
    uploadCompanyLogoLoader,
    deletedLogoDetails,
    deleteCompanyLogoLoader,
    uploadedServiceAgreementDetails,
    uploadServiceAgreementLoader,
    addressLoader,
    biometric_signLoader,
  } = useSelector((state) => state?.servicerProfile);
  const {
    StannupEmailDetails,
    paymnetDoneDetails,
    isPaymentPending,
    postcardLoader,
  } = useSelector((state) => state?.userProfile);

  console.log("uploadedLogoDetails", uploadedLogoDetails);
  const { spSignupLoader } = useSelector(
    (state) => state.serviceProviderSignup
  );

  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };

  // handle sign out button
  const handleSignOut = () => {
    dispatch(serviceProviderProfileAction.logoutPasswordRequired());
    dispatch(
      serviceProviderSignInAction.userSignoutData({
        url: "service-provider/logout",
        data: {},
        token: true,
      })
    );
    dispatch(serviceProviderSignInAction.clearSigninData());
    clearLocalStoarge();

    // logout native method call for mobile app
    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.invokeNativeLogout
    ) {
      console.log("Logout Native App got called...");
      window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
    }
    // call Android App Logout method
    windowsAndroidNativeMethods("logout");

    navigate("/login");
  };

  // handle edit profile picture button
  const handleEditProfilePicBtn = () => {
    if (formRef?.current) {
      formRef?.current?.resetForm();
    }
    setEdit(false);
    setIsEditProfilePictureModalOpen(true);
  };

  // handle edit profile picture button
  const handleEditProfilePicModalClose = () => {
    setIsEditProfilePictureModalOpen(false);
    setEdit(false);
  };

  // handle upload profile picture
  const handleUploadProfilePicture = (jpegFile) => {
    const formData = new FormData();

    formData.append("first_name", servicerProfileDetails?.data?.first_name);
    formData.append("last_name", servicerProfileDetails?.data?.last_name);
    formData.append("phone_number", servicerProfileDetails?.data?.phone_number);
    formData.append("address", servicerProfileDetails?.data?.address?.address);
    formData.append("city", servicerProfileDetails?.data?.address?.city);
    formData.append("state", servicerProfileDetails?.data?.address?.state);
    formData.append("zip", servicerProfileDetails?.data?.address?.zip);
    formData.append("old_password", "");
    formData.append("password", "");
    formData.append("password_confirmation", "");
    formData.append("profile_img", jpegFile);

    dispatch(
      serviceProviderProfileAction.editServicerProfileData({
        url: "service-provider/profile",
        data: formData,
        token: true,
      })
    );
  };

  const getCardListing = () => {
    dispatch(
      userProfileAction.userCardDetails({
        url: `service-provider/card/list`,
        data: {},
        token: true,
      })
    );
  };

  // get cropped image
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
    }
  };

  // when user select the image
  const onChange = async (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleUploadingCompanyLogo = () => {
    if (croppedImage) {
      dispatch(
        serviceProviderProfileAction.uploadCompanyLogo({
          url: "service-provider/upload-logo",
          data: {
            company_logo: croppedImage,
          },
          token: true,
        })
      );
    }
  };

  const handleUploadingServiceAgreement = () => {
    if (uploadedServiceAgreement) {
      dispatch(
        serviceProviderProfileAction.uploadServiceAgreement({
          url: "service-provider/upload-service-agreement",
          data: {
            document: uploadedServiceAgreement,
            user_id: servicerProfileDetails?.data?.user_id,
          },
          token: true,
          isFile: true,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      serviceProviderProfileAction.getServiceProviderProfessionalDetails({
        url: "service-provider/get-professional-details",
        data: {},
        token: true,
      })
    );
    dispatch(
      serviceProviderProfileAction.getSPBiometricData({
        url: "service-provider/get-biometric/face",
        data: {},
        token: true,
      })
    );
    dispatch(
      serviceProviderProfileAction.getSPSignatureData({
        url: "service-provider/get-biometric/signature",
        data: {},
        token: true,
      })
    );
    dispatch(
      userProfileAction.getReferralData({
        url: "generate-referral-link",
        data: {},
        token: true,
      })
    );

    getCardListing();
    if (
      parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
      isResetPasswordRequired !== 1
    ) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);

  // open stanup modal of any property remaining veifying
  useEffect(() => {
    if (StannupEmailDetails?.success && StannupEmailDetails?.data?.length > 0) {
      dispatch(userProfileAction.paymnetDoneDetails({}));
      if (localStorage.getItem("stanupPopupCount") === "1") {
        setOpenStanupPopup(true);
      }
    }
  }, [StannupEmailDetails, dispatch]);

  useEffect(() => {
    if (getObjLength(paymnetDoneDetails)) {
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
        dispatch(
          userProfileAction.getStannupEmailData({
            url: "service-provider/address",
            data: {},
            token: true,
          })
        );
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  // handle password Reset Required
  const passwordResetRequired = () => {
    localStorage.setItem(
      "isResetPasswordRequired",
      parseInt(isResetPasswordRequired) + 1
    );
    dispatch(serviceProviderProfileAction.clearResetPasswordRequired());
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  };

  useEffect(() => {
    // clear company logo state when company logo arrive in profile response
    if (servicerProfileDetails?.data?.company_logo) {
      setCroppedImage(null);
      setSelectedFile(null);
    }
  }, [servicerProfileDetails?.data?.company_logo]);

  useEffect(() => {
    // clear service agreement state when service agreement arrive in profile response
    if (servicerProfileDetails?.data?.service_agreement) {
      setUploadedServiceAgreement(null);
    }
  }, [servicerProfileDetails?.data?.service_agreement]);

  useEffect(() => {
    if (
      getObjLength(servicerProfileDetails) &&
      servicerProfileDetails?.success
    ) {
      if (servicerProfileDetails?.data?.status === "inactive") {
        localStorage.clear();
        localStorage.setItem("preference", true);
        window.location.href = `${SERVICE_PROVIDERS_URL}login`;
      }
    }
  }, [servicerProfileDetails]);

  // Function to handle scrolling to the form
  const scrollToAddressVerification = () => {
    addressVerificationRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Box className={`profile-wrapper ${isPadding && "apex-padding"}`}>
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <Typography variant="h6" className="title">
              My Profile
            </Typography>
            <Button
              variant="contained"
              className="signoutBtn d-none d-md-block"
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Paper elevation={0} className="personal-info" sx={Paperstyles}>
                <Box
                  className="d-flex align-items-center justify-content-between"
                  sx={{ mb: 3 }}
                >
                  <Typography variant="h6" className="card-title" my={1.6}>
                    Personal information
                  </Typography>
                  {!edit && (
                    <>
                      <Button
                        variant="outlined"
                        className="d-none d-md-block EditBtn"
                        sx={styles}
                        onClick={() => setEdit(!edit)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        className="d-block d-md-none EditBtn p-2"
                        sx={styles}
                        onClick={() => setEdit(!edit)}
                      >
                        <ModeEditOutlineOutlinedIcon fontSize="small" />
                      </Button>
                    </>
                  )}
                </Box>
                <Box className="d-flex align-items-center">
                  <Box className="profile-photo-box">
                    <img
                      src={
                        servicerProfileDetails?.data?.profile_img
                          ? servicerProfileDetails?.data?.profile_img
                          : ProfileDP
                      }
                      alt="Profile"
                      className="profilePic"
                    />
                    <IconButton
                      className="profile-photo-edit-btn"
                      onClick={handleEditProfilePicBtn}
                    >
                      <ModeEditOutlineOutlinedIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </Box>
                <Box className="form" sx={{ mt: 5 }}>
                  {/* {servicerProfileDetails?.success && ( */}
                  <>
                    <ServicerProfileForm
                      servicerProfileDetails={servicerProfileDetails}
                      edit={edit}
                      setEdit={setEdit}
                      formRef={formRef}
                    />
                  </>
                  {/* )} */}
                </Box>
              </Paper>
              <Paper elevation={0} className="personal-info" sx={Paperstyles}>
                <Box
                  className="d-flex align-items-center justify-content-between"
                  sx={{ mb: 3 }}
                >
                  <Typography variant="h6" className="card-title" my={1.6}>
                    Professional information
                  </Typography>
                  {!editProfessional && (
                    <>
                      <Button
                        variant="outlined"
                        className="d-none d-md-block EditBtn"
                        sx={styles}
                        onClick={() => setEditProfessional(!editProfessional)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        className="d-block d-md-none EditBtn p-2"
                        sx={styles}
                        onClick={() => setEditProfessional(!editProfessional)}
                      >
                        <ModeEditOutlineOutlinedIcon fontSize="small" />
                      </Button>
                    </>
                  )}
                </Box>
                <Box className="form" sx={{ mt: 5 }}>
                  {/* {servicerProfileDetails?.success && ( */}
                  <>
                    <ServicerProfessionalForm
                      servicerProfessionalDetails={servicerProfessionalDetails}
                      edit={editProfessional}
                      setEdit={setEditProfessional}
                    />
                  </>
                  {/* )} */}
                </Box>
              </Paper>

              {/* payment information */}
              <CardCRUD isSecondaryUser={false} isServiceProvider={true} />

              {/* Address Verification module */}
              {Object.keys(StannupEmailDetails).length > 0 &&
                (StannupEmailDetails?.data).length > 0 && (
                  <Paper
                    elevation={0}
                    className="payment-info"
                    sx={Paperstyles}
                    ref={addressVerificationRef}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="h6" className="card-title">
                        Address Verification
                      </Typography>
                    </Box>
                    <Box>
                      <PropertiesListing
                        columns={AddressVerificationColumns}
                        rowsData={StannupEmailDetails?.data}
                      />
                    </Box>
                  </Paper>
                )}
            </Grid>
            <Grid
              className="pt-1 pt-lg-3"
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Paper elevation={0} className="help-contact" sx={Paperstyles}>
                <Box
                  className="d-flex align-items-center justify-content-between"
                  sx={{ mb: 3 }}
                >
                  <Typography variant="h6" className="card-title">
                    Help and contact
                  </Typography>
                </Box>
                <List
                  className="supportContact"
                  sx={{ width: "100%", bgcolor: "background.paper" }}
                >
                  <ListItem disablePadding className="align-items-start">
                    <ListItemText className="mb-0 label">Support:</ListItemText>
                    <ListItemText className="mb-0 content">
                      <SupportEmailLink
                        email={SUPPORT_EMAIL}
                        text={"Click here to contact us"}
                      />
                    </ListItemText>
                  </ListItem>
                  <ListItem disablePadding className="align-items-start">
                    <ListItemText className="mb-0 label">Email:</ListItemText>
                    <ListItemText className="mb-0 content">
                      <SupportEmailLink
                        email={SUPPORT_EMAIL}
                        text={SUPPORT_EMAIL}
                      />
                    </ListItemText>
                  </ListItem>
                  <ListItem disablePadding className="align-items-start">
                    <ListItemText className="mb-0 label">
                      By phone:
                    </ListItemText>
                    <ListItemText className="mb-0 content">
                      <div className="d-flex flex-column">
                        <Callto phone={`${SUPPORT_PHONE}`}>
                          {SUPPORT_PHONE_FORMAT}
                        </Callto>
                        <p className="mb-0">
                          MON-FRI: <span>8:00AM - 6:00PM</span>
                        </p>
                        <p className="mb-0">
                          SAT-SUN: <span>9:00AM - 5:00PM</span>
                        </p>
                      </div>
                    </ListItemText>
                  </ListItem>
                </List>
              </Paper>
              <>
                {/* signature and biomatric */}
                <BiometricAndSign Paperstyles={Paperstyles} />
              </>

              {/* service agreement section */}
              <Paper elevation={0} className="help-contact" sx={Paperstyles}>
                <Box
                  className="d-flex align-items-center justify-content-between position-relative"
                  sx={{ mb: 3 }}
                >
                  <Typography variant="h6" className="card-title">
                    Service Agreement
                  </Typography>
                </Box>

                <Typography variant="body1">
                  {servicerProfileDetails?.data?.service_agreement ? (
                    <CustomLinkWrapper
                      link={servicerProfileDetails?.data?.service_agreement}
                      title={
                        <>
                          {
                            servicerProfileDetails?.data?.service_agreement?.split(
                              "/"
                            )?.[
                              servicerProfileDetails?.data?.service_agreement?.split(
                                "/"
                              )?.length - 1
                            ]
                          }
                          {/* <TextTruncate
                            text={
                              servicerProfileDetails?.data?.service_agreement?.split(
                                "/"
                              )?.[
                                servicerProfileDetails?.data?.service_agreement?.split(
                                  "/"
                                )?.length - 1
                              ]
                            }
                            maxLength={20}
                          /> */}
                        </>
                      }
                      classes="service-agreement"
                    />
                  ) : (
                    <Stack spacing={1.5}>
                      <span
                        className={`${
                          uploadedServiceAgreement
                            ? "d-flex justify-content-center align-items-center flex-column rounded py-4"
                            : ""
                        }`}
                        style={{
                          backgroundColor: "#F6F9FC",
                          height: "100%",
                          border: uploadedServiceAgreement
                            ? "1px solid #E2E8EB"
                            : "",
                          gap: uploadedServiceAgreement ? "10px" : "",
                        }}
                      >
                        {/* This condition will be true if servicer selects any file which type is either .pdf or .doc/.docx */}
                        {uploadedServiceAgreement ? (
                          <>
                            {/* This is displayed for the file type pdf */}
                            {uploadedServiceAgreement?.type ===
                            "application/pdf" ? (
                              <>
                                <span
                                  className="position-relative p-2 bg-white rounded"
                                  sx={{ backgroundColor: "#F6F9FC" }}
                                  onClick={() => {
                                    window.open(
                                      createFileUrl(uploadedServiceAgreement),
                                      "_blank"
                                    );
                                  }}
                                >
                                  <img
                                    src={pdfPlaceholder}
                                    alt="Document Preview"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                  <span
                                    style={closeDeleteIconStyle}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setUploadedServiceAgreement("");
                                    }}
                                  >
                                    <CloseOutlinedIcon />
                                  </span>
                                </span>
                                <Typography className="mw-100 m-0">
                                  <CustomLinkWrapper
                                    link={
                                      uploadedServiceAgreement
                                        ? createFileUrl(
                                            uploadedServiceAgreement
                                          )
                                        : "#"
                                    }
                                    title={uploadedServiceAgreement?.path}
                                    key={uploadedServiceAgreement?.path}
                                    classes="preview-url"
                                  />
                                </Typography>
                              </>
                            ) : uploadedServiceAgreement?.type ===
                                "application/msword" ||
                              uploadedServiceAgreement?.type ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                              <>
                                {/* This will display for the file type document */}
                                <span
                                  className="position-relative p-2 bg-white rounded"
                                  sx={{ backgroundColor: "#F6F9FC" }}
                                  onClick={() => {
                                    window.open(
                                      createFileUrl(uploadedServiceAgreement),
                                      "_blank"
                                    );
                                  }}
                                >
                                  <img
                                    src={wordDocPlaceholder}
                                    alt="Document Preview"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                  <span
                                    style={closeDeleteIconStyle}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setUploadedServiceAgreement("");
                                    }}
                                  >
                                    <CloseOutlinedIcon />
                                  </span>
                                </span>
                                <Typography className="mw-100 m-0">
                                  <CustomLinkWrapper
                                    link={
                                      uploadedServiceAgreement
                                        ? createFileUrl(
                                            uploadedServiceAgreement
                                          )
                                        : "#"
                                    }
                                    title={uploadedServiceAgreement?.path}
                                    key={uploadedServiceAgreement?.path}
                                    classes="preview-url"
                                  />
                                </Typography>
                              </>
                            ) : (
                              <>
                                {/* It will show the selected file if servicer selects the file which is neither in the pdf nor in the doc category */}
                                <Box
                                  className="preview-file bg-white"
                                  padding="3px 15px"
                                  mb={0.9}
                                >
                                  <CustomLinkWrapper
                                    link={
                                      uploadedServiceAgreement
                                        ? createFileUrl(
                                            uploadedServiceAgreement
                                          )
                                        : "#"
                                    }
                                    title={uploadedServiceAgreement?.path}
                                    key={uploadedServiceAgreement?.path}
                                    classes="preview-url"
                                  />
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setUploadedServiceAgreement("");
                                    }}
                                  >
                                    <CloseOutlinedIcon />
                                  </span>
                                </Box>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {/* when there is no file selected then it will display the file upload field */}
                            <span className="servicer-signup-form">
                              <FileUpload
                                name="upload_service_agreement"
                                accept={{
                                  "application/pdf": [".pdf"],
                                  "application/msword": [".doc"],
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                    [".docx"],
                                }}
                                multiple={false}
                                onFileUpload={async (file) => {
                                  // onChange(file);
                                  if (file?.length > 0) {
                                    setUploadedServiceAgreement(file[0]);
                                    setErrorMessage("");
                                  }
                                }}
                                dragDropText={`Drag and drop or `}
                                greenText="Upload a Service Agreement"
                                subText="Only .pdf, .doc, .docx files are allowed"
                              />
                            </span>
                            <FormHelperText>
                              {errorMessage?.length > 0 && (
                                <Typography
                                  className="text-danger"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  {errorMessage}
                                </Typography>
                              )}
                            </FormHelperText>
                          </>
                        )}
                      </span>
                      {uploadedServiceAgreement && (
                        <Box className="w-100 d-flex justify-content-end">
                          <Button
                            className="popup_Btn success_popup_btn"
                            onClick={handleUploadingServiceAgreement}
                          >
                            Upload
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  )}
                </Typography>
              </Paper>
              {/* Company logo section */}
              <Paper elevation={0} className="help-contact" sx={Paperstyles}>
                <Box
                  className="d-flex align-items-center justify-content-between"
                  sx={{ mb: 3 }}
                >
                  <Typography variant="h6" className="card-title">
                    Company Logo
                  </Typography>
                </Box>
                {/* show company logo if its present */}
                {servicerProfileDetails?.data?.company_logo && (
                  <Box
                    className="position-relative p-4 rounded d-flex justify-content-center align-items-center"
                    sx={{
                      backgroundColor: "#F6F9FC",
                      border: "1px solid #E2E8EB",
                    }}
                  >
                    <img
                      src={servicerProfileDetails?.data?.company_logo}
                      style={{
                        minHeight: "150px",
                        maxHeight: "230px",
                      }}
                      alt="cropped"
                      id="croppedImage"
                      className="rounded"
                    />

                    <IconButton
                      sx={{
                        ...closeDeleteIconStyle,
                        color: "#FC2125",
                        fontSize: "1.4rem",
                      }}
                      onClick={() => {
                        // call the delete company logo API
                        dispatch(
                          serviceProviderProfileAction.deleteCompanyLogo({
                            url: "service-provider/delete-logo",
                            data: {},
                            token: true,
                          })
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}

                {!servicerProfileDetails?.data?.company_logo ? (
                  !croppedImage ? (
                    selectedFile ? (
                      <>
                        {/* When service provider selects the image then this cropper will be displayed */}
                        <Cropper
                          ref={cropperRef}
                          style={{ height: "280px", width: "100%" }}
                          zoomTo={0}
                          initialAspectRatio={1}
                          src={selectedFile}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          background={false}
                          responsive={true}
                          autoCropArea={1}
                          checkOrientation={false}
                          guides={false}
                        />
                        <Box className="d-flex justify-content-start" mt={2}>
                          <Button
                            onClick={getCropData}
                            variant="contained"
                            className="success-btn"
                            // color="primary"
                          >
                            Crop Logo
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        {/* Initially there is neither cropped image nor selected image so upload image field will show */}
                        <span className="servicer-signup-form">
                          <FileUpload
                            name="upload_doc"
                            accept={{
                              "image/png": [".png", ".jpeg", ".jpg"],
                            }}
                            multiple={false}
                            onFileUpload={async (file) => {
                              onChange(file);
                            }}
                            dragDropText={`Drag and drop or `}
                            greenText="Upload a Logo"
                            subText="Only .png, .jpeg, .jpg files are allowed"
                          />
                        </span>
                      </>
                    )
                  ) : (
                    <Stack spacing={1.5}>
                      {/* After successfully cropping it will display the cropped image and delete icon for clearing the image */}
                      <Box
                        className="position-relative p-4 rounded d-flex justify-content-center align-items-center"
                        sx={{
                          backgroundColor: "#F6F9FC",
                          border: croppedImage ? "1px solid #E2E8EB" : "",
                        }}
                      >
                        <img
                          src={croppedImage}
                          style={{
                            minHeight: "150px",
                            maxHeight: "230px",
                          }}
                          alt="cropped"
                          id="croppedImage"
                          className="rounded"
                        />

                        <IconButton
                          sx={{
                            ...closeDeleteIconStyle,
                            color: "#FC2125",
                            fontSize: "1.4rem",
                          }}
                          onClick={() => {
                            setCroppedImage(null);
                            setSelectedFile(null);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Box className="d-flex justify-content-end">
                        <Button
                          className="popup_Btn success_popup_btn"
                          onClick={handleUploadingCompanyLogo}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Stack>
                  )
                ) : null}
              </Paper>

              {/* referal link */}
              <ReferralLink Paperstyles={Paperstyles} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              className="signoutBtn d-block d-md-none w-100"
              // onClick={handleSignOut}
            >
              Sign Out
            </Button>
          </Box>
        </>
      </Box>

      {/* upload profile picture modal */}
      {isEditProfilePictureModalOpen ? (
        <UploadProfilePictureModal
          open={isEditProfilePictureModalOpen}
          handleClose={handleEditProfilePicModalClose}
          handleUploadProfilePicture={handleUploadProfilePicture}
          loader={editProfileLoader}
        />
      ) : null}

      {/* modal for edit profile success or error response */}
      {getObjLength(editedServicerProfileDetails) ? (
        editedServicerProfileDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(
                serviceProviderProfileAction.setEditServicerProfileData({})
              );
              handleEditProfilePicModalClose();
              dispatch(
                serviceProviderProfileAction.getServiceProviderProfile({
                  url: "service-provider/profile",
                  data: {},
                  token: true,
                })
              );
            }}
            mesage={editedServicerProfileDetails?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() =>
              dispatch(
                serviceProviderProfileAction.setEditServicerProfileData({})
              )
            }
            mesage={editedServicerProfileDetails?.data?.message}
          />
        )
      ) : null}

      {/* modal for edit profile success or error response */}
      {getObjLength(editedServicerProfessionalDetails) ? (
        editedServicerProfessionalDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(
                serviceProviderProfileAction.setEditServicerProfessionalData({})
              );
              setEditProfessional(false);
              dispatch(
                serviceProviderProfileAction.getServiceProviderProfessionalDetails(
                  {
                    url: "service-provider/get-professional-details",
                    data: {},
                    token: true,
                  }
                )
              );
            }}
            mesage={editedServicerProfessionalDetails?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() =>
              dispatch(
                serviceProviderProfileAction.setEditServicerProfessionalData({})
              )
            }
            mesage={editedServicerProfessionalDetails?.data?.message}
          />
        )
      ) : null}

      {/* stannup info modal */}
      {openStanupPopup ? (
        <StannupInfoModal
          scrollToAddressVerification={scrollToAddressVerification}
          openStanupPopup={openStanupPopup}
          setOpenStanupPopup={setOpenStanupPopup}
        />
      ) : null}

      {/* Paymnet failed Popup */}
      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
      isPaymentDonePopup ? (
        <PaymentFailed isPaymentDonePopup={isPaymentDonePopup} />
      ) : null}

      {/* Reset password popup  */}
      {isResetPasswordRequired === 1 ? (
        <AlertDialog
          type="Attention"
          openPopup={isResetPasswordRequired}
          onClose={true}
          onCloseFun={passwordResetRequired}
          mesage={
            <p className="fw-400 mt-3">
              Please change auto generated password for the better security of
              your account.
            </p>
          }
          buttons={
            <Button
              className="popup_Btn success_popup_btn"
              onClick={passwordResetRequired}
            >
              Ok, All Good
            </Button>
          }
        />
      ) : null}

      {/* modal for upload company logo success or error response */}
      {getObjLength(uploadedLogoDetails) ? (
        <AlertDialog
          type={uploadedLogoDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(
              serviceProviderProfileAction.setUploadedCompanyLogoDetails({})
            );

            if (uploadedLogoDetails?.success) {
              // setCroppedImage(null);
              // setSelectedFile(null);
              dispatch(
                serviceProviderProfileAction.getServiceProviderProfile({
                  url: "service-provider/profile",
                  data: {},
                  token: true,
                })
              );
            }
          }}
          mesage={
            uploadedLogoDetails?.success
              ? uploadedLogoDetails?.message
              : uploadedLogoDetails?.data?.message
          }
        />
      ) : null}

      {getObjLength(deletedLogoDetails) ? (
        <AlertDialog
          type={deletedLogoDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(
              serviceProviderProfileAction.setDeletedCompanyLogoDetails({})
            );
            if (deletedLogoDetails?.success) {
              dispatch(
                serviceProviderProfileAction.getServiceProviderProfile({
                  url: "service-provider/profile",
                  data: {},
                  token: true,
                })
              );
            }
          }}
          mesage={
            deletedLogoDetails?.success
              ? deletedLogoDetails?.message
              : deletedLogoDetails?.data?.message || deletedLogoDetails?.error
          }
          footer={false}
        />
      ) : null}

      {getObjLength(uploadedServiceAgreementDetails) ? (
        <AlertDialog
          type={uploadedServiceAgreementDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(
              serviceProviderProfileAction.setUploadedServiceAgreementDetails(
                {}
              )
            );
            if (uploadedServiceAgreementDetails?.success) {
              dispatch(
                serviceProviderProfileAction.getServiceProviderProfile({
                  url: "service-provider/profile",
                  data: {},
                  token: true,
                })
              );
            }
          }}
          mesage={
            uploadedServiceAgreementDetails?.success
              ? uploadedServiceAgreementDetails?.message
              : uploadedServiceAgreementDetails?.data?.message ||
                uploadedServiceAgreementDetails?.error
          }
          footer={false}
        />
      ) : null}

      {/* loaders */}
      <Loader
        open={
          editProfileLoader ||
          postcardLoader ||
          professionalLoader ||
          editProfessionalLoader ||
          spSignupLoader ||
          profileLoader ||
          uploadCompanyLogoLoader ||
          deleteCompanyLogoLoader ||
          uploadServiceAgreementLoader ||
          addressLoader ||
          biometric_signLoader
        }
      />
    </>
  );
};

export default ServicerProfile;
