import React from "react";

import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

import { Box, Typography } from "@mui/material";

import { COUNTRY_CODE } from "../helpers/constants";

const CustomPhoneNumberInput = ({
  values,
  touched,
  errors,
  setFieldValue,
  name,
  disabled = false,
  edit = true,
  otherProps,
  classes,
  placeholder = "Phone Number",
}) => {
  return (
    <>
      <Box
        className={`phone-number-input ${
          ((values?.[name] && !isValidPhoneNumber(values?.[name])) ||
            (touched?.[name] && errors?.[name]?.length > 0)) &&
          edit
            ? "phone-error"
            : ""
        } ${classes}`}
      >
        <PhoneInput
          international={false}
          value={values?.[name]}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          countries={["US", "IN"]}
          defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
          countryOptionsOrder={["US", "IN"]}
          addInternationalOption={false}
          onChange={(event) => {
            setFieldValue(name, event);
            if (!event) {
              setFieldValue(name, "");
            }
          }}
          className={`${disabled ? "show-disabled-text" : ""}`}
          {...otherProps}
        />
      </Box>
      {((values?.[name] && !isValidPhoneNumber(values?.[name])) ||
        (touched?.[name] && errors?.[name]?.length > 0)) &&
        edit && (
          <Typography
            sx={{
              color: "#d32f2f !important",
              fontSize: "12px !important",
              margin: "0px 0px 0px 0px !important",
              maxWidth: "100% !important",
              textAlign: "left",
            }}
          >
            Please enter a valid phone number
          </Typography>
        )}
    </>
  );
};

export default CustomPhoneNumberInput;
