import React from "react";

import file from "../../assets/icons/file.svg";
import lock from "../../assets/icons/lock.svg";
import pulse from "../../assets/icons/pulse.svg";
import shield from "../../assets/icons/shield.svg";
import layers from "../../assets/icons/layers.svg";
import scanner from "../../assets/icons/scanner.svg";
import ep_logo from "../../assets/icons/EP_LOGO.svg";
import fingerprint from "../../assets/icons/fingerprint.svg";
import "./Style.scss";

const EpOrbitTransitionSection = () => {
  return (
    <div className="Ep_orbit mt-2">
      <ul className="orbit-wrap">
        <li className="orbit-center">
          <img
            src={ep_logo}
            alt="ep"
            className="orbit-center__icon fa fa-code"
          />
        </li>
        <li>
          <ul className="ring-0">
            <li>
              <div className="orbit-icon">
                <img src={layers} alt="asdf" className="fingerprint" />
              </div>
            </li>
            <li>
              <div className="orbit-icon">
                <img src={fingerprint} alt="asdf" className="fingerprint" />
              </div>
            </li>
          </ul>
        </li>
        <li>
          <ul className="ring-1">
            <li>
              <div className="orbit-icon">
                <img src={file} alt="asdf" className="fingerprint" />
              </div>
            </li>
            <li>
              <div className="orbit-icon">
                <img src={pulse} alt="asdf" className="fingerprint" />
              </div>
            </li>
            <li>
              <div className="orbit-icon">
                <img src={scanner} alt="asdf" className="fingerprint" />
              </div>
            </li>
          </ul>
        </li>
        <li>
          <ul className="ring-2">
            <li>
              <div className="orbit-icon">
                <img src={shield} alt="asdf" className="fingerprint" />
              </div>
            </li>
          </ul>
        </li>
        <li>
          <ul className="ring-3">
            <li>
              <div className="orbit-icon">
                <img src={lock} alt="asdf" className="fingerprint" />
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default EpOrbitTransitionSection;
