import React from "react";
import { Navigate } from "react-router-dom";

import { decryptPayload } from "../components/helpers/utils";

const RoleProtectedRoute = ({ children, accessEntity, isIOS = null }) => {
  return (
    <>
      {accessEntity?.includes(decryptPayload(localStorage.getItem("roles"))) ? (
        children
      ) : (
        <Navigate to={"/404"} state={{ removeToken: true }} />
      )}
    </>
  );
};

export default RoleProtectedRoute;
