import React, { useRef } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Box, Button, Divider, Stack } from "@mui/material";

import nbc from "../../assets/leadpage/nbc.WebP";
import fox from "../../assets/leadpage/fox.WebP";
import cbs from "../../assets/leadpage/cbs.WebP";
import cnn from "../../assets/leadpage/cnn.WebP";
import EvaluateRisk from "../common/EvaluateRisk";
import forbes from "../../assets/leadpage/forbes.WebP";
import tv_icon from "../../assets/leadpage/tv-icon.svg";
import check_img from "../../assets/icons/checkmark.png";
import usa_today from "../../assets/leadpage/usa_today.WebP";
import msnbc_logo from "../../assets/leadpage/msnbc-logo.png";
import PrivateGuardSection from "../common/PrivateGuardSection";
import PropertyRiskManagementForm from "./PropertyRiskManagementForm";

import "./LeadGenDesktop.scss";
import "../../styles/landingpage.scss";
import "../../styles/howItWorksSection.scss";

const LeadGenShortVersion = () => {
  const riskFormRef = useRef(null);

  // Function to handle scrolling to the form
  const scrollToForm = () => {
    riskFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="lead-gen-content bg-white main_class">
        <section className="first_section light_bg">
          <div
            className="d-flex align-items-center prm_bg_img  first-section"
            ref={riskFormRef}
          >
            <Container fluid className="text-white mx-150">
              <Row className="align-items-center" spacing={3}>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Stack direction={"column"} spacing={2}>
                    <Box className="top_section_spacing">
                      <p
                        className="notify_header mb-2"
                        style={{ color: "#0395FF" }}
                      >
                        Check your property
                      </p>
                      <h1 className="fw-700 mb-3 main-text">
                        Get your Free Property Risk Assessment instantly, no
                        waiting required!
                      </h1>
                      <p className="paragraph_text fw-400 mb-3 mt-2">
                        We are the only service that prevents equity theft and
                        fraud before it happens. With over 1,100 data points
                        meticulously analyzed, Equity Protect's Risk Assessment
                        Report delivers unparalleled accuracy in evaluating the
                        risk of equity theft and fraud.
                      </p>
                    </Box>
                  </Stack>
                  <div className="my-3 py-1 d-flex align-items-center as_seen_on ">
                    <span className="pe-2 pt-1">As Seen on</span>
                    <img src={tv_icon} alt="Tv_icon" />
                  </div>
                  <div className="pb-3">
                    <Stack
                      className="homepage_logos d-flex  logo_columned"
                      spacing={2}
                    >
                      <Stack
                        spacing={{ xs: 2, sm: 3 }}
                        direction="row"
                        className="alignments"
                      >
                        <LazyLoadImage src={nbc} alt="nbc" />
                        <LazyLoadImage src={forbes} alt="forbes" />
                        <LazyLoadImage src={fox} alt="fox" />
                      </Stack>
                      <Stack
                        spacing={{ xs: 1, sm: 3 }}
                        direction="row"
                        className="alignments"
                      >
                        <LazyLoadImage src={cbs} alt="cbs" />
                        <LazyLoadImage src={msnbc_logo} alt="msnbc_logo" />
                        <LazyLoadImage src={usa_today} alt="usa_today" />
                        <LazyLoadImage src={cnn} alt="cnn" />
                      </Stack>
                    </Stack>
                    <Box
                      className="homepage_logos d-flex logo_row flex-wrap"
                      gap={"16px"}
                    >
                      <LazyLoadImage src={nbc} alt="nbc" />
                      <LazyLoadImage src={forbes} alt="forbes" />
                      <LazyLoadImage src={fox} alt="fox" />
                      <LazyLoadImage src={cbs} alt="cbs" />
                      <LazyLoadImage src={msnbc_logo} alt="msnbc_logo" />
                      <LazyLoadImage src={usa_today} alt="usa_today" />
                      <LazyLoadImage src={cnn} alt="cnn" />
                    </Box>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    className="property_form property-risk-page-content lead_form"
                    id="lead-page-form"
                  >
                    <PropertyRiskManagementForm
                      getFreeReport={true}
                      leadGen={true}
                      widthClass={"lead-prm-form"}
                      source={"Lead-Generation"}
                    />
                  </Box>
                </Col>
              </Row>
              <div className="pt-5 d-flex justify-content-center align-items-center lg_short_second_section">
                <Stack
                  direction={{ sm: "column", md: "row" }}
                  justifyContent={{ sm: "center", md: "space-around" }}
                  alignItems={{ sm: "center", xs: "center" }}
                  spacing={2}
                  className="sec1"
                >
                  <>
                    <Stack direction={"row"}>
                      <img
                        src={check_img}
                        className="check_img ml-0"
                        alt="check"
                      />
                      <span className="sec1_text">
                        Your data is completely secure
                      </span>
                    </Stack>
                  </>
                  <>
                    <Stack direction={"row"}>
                      <img
                        src={check_img}
                        className="check_img ml-0"
                        alt="check"
                      />
                      <span className="sec1_text">
                        We won’t save anything locally
                      </span>
                    </Stack>
                  </>
                </Stack>
              </div>
            </Container>
          </div>
        </section>

        <section className="pt-3 d-flex justify-content-center align-items-center bg-white second_section lg_short_third_section">
          <Stack
            direction={{ sm: "column", md: "row" }}
            justifyContent={{ sm: "center", md: "space-around" }}
            alignItems={{ sm: "center", xs: "center" }}
            spacing={2}
            className="sec1"
          >
            <>
              <Stack direction={"row"}>
                <img src={check_img} className="check_img ml-0" alt="check" />
                <span className="sec1_text">
                  Your data is completely secure
                </span>
              </Stack>
            </>
            <>
              <Stack direction={"row"} className="mt-0">
                <img src={check_img} className="check_img img_2" alt="check" />
                <span className="sec1_text">
                  We won’t save anything locally
                </span>
              </Stack>
            </>
          </Stack>
        </section>

        <section className="bg-white forth_section pt-5">
          <Container fluid>
            <Row className="">
              <Col
                className="promo_code_outer_section"
                xs={11}
                sm={10}
                md={8}
                lg={6}
              >
                <Box className="promo_code_inner_section">
                  <Box className="box_border">
                    <h1 className="fw-700 mb-3 main-text">
                      Get 50% off your first year
                    </h1>
                    <p className="paragraph_text fw-400 mb-3 mt-2">
                      Use the code “<b>Protect50</b>” at checkout.
                    </p>
                  </Box>
                </Box>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-5 bg-white">
          <EvaluateRisk />
        </section>
        <Divider className="divider" />

        <section className="py-5 text-center bg-white">
          <Container fluid className="my-3">
            <div className="free_report-spacing">
              <h5 className="safeGuard">
                Let us help you safeguard your equity and prevent costly fraud.
              </h5>
              <Button
                className={`button success_popup_btn my-4`}
                variant="contained"
                onClick={scrollToForm}
              >
                Get your free report
              </Button>
            </div>
          </Container>
        </section>
        <PrivateGuardSection displayLastSection={false} />
      </div>
    </>
  );
};

export default LeadGenShortVersion;
