import { createSlice } from "@reduxjs/toolkit";

const intialBrokerState = {
  brokerDetails: {},
  agentCreateAccDetails: {},
  loggedInAgentDetails: {},
  agentProfileListData: {},
  agentPropertyListData: {},
  saveFreeMonitortingData: {},
  addNewAgentPropertyData: {},
  agentProfileData: {},
  updateAgentProfileData: {},
  profileFormData: {},
  agentProfilePayload: {},
  agentProfileLoader: false,
  agentPropertyLoader: false,
  brokerAgentLoader: false,
};

const brokerAgents = createSlice({
  name: "brokerAgentDetails",
  initialState: intialBrokerState,
  reducers: {
    resetBrokerAgentsState: () => intialBrokerState,
    getBrokerDetails(state, action) {
      state.brokerAgentLoader = true;
    },
    fetchBrokerData(state, action) {
      state.brokerDetails = action.payload;
      state.brokerAgentLoader = false;
    },
    createAgentAccount(state, action) {
      state.brokerAgentLoader = true;
    },
    fetchAgentCreateAccData(state, action) {
      state.agentCreateAccDetails = action.payload;
      state.brokerAgentLoader = false;
    },
    getLoginAgentData(state, action) {
      state.brokerAgentLoader = true;
    },
    fetchLoggedInAgentData(state, action) {
      state.loggedInAgentDetails = action.payload;
      state.brokerAgentLoader = false;
    },
    getAgentProfileListData(state, action) {
      state.agentProfileLoader = true;
      state.agentProfilePayload = action.payload?.data;
    },
    clearAgentProfilePayload(state, action) {
      state.agentProfilePayload = {};
    },
    fetchAgentProfileListData(state, action) {
      state.agentProfileListData = action.payload;
      state.agentProfileLoader = false;
    },
    getAgentPropertyListData(state, action) {
      state.agentPropertyLoader = true;
    },
    fetchAgentPropertyListData(state, action) {
      state.agentPropertyListData = action.payload;
      state.agentPropertyLoader = false;
    },
    getSaveFreeMonitortingData(state, action) {
      state.brokerAgentLoader = true;
    },
    fetchSaveFreeMonitortingData(state, action) {
      state.saveFreeMonitortingData = action.payload;
      state.brokerAgentLoader = false;
    },
    getStartMarketringMailData(state, action) {
      state.brokerAgentLoader = true;
    },
    fetchStartMarketringMailData(state, action) {
      state.startMarketringMailData = action.payload;
      state.brokerAgentLoader = false;
    },
    getAddNewAgentPropertyData(state, action) {
      state.brokerAgentLoader = true;
    },
    fetchAddNewAgentPropertyData(state, action) {
      state.addNewAgentPropertyData = action.payload;
      state.brokerAgentLoader = false;
    },
    getAgentProfileData(state, action) {
      state.brokerAgentLoader = true;
    },
    fetchAgentProfileData(state, action) {
      state.agentProfileData = action.payload;
      state.brokerAgentLoader = false;
    },
    getAgentProfileFormData(state, action) {
      console.log("values -->", action.payload);

      state.profileFormData = action.payload;
    },
    getUpdateAgentProfileData(state, action) {
      state.brokerAgentLoader = true;
    },
    fetchUpdateAgentProfileData(state, action) {
      state.updateAgentProfileData = action.payload;
      state.brokerAgentLoader = false;
    },
  },
});
export const brokerAgentsAction = brokerAgents.actions;
export default brokerAgents.reducer;
