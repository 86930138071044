import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import OwnerVerified from "../../user-signup/ownerVerified";
import CardList from "../../userProfile/OrderDocuments/CradList";
import CardPayment from "../../userProfile/add-property/CardPayment";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import InvestorsAnnaulSubscriptionPlan from "../../common/investorsAnnaulSubscriptionPlan";

const AddRemainingInvestorProperties = ({
  isPropertyModal = false,
  closeModal = () => {},
  closePaymentModal = () => {},
  setScreen = () => {},
  screen = "ownerScreen",
  selectedData = {},
  selectedProperties = {},
  token = undefined,
  selectedPropertyIds = [],
  user_id = undefined,
}) => {
  const dispatch = useDispatch();
  const propertiesList = useSelector(
    (state) => state.signUpUser.verifiedPropertiesList
  );
  const [planData, setPlanData] = useState({});
  console.log("selectedPropertyIds==>", selectedPropertyIds);
  const goToSubscriptions = () => {
    setScreen("subscription");
  };
  const goToCards = (data) => {
    console.log("enable cards...", data);
    setPlanData(data?.plan_id);
    fecthCardListAPI();
    setScreen("cards");
  };
  const fecthCardListAPI = () => {
    dispatch(
      userProfileAction.userCardDetails({
        url: "get-cards",
        data: { token },
        token: true,
        type: "post",
      })
    );
  };
  return (
    <>
      <Dialog
        open={isPropertyModal}
        fullWidth={true}
        maxWidth="lg"
        onClose={closeModal}
        className="add-propery-modal zoom-modal-wrapper"
      >
        <DialogContent
          className="bg-white"
          dividers={true}
          sx={{ px: "2 !important", position: "relative" }}
        >
          {screen === "ownerScreen" && (
            <>
              <Typography
                align="center"
                variant="subtitle1"
                className="py-1 formHeader zoom-container"
                color={"white"}
                sx={{ position: "relative" }}
              >
                <LockIcon sx={{ fontSize: 12 }} className="me-1" />
                Secure connection
              </Typography>
              <Button
                onClick={closeModal}
                className="modal-close-btn close-text hide-inner-close"
                style={{
                  position: "absolute !important",
                  top: 14,
                  right: 76,
                  background: "transparent",
                  textTransform: "none",
                }}
              >
                <CloseIcon
                  className="hide-inner-close"
                  sx={{ fontSize: 20, color: "white" }}
                />
              </Button>
            </>
          )}
          <Button
            onClick={closeModal}
            className="modal-close-btn close-text hide-outer-close"
            style={{
              position: "absolute",
              right: 5,
              top: 5,
              background: "transparent",
              textTransform: "none",
            }}
          >
            Close
            <CloseIcon
              className="hide-outer-close"
              sx={{ fontSize: "2.5rem", color: "#15be53" }}
            />
          </Button>

          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
            className={`zoom-container`}
            //sx={isPadding && { paddingBottom: "110px" }}
          >
            <Box className="my-2 ">
              {screen === "ownerScreen" ? (
                <OwnerVerified
                  user_id={user_id}
                  propertiesList={propertiesList}
                  isInvestor={true}
                  goToSubscriptions={goToSubscriptions}
                  closeBtnFunction={closeModal}
                />
              ) : screen === "subscription" ? (
                <InvestorsAnnaulSubscriptionPlan
                  uid={user_id}
                  paySecure={goToCards}
                  propertiesIds={selectedPropertyIds}
                  selectedData={selectedData}
                  // promoCode={promoCode}
                  isBack_btn={true}
                  backBtnFunction={() => setScreen("ownerScreen")}
                />
              ) : screen === "cards" ? (
                <CardPayment
                  handleCloseAddPropertyModal={closeModal}
                  selectedData={selectedData}
                  planId={planData}
                  onChangeModalScreen={setScreen}
                  onCloseModalHandler={closePaymentModal}
                  propertiesIds={selectedPropertyIds}
                  callListingAPI={fecthCardListAPI}
                  addPromoCode={true}
                  uid={user_id}
                />
              ) : (
                "Not owned"
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddRemainingInvestorProperties;
