import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { getObjLength, windowsAndroidNativeMethods } from "../helpers/utils";
import { useSelector } from "react-redux";

const BiometricForNativeApp = ({ onBtnClick, isIOS }) => {
  const accCreated = useSelector((state) => state.signUpUser.createAccResponse);
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));

  useEffect(() => {
    console.log("Native App got called...");
    const bioMetricData = {
      user_id: getObjLength(accCreated)
        ? accCreated.data?.user_id
        : userIdFromSession,
      step: 6,
      step_key: "REGISTER_FACE",
      device_type: isIOS,
    };
    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.invokeNativeBiometric
    ) {
      window.webkit.messageHandlers.invokeNativeBiometric.postMessage(
        bioMetricData
      );
    }
    // call Android App Biometric method
    windowsAndroidNativeMethods("biometric", bioMetricData);
  }, []);

  return (
    <Box className="accountBoxWrapper">
      <Typography align="center" variant="h3">
        There has been problem with accessing the Camera
      </Typography>
      <Typography align="center" variant="body1">
        Please click on the next button to continue to Signup process.You can
        always enable or disable this feature in your profile.
      </Typography>
      <Box className="text-center">
        <Button
          className="popup_Btn success_popup_btn m-2"
          variant="outlined"
          onClick={() => onBtnClick(null, "ios")}
        >
          Go next
        </Button>
      </Box>
    </Box>
  );
};

export default BiometricForNativeApp;
