import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import { ROLES } from "../../helpers/constants";
import AlertDialog from "../../common/AlertDialog";
import CountyListDropDown from "../../common/CountyListDropDown";
import { decryptPayload, getObjLength } from "../../helpers/utils";
import SubscriptionPlansContent from "../../common/SubscriptionPlans";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import SubscriptionPlansFeatures from "../../common/SubscriptionPlansFeatures";
import TotalPyamentPopup from "../../user-signup/React-stripe/TotalPyamentPopup";
import InvestorsAnnaulSubscriptionPlan from "../../common/investorsAnnaulSubscriptionPlan";

const SubscriptionPlans = ({
  onChangeModalScreen,
  planId,
  setFinalAmount,
  isAdminCreateUser = false,
  selectedData = {},
  from = "",
  uid = null,
  propertiesIds = [],
  ids = [],
  tabComponent = false,
  setSkipVerification,
  isShow = true,
  isUserCreateProperty = false,
  fromPropertyList,
  handleCloseAddPropertyModal,
  showSource = false,
  isBulkSubscription = false,
  promoCode = undefined,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [alert, setAlert] = useState(false);
  const [dataflag, setDataFlag] = useState(false);
  const [countyListModal, setCountyListModal] = useState(false);
  const [defaultcounty, setDefaultCountyData] = useState("Equity Protect");

  const userIdFromSession = localStorage.getItem("user_Id");
  const { propertyIds, properties, investorsID, isAnualPlanScreen } =
    useSelector((state) => state.signUpUser);

  // store data
  const userProfileDetails = useSelector(
    (state) => state?.userProfile?.userProfileDetails
  );
  const {
    subscriptionPlans,
    totalPaymentDetails,
    statesLoader,
    createAccResponse,
    getTotalAmountLoader,
  } = useSelector((state) => state?.signUpUser);
  const { selectedProperty, selectedUserID } = useSelector(
    (state) => state?.properties
  );
  const { servicerProfessionalDetails } = useSelector(
    (state) => state?.servicerProfile
  );

  let county_details =
    selectedData?.owner?.[0]?.county_details ||
    selectedData?.[0]?.countyDetails ||
    JSON.parse(localStorage.getItem("county_data"));

  const callPlansAPI = (county_data) => {
    if (getObjLength(county_data) && county_data?.id !== "ep") {
      dispatch(
        userSigupAction.getSubscriptionPlan({
          url: `county/${county_data?.id}/get-plans`,
          data: {},
          type: "post",
        })
      );
    } else {
      if (!investorsID) {
        dispatch(
          userSigupAction.getSubscriptionPlan({
            url: "get-plans",
            data: {},
            type: "post",
          })
        );
      }
    }
    setCountyListModal(false);
  };

  useEffect(() => {
    callPlansAPI(isAdminCreateUser ? county_details : null);
    handleToggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleNextButton = (data) => {
    setFinalAmount(totalPaymentDetails?.data?.total);
    setDataFlag(false);
    setAlert(false);
    console.log("handleNextButton==>", data);
    localStorage.setItem(
      "planDetails",
      JSON.stringify({ plan_id: data?.plan_id, plan_Name: data?.name })
    );
    planId(data?.plan_id);

    let params = {
      user_id: getObjLength(createAccResponse)
        ? createAccResponse.data?.user_id
        : userIdFromSession,
      step: 2,
      step_key: "GET_MAILING_ADDRESS",
    };
    if (from === "add-subscriptions" || from === "add-property") {
      params = {
        user_id: uid || selectedUserID,
        step: 2,
        step_key: "GET_MAILING_ADDRESS",
      };
    }

    dispatch(userSigupAction.fetchTotalPaymentDetails({}));
    if (
      isAdminCreateUser ||
      from === "add-subscriptions" ||
      from === "add-property"
    ) {
      dispatch(
        userSigupAction.getMailingAddress({
          url: "get-mailing-address",
          data: params,
        })
      );
      dispatch(
        userSigupAction.getStateCode({ url: "get-states-code", data: {} })
      );
    }

    onChangeModalScreen("cardsInfo");
  };

  const closeFinalAmountPopup = () => {
    setAlert(false);
    dispatch(userSigupAction.fetchTotalPaymentDetails({}));
  };

  // handle plan selection
  const handlePlanSelection = (plan) => {
    let pc = localStorage.getItem("promoCode");
    let user_id =
      userProfileDetails?.data?.user_id ||
      servicerProfessionalDetails?.data?.user_id;
    if (isAdminCreateUser) {
      user_id = getObjLength(createAccResponse)
        ? createAccResponse.data?.user_id
        : userIdFromSession;
    }
    if (from === "add-property") {
      user_id = selectedUserID || uid;
    }
    let data = {
      plan_id: parseInt(plan?.plan_id),
      user_id: decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.serviceProvider
      )
        ? servicerProfessionalDetails?.data?.user_id
        : user_id,
      property_id: [selectedProperty?.property_id],
      property_ids: [
        {
          address: selectedProperty?.address,
          property_id: selectedProperty?.property_id,
          city: selectedProperty?.city,
          state: selectedProperty?.state,
          zip: selectedProperty?.zip,
        },
      ],
      // state: selectedProperty?.json_response?.mm_fips_state_code,
    };

    if (from === "add-subscriptions") {
      data = {
        plan_id: parseInt(plan?.plan_id),
        user_id: uid,
        property_id: !tabComponent
          ? [selectedData?.property_id]
          : propertiesIds,
        property_ids: !tabComponent
          ? [
              {
                address: selectedData?.address,
                property_id: selectedData?.property_id,
                city: selectedData?.city,
                state: selectedData?.state,
                zip: selectedData?.zip,
              },
            ]
          : ids,
        // state: [selectedData?.state_code],
      };
    }
    if (pc?.length > 0) {
      data.promo_code = pc;
    }

    if (fromPropertyList) {
      data = {
        plan_id: parseInt(plan?.plan_id),
        user_id: user_id,
        property_id: propertyIds,
        property_ids: properties,
        // state: [selectedData?.state_code],
      };
    }

    if (
      userProfileDetails?.data?.user_type?.includes(ROLES?.professional) &&
      !userProfileDetails?.data?.role?.includes(ROLES?.customer)
    ) {
      dispatch(
        userSigupAction.getTotalPaymentDetails({
          url: "professional/get-final-amount",
          data: data,
          token: true,
        })
      );
    } else {
      let url = "customer/get-final-amount";
      if (isUserCreateProperty) {
        // url = "customer/get-final-amount-v1";

        data = {
          plan_id: parseInt(plan?.plan_id),
          user_id: decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
            ? servicerProfessionalDetails?.data?.user_id
            : user_id,
          property_id: [selectedProperty?.property_id],
          property_ids: [
            {
              address: selectedProperty?.address,
              property_id: selectedProperty?.property_id,
              state: selectedProperty?.state,
              city: selectedProperty?.city,
              zip: selectedProperty?.zip,
            },
          ],
        };
      } else {
        if (localStorage.getItem("promoCode")) {
          data = {
            ...data,
            promo_code: localStorage.getItem("promoCode"),
          };
        }
      }
      dispatch(
        userSigupAction.getTotalPaymentDetails({
          url: url,
          data: data,
        })
      );
    }
    localStorage.setItem(
      "Plan_Data",
      JSON.stringify({ ...data, state: selectedProperty?.state })
    );
    localStorage.setItem("Plan_Name", JSON.stringify(plan?.name));
  };

  console.log(
    from,
    "plan data==>.",
    "==selectedData==",
    selectedData,
    "==selectedProperty===",
    selectedProperty
  );

  useEffect(() => {
    if (Object.keys(subscriptionPlans).length > 0) {
      handleClose();
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    if (getObjLength(totalPaymentDetails)) {
      setAlert(true);
      if (totalPaymentDetails?.success) {
        setDataFlag(true);
      } else {
        setDataFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPaymentDetails]);

  console.log(alert, "alert===Dataflag==", dataflag);

  // filter county
  const handleChoosePricing = (countyData) => {
    setDefaultCountyData(countyData?.county_name);
    callPlansAPI(countyData);
  };

  const openCountyListModal = () => {
    setCountyListModal(true);
  };
  const IsAdmin =
    decryptPayload(localStorage.getItem("roles")) === ROLES?.admin ||
    decryptPayload(localStorage.getItem("roles")) === ROLES?.subAdmin;

  return (
    <Box
      className={`${isAnualPlanScreen && "border_clasess"} ${
        !isAdminCreateUser && `bg-white`
      }`}
    >
      {isShow && (
        <Typography
          align="center"
          variant="subtitle1"
          className="py-1 formHeader zoom-container"
          sx={{ position: "relative" }}
        >
          <LockIcon sx={{ fontSize: 16 }} className="me-1" />
          Secure connection
          <Button
            onClick={handleCloseAddPropertyModal}
            className="modal-close-btn close-text hide-inner-close"
            style={{
              position: "absolute",
              top: 0,
              right: -16,
              background: "transparent",
              textTransform: "none",
            }}
          >
            <CloseIcon
              className="hide-inner-close"
              sx={{ fontSize: 20, color: "white" }}
            />
          </Button>
        </Typography>
      )}

      <Box className="profile-wrapper plans-content-spacing subscription">
        {!investorsID && !userProfileDetails?.data?.investor_id ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // mb: 3,
                my: 3,
              }}
            >
              {(!isAdminCreateUser || showSource) && (
                <>
                  <Typography
                    sx={{ margin: "0 auto" }}
                    variant="h6"
                    className={`title ${IsAdmin && "mx-1"}`}
                  >
                    Subscriptions
                  </Typography>
                  {IsAdmin && (
                    <Button
                      className="popup_Btn success_popup_btn"
                      onClick={openCountyListModal}
                    >
                      Source
                    </Button>
                  )}
                </>
              )}
            </Box>
            {/* left side box */}
            <Box className="market-value-second-box ">
              <Grid container spacing={2} className="">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  order={{ xs: 2, sm: 1, md: 1, lg: 1, xl: 1 }}
                >
                  <Box
                    className="card"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Box className="d-flex align-items-center justify-content-between p-3">
                      <SubscriptionPlansContent
                        plan1={true}
                        plan2={false}
                        fullPage={true}
                        county_name={
                          county_details?.county_domain_name || defaultcounty
                        }
                      />
                    </Box>

                    <Stack
                      direction="column"
                      alignItems="center"
                      className="py-3"
                    >
                      <Button
                        variant="contained"
                        className="popup_Btn success_popup_btn"
                        onClick={() => {
                          setSelectedPlan(
                            subscriptionPlans?.data?.find(
                              (data) => data?.name === "Monthly"
                            )
                          );
                          handlePlanSelection(
                            subscriptionPlans?.data?.find(
                              (data) => data?.name === "Monthly"
                            )
                          );
                          // handleNextButton(
                          //   subscriptionPlans?.data?.find(
                          //     (data) => data?.name === "Monthly"
                          //   )
                          // );
                        }}
                      >
                        Activate Plan
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  order={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                >
                  <Box
                    className="card"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Box className="d-flex align-items-center justify-content-between p-3">
                      <SubscriptionPlansContent
                        plan1={false}
                        plan2={true}
                        fullPage={true}
                        county_name={
                          county_details?.county_domain_name || defaultcounty
                        }
                      />
                    </Box>

                    <Stack
                      direction="column"
                      alignItems="center"
                      className="py-3"
                    >
                      <Button
                        variant="contained"
                        className="popup_Btn success_popup_btn"
                        onClick={() => {
                          setSelectedPlan(
                            subscriptionPlans?.data?.find(
                              (data) => data?.name === "Yearly"
                            )
                          );
                          handlePlanSelection(
                            subscriptionPlans?.data?.find(
                              (data) => data?.name === "Yearly"
                            )
                          );
                          // handleNextButton(
                          //   subscriptionPlans?.data?.find(
                          //     (data) => data?.name === "Yearly"
                          //   )
                          // );
                        }}
                      >
                        Activate Plan
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <InvestorsAnnaulSubscriptionPlan
            uid={uid}
            paySecure={handleNextButton}
            propertiesIds={
              from === "add-property" || isUserCreateProperty
                ? [selectedProperty?.property_id]
                : isBulkSubscription
                ? ids?.map((id) => id?.property_id)
                : !tabComponent
                ? [selectedData?.property_id]
                : propertiesIds
            }
            selectedData={
              getObjLength(selectedProperty)
                ? selectedProperty
                : getObjLength(selectedData)
                ? selectedData
                : undefined
            }
            promoCode={promoCode}
          />
        )}
      </Box>

      <section className="plans-content-spacing">
        <Container fluid>
          <SubscriptionPlansFeatures
            fullPage={true}
            plans_title="All plans include"
          />
        </Container>
      </section>

      {alert &&
        (dataflag ? (
          <TotalPyamentPopup
            dataflag={dataflag}
            setDataFlag={setDataFlag}
            alert={alert}
            setAlert={setAlert}
            closePopup={closeFinalAmountPopup}
            planDetails={selectedPlan}
            isClose={false}
            paySecure={() => handleNextButton(selectedPlan)}
          />
        ) : (
          <>
            <AlertDialog
              type="Error"
              openPopup={alert}
              onClose={true}
              onCloseFun={closeFinalAmountPopup}
              mesage={totalPaymentDetails?.data?.message}
              buttons={
                <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                  <Button
                    className="popup_Btn error_popup_btn m-2"
                    onClick={closeFinalAmountPopup}
                  >
                    cancel
                  </Button>
                </Stack>
              }
            />
          </>
        ))}

      <DialogBox
        fullScreenModal={false}
        width={"sm"}
        contentClassName={"bg-white"}
        title={"Select the Source for Pricing"}
        openPopup={countyListModal}
        onClose={() => {
          setCountyListModal(false);
        }}
        content={
          <CountyListDropDown
            handleCountyData={handleChoosePricing}
            hideEPFromList={false}
            defaultcounty={defaultcounty}
          />
        }
      />
      <Loader open={statesLoader || getTotalAmountLoader} />
    </Box>
  );
};

export default SubscriptionPlans;
