import React from "react";

import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import RealStoryDesign from "../common/RealStoryDesign";
import FirstCommonBlogSection from "./FirstCommonBlogSection";
import SecondCommonBlogSection from "./SecondCommonBlogSection";
import LearnEquityProtectSection from "./LearnEquityProtectSection";
import NotifyPolice from "../../assets/SEO/NotifyPolice.webp";

import "./Blogs.scss";
import {
  BLOG_SUPPORT_PHONE,
  BLOG_SUPPORT_PHONE_FORMAT,
} from "../helpers/constants";

const StepsToTakeLostWallet = () => {
  return (
    <div>
      <div className="blogs-content">
        <FirstCommonBlogSection
          blog_date="January 18, 2025"
          title={"Steps to Take When You Lose Your Wallet"}
          mainImg="LoseWallet"
        />

        <SecondCommonBlogSection
          mainContent={
            <>
              <p className="pb-1">
                Losing your wallet can feel overwhelming, especially when it
                holds essentials like your ID, credit cards, and documents. If
                you're thinking, "What do I do? I lost my wallet," acting fast
                will help protect your finances and personal information.
                Whether it's a lost or stolen wallet, taking immediate steps can
                minimize risks like unauthorized transactions and identity
                theft.
              </p>

              <p className="pt-1">
                Knowing how to respond after a lost wallet can make a
                difference. Taking steps such as contacting your bank, filing a
                police report, replacing identification, and monitoring your
                accounts can help you regain control of your financial security.
                Proactive measures like credit monitoring and{" "}
                <Link to="/property-risk-management" target="_blank">
                  home title protection
                </Link>{" "}
                further reduce the risk of fraud.
              </p>
            </>
          }
          content={[
            {
              type: "title_tag_2",
              content: "Immediate Steps to Take When You Lose Your Wallet",
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Taking swift action after a lost wallet helps protect your
                  financial accounts and personal information from misuse.
                </>
              ),
            },
            {
              type: "title_tag_3",
              content: "Contact Your Bank and Credit Card Companies",
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Alerting your financial institutions as soon as possible can
                  prevent unauthorized transactions. If you suspect a stolen
                  wallet, call your bank or credit card companies immediately to
                  freeze your accounts to stop any further charges while you
                  work on securing your accounts.
                </>
              ),
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Request replacement cards and inquire about fraud protection
                  options. Many banks offer temporary digital cards to ensure
                  you can continue transactions while waiting for your physical
                  replacements.
                </>
              ),
            },
            {
              type: "title_tag_3",
              content: "Notify the Police",
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Filing a police report after a lost wallet can be helpful,
                  especially if identity theft becomes an issue. An official
                  record strengthens your position when disputing fraudulent
                  charges or working with credit bureaus to restore your
                  accounts.
                </>
              ),
            },
            {
              type: "caption_texts",
              content: (
                <>
                  When reporting the loss, provide the authorities with key
                  details, including:
                </>
              ),
            },
            {
              type: "list",
              content: [
                "When and where your wallet was lost or stolen",
                "A list of missing items (e.g., ID, credit cards, social security card)",
                "Suspicious activity, if any, noticed since losing the wallet",
              ],
            },

            {
              type: "image",
              imgSrc: NotifyPolice,
              alt: "Resources From County ",
            },
            {
              type: "title_tag_2",
              content: "Protecting Your Identity and Personal Information",
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Once financial accounts are secured, focus on protecting your
                  identity by replacing missing documents and closely monitoring
                  your accounts.
                </>
              ),
            },
            {
              type: "title_tag_3",
              content: "Update Your Identification",
            },
            {
              type: "caption_texts",
              content: (
                <>
                  If your driver's license or ID was in your lost wallet,
                  replacing it should be a priority. Visit your local Department
                  of Motor Vehicles or equivalent office with an alternative
                  form of identification and proof of residency to apply for a
                  replacement.
                </>
              ),
            },
            {
              type: "caption_texts",
              content: (
                <>
                  If your wallet had your social security card, contact the
                  Social Security Administration (SSA) immediately to report it.
                  Though the SSA advises against carrying your Social Security
                  card regularly, reporting its loss can help prevent potential
                  identity theft. Request a replacement card through their
                  official channels.
                </>
              ),
            },
            {
              type: "title_tag_3",
              content: "Monitor Your Financial Accounts",
            },
            {
              type: "caption_texts",
              content: (
                <>
                  After losing a wallet, staying proactive by regularly checking
                  your financial accounts can help catch any suspicious
                  activity, unauthorized transactions, or new accounts opened in
                  your name.
                </>
              ),
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Consider using credit monitoring services, which can send
                  alerts for unusual activity, new credit inquiries, or changes
                  to your credit score.
                </>
              ),
            },
            {
              type: "title_tag_3",
              content: "Consider Home Title Protection Service",
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Home title protection adds another layer of security against
                  identity theft. Even though a lost wallet may seem unrelated
                  to homeownership risks, stolen personal information can be
                  used for more serious crimes, such as fraudulent property
                  title changes.
                </>
              ),
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Home title protection monitors your property's title and
                  alerts you if any unauthorized activity occurs, helping
                  prevent financial loss from title fraud.
                </>
              ),
            },
            {
              type: "title_tag_2",
              content: "Act Fast to Safeguard Your Identity and Finances",
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Losing your wallet can be stressful, but taking immediate
                  steps can protect your finances and identity. Contact your
                  bank and credit card companies immediately to freeze accounts
                  and prevent unauthorized transactions, especially if you
                  suspect a stolen wallet. Filing a police report provides an
                  official record, which can help if fraud occurs.
                </>
              ),
            },
            {
              type: "caption_texts",
              content: (
                <>
                  Stay proactive by monitoring your financial accounts and
                  considering services like home title protection for long-term
                  security. Act quickly after a lost or stolen wallet to take
                  control of your financial security. Contact{" "}
                  <Link to="/" target="_blank">
                    EquityProtect
                  </Link>{" "}
                  at{" "}
                  <a href={`tel:${BLOG_SUPPORT_PHONE}`}>
                    {BLOG_SUPPORT_PHONE_FORMAT}
                  </a>{" "}
                  for expert guidance and assistance.
                </>
              ),
            },
          ]}
        />

        <LearnEquityProtectSection />
        <section className="story_section">
          <Container fluid className="mx-150">
            <div>
              <RealStoryDesign
                subTitle="Related articles"
                title="More stories about title fraud"
              />
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default StepsToTakeLostWallet;
