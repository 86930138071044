import { call, put, takeLatest } from "redux-saga/effects";

import { getAPIPayload } from "../../../../apis/getApi";
import { postAPIPayload } from "../../../../apis/postApi";
import { deleteAPIPayload } from "../../../../apis/deleteApi";
import { professionalAction } from "../../../slices/admin/professionals/professionalsSlice";

export function* fetchProfessionalListDataSaga({ payload }) {
  console.log("asdasd", payload);
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(professionalAction.setProfessionalListData(response));
}

export function* addProfessionalDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set data in redux store
  yield put(professionalAction.setProfessionalDetails(response));
}

export function* deleteProfessionalDetailsSaga({ payload }) {
  // return console.log("  ", payload);
  const response = yield call(deleteAPIPayload, payload);

  // set data in redux store
  // yield put(professionalAction.setProfessionalDetails(response));
  yield put(professionalAction.setDeletedProfessionalDetails(response));
}

export function* fetchProfessionalTypesSaga({ payload }) {
  // return console.log("  ", payload);
  const response = yield call(getAPIPayload, payload);

  // set data in redux store
  yield put(professionalAction.setProfessionalTypes(response));
}

export function* fetchSwitchProfessionalDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(professionalAction.fetchSwitchProfessionalData(response));
}

export function* activateDeactivateProfessionalSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(
    professionalAction.setActivateDeactivateProfessionalDetails(response)
  );
}

export function* fetchTitleCompanyListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(professionalAction.fetchTitleCompanyListData(response));
}

export function* fetchRepListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(professionalAction.fetchRepListData(response));
}

export function* watchProfessionalSagaAsync() {
  yield takeLatest(
    professionalAction.fetchProfessionalListData,
    fetchProfessionalListDataSaga
  );
  yield takeLatest(
    professionalAction.addProfessionalDetails,
    addProfessionalDetailsSaga
  );
  yield takeLatest(
    professionalAction.deleteProfessionalDetails,
    deleteProfessionalDetailsSaga
  );
  yield takeLatest(
    professionalAction.fetchProfessionalTypes,
    fetchProfessionalTypesSaga
  );
  yield takeLatest(
    professionalAction.getSwitchProfessionalData,
    fetchSwitchProfessionalDataSaga
  );
  yield takeLatest(
    professionalAction.activateDeactivateProfessional,
    activateDeactivateProfessionalSaga
  );
  yield takeLatest(
    professionalAction.getTitleCompanyListData,
    fetchTitleCompanyListDataSaga
  );
  yield takeLatest(professionalAction.getRepListData, fetchRepListDataSaga);
}
