import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Stack } from "@mui/material";

import { Loader } from "../common/loader";
import { getObjLength } from "../helpers/utils";
import check_img from "../../assets/icons/checkmark_blk.png";
import arrow_back from "../../assets/home/arrow_left_alt.svg";
import auto_activity1 from "../../assets/home/auto_activity_zone.svg";
import multiple_properties from "../../assets/home/multiple_properties.svg";
import heap_snapshot_multiple from "../../assets/home/heap_snapshot_multiple.svg";

const InvestorsPlansContent = ({ plan1 = true, plan2 = true, price }) => {
  const navigate = useNavigate();
  const { subscriptionPlans, statesLoader } = useSelector(
    (state) => state.signUpUser
  );

  const investors_token = localStorage.getItem("inv_token");

  const [YEARLY_PLAN, set_YEARLY_PLAN] = useState();
  const [YEARLY_PLAN_PER_MONTH, set_YEARLY_PLAN_PER_MONTH] = useState();

  const handlebackToProperties = () => {
    if (investors_token) {
      navigate(`/investor-list/${investors_token}`);
    } else {
      navigate("/investor-login");
    }
  };

  useEffect(() => {
    if (getObjLength(subscriptionPlans) && subscriptionPlans?.success) {
      if ((subscriptionPlans?.data).length > 0) {
        const yearlyPlans = subscriptionPlans?.data?.find(
          (data) => data?.name === "Yearly"
        );

        set_YEARLY_PLAN(yearlyPlans?.amount);
        set_YEARLY_PLAN_PER_MONTH(yearlyPlans?.recurring_amount);
      }
    }
  }, [subscriptionPlans]);

  return (
    <>
      {plan1 && (
        <div className="card_inner w-100 break-word inv_title_yearly">
          <div className="d-flex align-items-center mb-4">
            <div
              className="d-flex align-items-start"
              style={{ flexDirection: "column" }}
            >
              <p className="title mb-0">EquityProtect</p>
              <h2 className="subTitle mb-0">Yearly</h2>
            </div>
            <div className="ms-auto">
              <LazyLoadImage
                src={auto_activity1}
                alt="auto_activity_zone"
                width="48px"
                height="48px"
              />
            </div>
          </div>
          <hr className="seperator" />
          <div className="pricing_Wrapper text-center pt-1 inv_yearly">
            <h4 className="mb-1 ">
              ${((YEARLY_PLAN_PER_MONTH || price) / 100).toFixed(2)}
            </h4>

            <p className="mb-0">Per year, per property</p>
            <h5 className="mt-3">${(YEARLY_PLAN / 100).toFixed(2)}/yr </h5>
            <p className="mb-0">For the first year per Property</p>

            <p className="mb-0 mt-0 itlalic_text">
              Plus $125.00 per County for recording fees
            </p>
            <hr className="seperator" />
            <div className="">
              <ul
                className="features_list mb-4"
                style={{ listStyleType: "none", textAlign: "left !important" }}
              >
                <li className="position-relative mb-3">
                  <Stack direction={"row !important"} alignItems={"center"}>
                    <img src={check_img} className="check_img" alt="check" />
                    <span>24/7 Property Monitoring</span>
                  </Stack>
                </li>
                <li className="position-relative mb-3">
                  <Stack direction={"row !important"} alignItems={"center"}>
                    <img src={check_img} className="check_img" alt="check" />
                    <span>SmartPolicy insuring your home ownership</span>
                  </Stack>
                </li>
                <li className="position-relative mb-3">
                  <Stack direction={"row !important"} alignItems={"center"}>
                    <img src={check_img} className="check_img" alt="check" />
                    <span>
                      {" "}
                      Access to your recorded property information at anytime
                    </span>
                  </Stack>
                </li>

                <li className="position-relative mb-3">
                  <Stack direction={"row !important"} alignItems={"center"}>
                    <img src={check_img} className="check_img" alt="check" />
                    <span> Blockchain Protection of your property</span>
                  </Stack>
                </li>
                <li className="position-relative mb-3">
                  <Stack direction={"row !important"} alignItems={"center"}>
                    <img src={check_img} className="check_img" alt="check" />
                    <span>Live Support</span>
                  </Stack>
                </li>
              </ul>
            </div>
          </div>

          <div className="back_to_properties mt-auto">
            <button className="btn" onClick={handlebackToProperties}>
              <LazyLoadImage
                src={arrow_back}
                alt="arrow_back"
                width="16px"
                height="12px"
              />{" "}
              Back to properties list
            </button>
          </div>
        </div>
      )}

      {plan2 && (
        <div className="card_inner w-100 break-word multi_properties">
          <div className="d-flex align-items-center mb-4">
            <div
              className="d-flex align-items-start div__1"
              style={{ flexDirection: "column" }}
            >
              <p className="title mb-0">EquityProtect</p>
              <h2 className="subTitle mb-0">Multi-Property Discount</h2>
            </div>
            <div className="ms-auto div__2">
              <LazyLoadImage
                src={multiple_properties}
                alt="auto_activity_zone"
                width="48px"
                height="48px"
              />
            </div>
          </div>
          <hr className="seperator" />
          <div className="pricing_Wrapper text-center mt-5">
            <div>
              <p className="properties_btn">1 - 4 properties</p>
              <h6 className="">10% Discount per Property</h6>
            </div>
            <div>
              <p className="properties_btn">5 - 9 properties</p>
              <h6 className="">20% Discount per Property</h6>
            </div>
            <div>
              <p className="properties_btn">10 properties and above</p>
              <h6 className="">30% Discount per Property</h6>
            </div>
            <div className="additional_discount">
              <LazyLoadImage
                src={heap_snapshot_multiple}
                alt="heap_snapshot_multiple"
                width="32px"
                height="37px"
              />
              <p className="itlalic_text">
                If you have more than 30 properties please contact us for
                additional discounts.
              </p>
            </div>
          </div>
        </div>
      )}
      <Loader open={statesLoader} />
    </>
  );
};

export default InvestorsPlansContent;
