import axios from "axios";
import { toast } from "react-toastify";

import { BASE_URL, ROLES } from "../components/helpers/constants";
import {
  decryptPayload,
  windowsAndroidNativeMethods,
} from "../components/helpers/utils";

export const postAPIPayload = async (data) => {
  console.log("payload", data);
  let passingData = data.data === undefined ? {} : data.data;
  let device = decryptPayload(localStorage.getItem("isMobile"));

  // to identify logged in devices
  if (
    decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.customer) ||
    decryptPayload(localStorage.getItem("roles"))?.includes(
      ROLES.professional
    ) ||
    decryptPayload(localStorage.getItem("path"))?.includes("create-account")
  ) {
    // for form data
    const formData = new FormData();
    if (device) {
      formData.append(`device_type`, device);
    } else {
      formData.append(`device_type`, "web");
    }

    if (!(data?.data instanceof FormData)) {
      if (device) {
        passingData = { ...passingData, device_type: device };
      } else {
        passingData = { ...passingData, device_type: "web" };
      }
    }
    // else {
    //   const formData = new FormData();
    //   formData.append(`document`, ...passingData);
    //   if (device) {
    //     formData.append(`device_type`, device);
    //   } else {
    //     formData.append(`device_type`, "web");
    //   }
    // }

    // when paylaod includes the form data but not uploading the file
    if (data?.data instanceof FormData) {
      for (const [key, value] of data?.data?.entries()) {
        formData.append(key, value);
      }
      passingData = formData;
    }
  }

  return await postAPICall(
    data.url,
    passingData,
    data.token,
    data.isFile,
    data?.brokertoken
  );
};

export const postAPICall = async (
  url,
  reqbody,
  token,
  isFile = false,
  brokertoken
) => {
  return await axios
    .post(`${BASE_URL}${url}`, reqbody, {
      headers: {
        authorization: token
          ? `Bearer ${localStorage.getItem("authToken")}`
          : brokertoken
          ? `Bearer ${localStorage.getItem("agentauthToken")}`
          : null,
        "content-type": isFile ? "multipart/form-data" : "application/json",
      },
    })
    .then(function (response) {
      console.log("in postAPIPayload gettRequest...", response);
      return response.data;
    })
    .catch(function (error) {
      console.error("Error in postAPIPayload gettRequest", error);
      let data = {
        success: false,
        error: error.message,
        data: error.response?.data,
        statuscode: error.response?.status,
      };
      if (error.response?.status === 401) {
        const path = window.location.pathname;
        console.log("pathname post ==>", path);
        if (path !== "/login" && !path?.includes("investors")) {
          localStorage.setItem("previousPath", path);
          localStorage.setItem("previousUserID", localStorage.getItem("uID"));
        }
        localStorage.removeItem("authToken");
        localStorage.removeItem("authorised");
        toast.error("Your token is expired or invalid. Please Login again.");

        // logout native method call for mobile app
        if (
          window?.webkit &&
          window?.webkit?.messageHandlers &&
          window?.webkit?.messageHandlers?.invokeNativeLogout
        ) {
          console.log("Logout Native App got called...");
          window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
        }
        // call Android App Logout method
        windowsAndroidNativeMethods("logout");

        if (path?.includes("investor")) {
          window.location.replace("/investor-login");
        } else if (path?.includes("broker") || path?.includes("/title/")) {
          const brokerName = path.split("/")?.[2];
          if (path?.includes("broker")) {
            window.location.replace(`/broker/${brokerName}/agent-login`);
          } else {
            window.location.replace(`/title/${brokerName}/agent-login`);
          }
        } else {
          window.location.replace("/login");
        }
      }
      return data;
    });
};
