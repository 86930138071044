import { useRef } from "react";
import { useState } from "react";
import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Cropper } from "react-cropper";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import DeleteIcon from "@mui/icons-material/Delete";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Autocomplete as Pracel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import CustomTextField from "../common/CustomTextField";
import CustomPhoneNumberInput from "../common/CustomPhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  getAddressFromGoogleAutoComplete,
  getObjLength,
  getShortNameAddressFromGoogleAutoComplete,
} from "../helpers/utils";
import { FieldStyle, GOOGLE_API_KEY } from "../helpers/constants";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { brokerAgentsAction } from "../../redux/slices/brokers";
import { LoaderWithTimeout } from "../common/LoaderWithTimeout";
import { propertyRiskFormValidations } from "../PropertyRiskManagement/PropertyRiskManagementForm";
import { Loader } from "../common/loader";

const AddAgentProperties = ({
  handleClose = () => {},
  base_route = "broker",
}) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const agent_id = localStorage.getItem("agent_id");

  const [value, setValue] = useState("address");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [propertyData, setPropertyData] = useState({});

  const { loaded } = useSelector((state) => state?.autocomplete);
  const { countyList, statesLoader } = useSelector((state) => state.signUpUser);
  const { brokerAgentLoader, addNewAgentPropertyData } = useSelector(
    (state) => state?.brokerAgents
  );

  const getCountyList = (county_value) => {
    console.log("value==>", county_value);
    dispatch(
      userSigupAction.getCountyList({
        url: "get-county-autocomplete",
        data: { county: county_value },
      })
    );
  };

  const onSubmit = (values) => {
    let payload = { ...values, ...propertyData, type: value };

    if (value === "address") {
      delete payload?.county_code;
      delete payload?.parcel_number;
    } else {
      delete payload?.address;
      delete payload?.city;
      delete payload?.state;
      delete payload?.zip;
    }

    console.log("final payload==>", payload);
    dispatch(
      brokerAgentsAction.getAddNewAgentPropertyData({
        url: `agent/add-property/${agent_id}`,
        data: payload,
        brokertoken: true,
        source: base_route,
      })
    );
  };

  useEffect(() => {
    if (getObjLength(countyList)) {
      if (countyList?.success) {
        setOptions([...countyList?.data]);
      }
    }
  }, [countyList]);

  // Check if the form is in the DOM
  const checkFormLoaded = () => {
    if (formRef?.current) {
      console.log("form loaded..");
    } else {
      setTimeout(checkFormLoaded, 100); // Check again after 100ms
    }
  };

  useEffect(() => {
    // Start checking for the form's presence
    checkFormLoaded();

    return () => {
      // Clean up if necessary
    };
  }, []);

  if (!loaded) return <LoaderWithTimeout open={true} />;

  const validationSchema = propertyRiskFormValidations(value, { name: false });
  return (
    <>
      <div className="broker-create-account">
        <section className="create_acc_form_wrapper m-0">
          <Container fluid className="bg-white ">
            <Box
              className="box-container agents_form"
              padding={"25px 20px 50px!important"}
            >
              <Box className="bg-white">
                <Formik
                  enableReinitialize
                  validateOnChange
                  validationSchema={validationSchema}
                  initialValues={{
                    //name: "",
                    email: "",
                    phone: "",
                    address: "",
                    parcel_number: "",
                    county_code: "",
                  }}
                  onSubmit={(values, validation) => {
                    if (!isValidPhoneNumber(values?.phone)) {
                      return;
                    }
                    console.log("vcalues-->>", values);
                    onSubmit(values);
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    touched,
                    errors,
                    setTouched,
                    isSubmitting,
                  }) => {
                    // handle selected address for sign up form
                    const handleChange = (address) => {
                      console.log("address changed==>", address);
                      const condos = address.address_components.filter((i, k) =>
                        i.types.includes("subpremise")
                      );
                      var units = condos.length > 0 ? condos[0]?.long_name : "";
                      setFieldValue("unit_no", units);
                      setFieldValue(
                        "address",
                        getShortNameAddressFromGoogleAutoComplete(
                          address,
                          "street_number"
                        ) +
                          (getShortNameAddressFromGoogleAutoComplete(
                            address,
                            "street_number"
                          )
                            ? " "
                            : "") +
                          getShortNameAddressFromGoogleAutoComplete(
                            address,
                            "route"
                          )
                      );
                      var county = getShortNameAddressFromGoogleAutoComplete(
                        address,
                        "administrative_area_level_2"
                      );
                      var county_name = county?.replace("County", "").trim();
                      let data = {
                        searched_Add: address?.formatted_address,
                        street_number: getAddressFromGoogleAutoComplete(
                          address,
                          "street_number"
                        ),
                        site_route: getAddressFromGoogleAutoComplete(
                          address,
                          "route"
                        ),
                        country: getShortNameAddressFromGoogleAutoComplete(
                          address,
                          "country"
                        ),
                        latitude: address.geometry.location.lat(),
                        longitude: address.geometry.location.lng(),
                        city: getAddressFromGoogleAutoComplete(
                          address,
                          "locality"
                        ),
                        county: county_name,
                        state_name: getAddressFromGoogleAutoComplete(
                          address,
                          "administrative_area_level_1"
                        ),
                        state: getShortNameAddressFromGoogleAutoComplete(
                          address,
                          "administrative_area_level_1"
                        ),
                        zip: getAddressFromGoogleAutoComplete(
                          address,
                          "postal_code"
                        ),
                        //unit_no: units,
                      };

                      setPropertyData(data);
                    };
                    return (
                      <>
                        <Form ref={formRef}>
                          <Grid container spacing={1}>
                            {/* <Grid item xs={12} md={6} px={1} py={2}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Owner's name
                              </InputLabel>
                              <CustomTextField
                                name="name"
                                validation={{
                                  values,
                                  setFieldValue,
                                  touched,
                                  errors,
                                }}
                              />
                            </Grid> */}

                            <Grid item xs={12} md={6} px={1} py={2}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Owner’s email address
                              </InputLabel>
                              <CustomTextField
                                name="email"
                                validation={{
                                  values,
                                  setFieldValue,
                                  touched,
                                  errors,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={6} px={1} py={2}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Owner’s mobile number
                              </InputLabel>
                              <CustomPhoneNumberInput
                                name="phone"
                                placeholder={null}
                                values={values}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                otherProps={{
                                  onChange: (event) => {
                                    setFieldValue("phone", event);
                                    if (!event) {
                                      setFieldValue("phone", "");
                                    }
                                  },
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={6} px={1} py={2}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Select Proeprty Serach Type
                              </InputLabel>
                              <FormControl
                                fullWidth
                                className="sign-textField"
                                error={
                                  touched.office_id && Boolean(errors.office_id)
                                }
                              >
                                <Select
                                  value={value}
                                  onChange={(event) => {
                                    setValue(event.target.value);
                                  }}
                                  displayEmpty
                                  className="googleAutoComplete mobile-select-tabs w-100 text-start"
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem
                                    sx={{
                                      color:
                                        value === "address"
                                          ? "#0395FF"
                                          : "# 0D120B",
                                    }}
                                    value={"address"}
                                  >
                                    Search by Address
                                  </MenuItem>
                                  <MenuItem
                                    sx={{
                                      color:
                                        value === "parcel"
                                          ? "#0395FF"
                                          : "#0D120B",
                                    }}
                                    value={"parcel"}
                                  >
                                    Search by Parcel
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            {value === "address" && (
                              <>
                                <Grid item xs={12} sm={12} md={6} px={1} py={2}>
                                  <InputLabel shrink htmlFor="bootstrap-input">
                                    Owner’s Property address
                                  </InputLabel>
                                  <Autocomplete
                                    className={`googleAutoComplete mw-100 ${
                                      touched.address && Boolean(errors.address)
                                        ? "error_border"
                                        : ""
                                    }`}
                                    name="property"
                                    //  value={values?.address}
                                    apiKey={GOOGLE_API_KEY}
                                    // onChange={(e) => {
                                    //   setFieldValue("address", e.target.value);
                                    // }}
                                    onPlaceSelected={(address) => {
                                      handleChange(address);
                                    }}
                                    onBlur={(event) => {
                                      console.log(
                                        "onChange==>",
                                        event.target.value
                                      );

                                      setFieldValue(
                                        "address",
                                        event.target.value
                                      );
                                    }}
                                    onKeyDown={(event) =>
                                      event.key === "Enter"
                                        ? event.preventDefault()
                                        : null
                                    }
                                    placeholder=""
                                    options={{
                                      types: ["address"],
                                      componentRestrictions: { country: "us" },
                                    }}
                                  />
                                  {touched.address &&
                                    Boolean(errors.address) && (
                                      <FormHelperText
                                        className="m-0"
                                        sx={{
                                          color: "#d32f2f !important",
                                          maxWidth: "none !important",
                                          fontSize: "12px !important",
                                        }}
                                      >
                                        {touched.address && errors.address}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} px={1} py={2}>
                                  <InputLabel shrink htmlFor="bootstrap-input">
                                    Proeprty Unit number
                                  </InputLabel>
                                  <CustomTextField
                                    placeholder=""
                                    name="unit_no"
                                    validation={{
                                      values,
                                      setFieldValue,
                                      touched,
                                      errors,
                                    }}
                                    otherProps={{
                                      onChange: (event) => {
                                        setFieldValue(
                                          "unit_no",
                                          event.target.value
                                        );
                                      },
                                    }}
                                  />
                                </Grid>
                              </>
                            )}

                            {value === "parcel" && (
                              <>
                                <Grid item xs={12} sm={6} md={6} px={1} py={2}>
                                  <InputLabel shrink htmlFor="bootstrap-input">
                                    Property APN Number
                                  </InputLabel>
                                  <CustomTextField
                                    name="parcel_number"
                                    validation={{
                                      values,
                                      setFieldValue,
                                      touched,
                                      errors,
                                    }}
                                    // otherProps={{
                                    //   onChange: (event) => {
                                    //     setFieldValue(
                                    //       "parcel_number",
                                    //       event.target.value
                                    //     );
                                    //   },
                                    // }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} px={1} py={2}>
                                  <InputLabel shrink htmlFor="bootstrap-input">
                                    Search Proeprty County
                                  </InputLabel>
                                  <FormControl
                                    fullWidth
                                    className="sign-textField"
                                    htmlFor="demo-customized-textbox"
                                    error={
                                      touched.county_code &&
                                      Boolean(errors.county_code)
                                    }
                                  >
                                    <Pracel
                                      name="county"
                                      id="county"
                                      // freeSolo
                                      disableClearable
                                      sx={FieldStyle}
                                      open={open}
                                      //   value={values.county_code}
                                      onOpen={() => {
                                        setOpen(true);
                                      }}
                                      onClose={() => {
                                        setOpen(false);
                                      }}
                                      onChange={(event, newValue) => {
                                        setFieldValue(
                                          "county_code",
                                          String(newValue?.id)
                                        );
                                      }}
                                      isOptionEqualToValue={(option, value) =>
                                        option.county === value.county
                                      }
                                      getOptionLabel={(option) =>
                                        option.county || values.county_code
                                      }
                                      options={options}
                                      loading={statesLoader}
                                      renderInput={(params) => (
                                        <TextField
                                          onChange={(event) => {
                                            // setFieldValue(
                                            //   "county_code",
                                            //   event.target.value
                                            // );
                                            getCountyList(event.target.value);
                                            setOptions([]);
                                          }}
                                          // label="County"
                                          placeholder={""}
                                          {...params}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <React.Fragment>
                                                {statesLoader ? (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                  />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                              </React.Fragment>
                                            ),
                                          }}
                                          helperText={
                                            touched.county_code &&
                                            errors.county_code
                                          }
                                          error={
                                            touched.county_code &&
                                            Boolean(errors.county_code)
                                          }
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                              </>
                            )}

                            {getObjLength(addNewAgentPropertyData) &&
                              !addNewAgentPropertyData?.success && (
                                <p className="text-danger text-center w-100 mb-3">
                                  {addNewAgentPropertyData?.data?.message ||
                                    addNewAgentPropertyData?.error}
                                </p>
                              )}

                            <Grid
                              item
                              xs={12}
                              md={12}
                              px={1}
                              className="d-flex justify-content-end align-items-center mt-3"
                            >
                              <Stack
                                direction={"row"}
                                spacing={1}
                                color={"black"}
                              ></Stack>
                              <Button
                                className="next_button other_popup_btn mx-2"
                                variant="contained"
                                onClick={handleClose}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                className="next_button"
                                variant="contained"
                              >
                                Add Property
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Container>
        </section>
      </div>
      <Loader open={brokerAgentLoader} />
    </>
  );
};

export default AddAgentProperties;
