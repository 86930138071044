import React from "react";

import { Col, Row } from "react-bootstrap";

import "../../styles/howItWorksSection.scss";
import { Link } from "react-router-dom";

const HowItWorksSection = ({ title = null, isSignupBtn = false }) => {
  return (
    <>
      <Row>
        <Col>
          <div className="card border-0 alert_card ">
            <div className="card-body p-0">
              <p className="notify_header text-center mb-4">How it works</p>
              <h2 className="card-title text-center mb-5 sub_header">
                {title
                  ? title
                  : "I want peace of mind, please; how does it work?"}
              </h2>
            </div>
            <Row className="steps_wrapper mt-2">
              <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                <div className="p-0 p-md-3 d-flex flex-column align-items-start">
                  <div className="number_alignment">
                    <span className="steps mb-4">1</span>
                  </div>
                  <h3 className="fw-600">
                    We Validate & Confirm your Ownership.
                  </h3>
                  <p className="fw-400">
                    Using multiple different data aggregators, we will verify
                    your home ownership by challenging you with questions only
                    you will know the answer to.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                <div className="p-0 p-md-3 d-flex flex-column align-items-start">
                  <div className="number_alignment">
                    <span className="steps mb-4">2</span>
                  </div>
                  <h3 className="fw-600">We protect</h3>
                  <p className="fw-400">
                    Using a patented process, we will lock down your title and
                    protect you from any transfers or new liens.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                <div className="p-0 p-md-3 d-flex flex-column align-items-start">
                  <div className="number_alignment">
                    <span className="steps mb-4">3</span>
                  </div>
                  <h3 className="fw-600">You Verify</h3>
                  <p className="fw-400">
                    Using facial recognition, we will verify who you are and
                    then unlock your property at your request.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                <div className="p-0 p-md-3 d-flex flex-column align-items-start">
                  <div className="number_alignment">
                    <span className="steps mb-4">4</span>
                  </div>
                  <h3 className="fw-600">Action (or not)</h3>
                  <p className="fw-400">
                    Allowing only specific access to your Real Estate
                    professionals like escrow and lenders, or stop the attempt
                    to tamper with or steal your equity.
                  </p>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                className="text-center last_section"
                style={{}}
              >
                <div className="">
                  <p style={{ fontWeight: "800" }}>
                    We will insure you with our SmartPolicy against anything
                    fraudulent
                  </p>
                  <p className="fw-400">
                    occurring on your title and will rectify it at our costs. We
                    do this by providing a form of Gap Assurance from your
                    purchase to your sale for as long as you have EquityProtect.
                  </p>
                  {isSignupBtn && (
                    <Link to="/create-account">
                      <button className="text-white text-uppercase button-width-mobile signin_button_v2 button_clr text-line-fix">
                        Get Protection Now
                      </button>
                    </Link>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(HowItWorksSection);
