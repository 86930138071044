import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getMonthFromString, getObjLength } from "../../helpers/utils";
import MasterCard from "../../../assets/dashboard/mastercard.svg";
import VisaCard from "../../../assets/dashboard/visa.svg";
import AmericanCard from "../../../assets/dashboard/american-express.svg";
import DiscoverCard from "../../../assets/dashboard/discover.svg";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import AlertDialog from "../../common/AlertDialog";
import { Loader } from "../../common/loader";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";

const Paperstyles = {
  "&.MuiPaper-elevation0": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
    borderRadius: "4px",
    p: 3,
    mb: 2,
  },
};

const ManageCreditCard = ({
  uid = null,
  onClose = () => {},
  addCard = () => {},
  cardListAPI = () => {},
}) => {
  const {
    cardListData,
    cardListLoader,
    deleteCardLoader,
    deleteCardData,
    setDefaultCardData,
  } = useSelector((state) => state.propertyUsers);

  const [removeCardDetails, setRemoveCardDetails] = useState({});
  const [removeCardPopup, setRemoveCardPopup] = useState(false);
  const [showValidationmsg, setShowValidationmsg] = useState(false);
  const [defaultCardmsg, setDefaultCardmsg] = useState(false);

  const dispatch = useDispatch();

  // const cardListAPI = () => {
  //   dispatch(
  //     propertyUsersAction.getCardListData({
  //       url: `admin/users/${uid}/card`,
  //       data: {},
  //       token: true,
  //     })
  //   );
  // };

  const removeCard = (data) => {
    console.log("remove card ==>>", data);
    // setShowValidationmsg(false);
    setRemoveCardDetails(data);
    setRemoveCardPopup(true);
  };

  const deleteCard = () => {
    setRemoveCardPopup(false);
    dispatch(
      propertyUsersAction.getDeleteCardData({
        url: `admin/users/${uid}/card/` + removeCardDetails?.id,
        data: {},
        token: true,
      })
    );
  };

  const setDefaultCard = (data) => {
    console.log("setDefaultCard card ==>>", data);
    setDefaultCardmsg(false);
    dispatch(
      propertyUsersAction.getSetDefaultCardData({
        url: `admin/users/${uid}/card/` + data?.id,
        data: {},
        token: true,
      })
    );
  };

  const closeCardPopup = () => {
    setDefaultCardmsg(false);
    cardListAPI();
    // onClose();
    // dispatch(propertyUsersAction.clearDeleteCardData({}));
  };

  useEffect(() => {
    cardListAPI();
  }, []);

  useEffect(() => {
    console.log("removeCardData called==>", deleteCardData);
    if (getObjLength(deleteCardData)) {
      setShowValidationmsg(true);
      if (deleteCardData?.success) {
        cardListAPI();
      }
    }
  }, [deleteCardData]);

  useEffect(() => {
    console.log("profile data called==>", setDefaultCardData);
    if (getObjLength(setDefaultCardData)) {
      if (setDefaultCardData?.success) {
        cardListAPI();
      }

      setDefaultCardmsg(true);
    }
  }, [setDefaultCardData]);

  return (
    <>
      {/* <Button
        onClick={() => onClose()}
        className="modal-close-btn"
        style={{
          position: "absolute",
          right: 10,
          top: 15,
          background: "transparent",
        }}
      >
        <CloseIcon sx={{ color: "#9CB8A6" }} />
      </Button> */}
      <Paper elevation={0} className="payment-info mx-3 mt-2" sx={Paperstyles}>
        <Box
          className="d-flex align-items-center justify-content-between"
          // sx={{ mb: 3 }}
        >
          <Typography variant="h6" className="card-title">
            Manage card
          </Typography>
          <Button
            onClick={addCard}
            variant="h6"
            className="popup_Btn success_popup_btn "
          >
            Add card
          </Button>
        </Box>
        <hr />

        {Object.keys(cardListData)?.length > 0 &&
          cardListData?.success &&
          cardListData?.data?.map((data, key) => (
            <>
              {/* desktop view */}
              <Box className="pt-2 d-none d-sm-block" key={key}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6} lg={6} mb={1}>
                    <Box className="px-3 w-100 d-flex align-items-start">
                      {data.brand === "MasterCard" ? (
                        <img width={"10%"} src={MasterCard} alt="Master Card" />
                      ) : data.brand === "Visa" ? (
                        <img width={"10% "} src={VisaCard} alt="Visa Card" />
                      ) : data.brand === "American Express" ? (
                        <img
                          width={"10%"}
                          src={AmericanCard}
                          alt="American Card"
                        />
                      ) : data.brand === "Discover" ? (
                        <img
                          width={"10%"}
                          src={DiscoverCard}
                          alt="Discover Card"
                        />
                      ) : (
                        <CreditCardIcon fontSize="large" />
                      )}

                      <Typography
                        variant="caption"
                        className="visa-num ms-3 text-uppercase "
                      >
                        {data.brand} - **** {data.last4}
                        &nbsp;&nbsp;
                        {data.default === 1 && (
                          <Chip
                            sx={{ backgroundColor: "#46b14c33" }}
                            className="fw-600 text-success"
                            label="DEFAULT"
                          />
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Grid container>
                      <Grid
                        item
                        xs={8}
                        sm={6}
                        md={6}
                        lg={6}
                        className="d-flex justify-content-center align-items-center"
                      >
                        Expiry Date, {data.exp_month} {data.exp_year}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={6}
                        md={6}
                        lg={6}
                        className="text-end"
                      >
                        {data.default === 0 && (
                          <>
                            <Tooltip
                              title="Set as Default"
                              TransitionComponent={Zoom}
                              arrow
                            >
                              <IconButton
                                sx={{
                                  color: "#43D551",
                                }}
                                disabled={
                                  data?.id
                                    ? data?.exp_year <= new Date().getFullYear()
                                      ? getMonthFromString(
                                          data?.exp_month,
                                          data?.exp_year
                                        ) <
                                        new Date().getMonth() + 1
                                        ? true
                                        : false
                                      : false
                                    : true
                                }
                                onClick={() => setDefaultCard(data)}
                              >
                                <CheckCircleIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Remove card"
                              TransitionComponent={Zoom}
                              arrow
                            >
                              <IconButton
                                sx={{ color: "#FC2125" }}
                                onClick={() => removeCard(data)}
                                // disabled={isSecondaryUser}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {/* mobile view */}
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                className="d-flex d-sm-none"
              >
                <Stack direction="column">
                  <Box
                    sx={{
                      minWidth: data.default === 1 ? "200px" : "",
                    }}
                  >
                    {data.brand === "MasterCard" ? (
                      <img width={"15%"} src={MasterCard} alt="Master Card" />
                    ) : data.brand === "Visa" ? (
                      <img width={"15% "} src={VisaCard} alt="Visa Card" />
                    ) : data.brand === "American Express" ? (
                      <img
                        width={"15%"}
                        src={AmericanCard}
                        alt="American Card"
                      />
                    ) : data.brand === "Discover" ? (
                      <img
                        width={"15%"}
                        src={DiscoverCard}
                        alt="Discover Card"
                      />
                    ) : (
                      <CreditCardIcon fontSize="large" />
                    )}
                    <Typography
                      variant="caption"
                      className="visa-num ms-2 text-uppercase"
                      sx={{ mr: 1 }}
                    >
                      **** {data.last4}
                    </Typography>
                    {data.default === 1 && (
                      <Chip
                        sx={{
                          backgroundColor: "#46b14c33",
                          p: 0,
                        }}
                        className="fw-600 text-success"
                        label="DEFAULT"
                      />
                    )}
                  </Box>
                  <Typography>
                    Expiry Date, {data.exp_month} {data.exp_year}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  {data.default === 0 && (
                    <>
                      <Tooltip
                        title="Set as Default"
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <IconButton
                          sx={{ color: "#43D551" }}
                          onClick={() => setDefaultCard(data)}
                          disabled={
                            data?.id
                              ? data?.exp_year <= new Date().getFullYear()
                                ? getMonthFromString(
                                    data?.exp_month,
                                    data?.exp_year
                                  ) <
                                  new Date().getMonth() + 1
                                  ? true
                                  : false
                                : false
                              : true
                          }
                        >
                          <CheckCircleIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title="Remove card"
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <IconButton
                          sx={{ color: "#FC2125" }}
                          onClick={() => removeCard(data)}
                          //   disabled={isSecondaryUser}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Stack>
              </Stack>
              {key < cardListData?.data?.length - 1 && <hr />}
            </>
          ))}
        {!cardListData?.data?.length > 0 && (
          <Typography className="p-5 text-center" variant="h6">
            No Card Found. Please add the Credit card
          </Typography>
        )}
      </Paper>

      {/* Set Dafault card */}

      {defaultCardmsg &&
        (getObjLength(setDefaultCardData) && setDefaultCardData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={defaultCardmsg}
            onClose={true}
            onCloseFun={closeCardPopup}
            mesage={setDefaultCardData?.message}
            buttons={
              <Button
                className="popup_Btn success_popup_btn"
                onClick={closeCardPopup}
              >
                Ok, All Good
              </Button>
            }
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={defaultCardmsg}
            onClose={true}
            onCloseFun={() => setDefaultCardmsg(false)}
            mesage={setDefaultCardData?.data?.message}
            buttons={
              <Button
                className="popup_Btn error_popup_btn "
                onClick={() => setDefaultCardmsg(false)}
              >
                Ok, Close
              </Button>
            }
          />
        ))}

      {/* Remove Cards */}
      {showValidationmsg &&
        (getObjLength(deleteCardData) ? (
          <>
            {deleteCardData?.success && (
              <AlertDialog
                type="Success"
                openPopup={deleteCardData?.success}
                onClose={true}
                onCloseFun={() => setShowValidationmsg(false)}
                mesage={deleteCardData?.message}
                buttons={
                  <Button
                    className="popup_Btn success_popup_btn"
                    onClick={() => {
                      cardListAPI();
                      setShowValidationmsg(false);
                    }}
                  >
                    Ok, All Good
                  </Button>
                }
              />
            )}
            {!deleteCardData?.success && (
              <AlertDialog
                type="Error"
                openPopup={!deleteCardData?.success}
                onClose={true}
                onCloseFun={() => setShowValidationmsg(false)}
                mesage={deleteCardData?.data?.message}
                buttons={
                  <Button
                    className="popup_Btn error_popup_btn "
                    onClick={() => setShowValidationmsg(false)}
                  >
                    Try again
                  </Button>
                }
              />
            )}
          </>
        ) : null)}

      {removeCardPopup && (
        <>
          <AlertDialog
            type="Warning"
            openPopup={removeCardPopup}
            // setOpenPopup={setRemoveCardPopup}
            onCloseFun={() => setRemoveCardPopup(false)}
            onClose={true}
            loader={deleteCardLoader}
            mesage={" Are you sure, you want to delete this card?"}
            buttons={
              <>
                <Button
                  onClick={() => setRemoveCardPopup(false)}
                  className="popup_Btn other_popup_btn mx-2"
                  variant="outlined"
                >
                  Nope
                </Button>
                <Button
                  onClick={deleteCard}
                  className="popup_Btn success_popup_btn mx-2"
                  variant="outlined"
                  color="error"
                >
                  Yes
                </Button>
              </>
            }
          />
        </>
      )}
      <Loader open={deleteCardLoader || cardListLoader} />
    </>
  );
};

export default ManageCreditCard;
