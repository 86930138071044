import React, { useState } from "react";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import { Box, Typography } from "@mui/material";

import RealStoryDesign from "../common/RealStoryDesign";
import QuitClaimImg from "../../assets/SEO/QuitClaim.webp";
import HELOC_FraudImg from "../../assets/SEO/HELOC_Fraud.webp";
import MortgageImg from "../../assets/SEO/MortgageResponsive.webp";
import ForgedDeedImg from "../../assets/SEO/ForgedDeedResponsive.webp";
import LoseWalletImg from "../../assets/SEO/LoseWalletResponsive.webp";
import RealEstateImg from "../../assets/SEO/RealEstateScamResponsive.webp";
import ProactiveMonitorImg from "../../assets/SEO/ProactiveMonitorImg.webp";
import NewHomeownersImg from "../../assets/SEO/NewHomeownersResponsive.webp";
import TitleInsuranceImg from "../../assets/SEO/TitleInsuranceResponsive.webp";

export const blog_stories = [
  {
    image: RealEstateImg,
    title: `Common Real Estate Scams and How to Handle Them`,
    description: `The real estate industry can be lucrative, but it also
    comes with its fair share of scams and frauds. Homeowners should be aware of the common red flags in
    real estate transactions to protect themselves from falling victim to these schemes. Here are some of the
    most common real estate scams and how to handle them to keep your money and property safe.`,
    link: "/real-estate-scams",
  },
  {
    image: TitleInsuranceImg,
    title: `What is Title Insurance and What Does It Cover`,
    description: `Title insurance is an integral part of the home-buying
      process, providing homeowners with protection and peace of mind. But what exactly does title insurance cover?
      Knowing how title insurance works can help homeowners avoid unexpected complications. Let’s explore how this
      coverage ensures a smooth homeownership experience.`,
    link: "/does-title-insurance-cover-title-theft",
  },
  {
    image: MortgageImg,
    title: `Can Your Home Title Be Stolen If You Have a Mortgage`,
    description: `Homeownership is a significant investment and a source
      of pride for many individuals. However, there are potential risks associated with it, including the threat
      of title theft. Homeowners must understand these risks and take proactive steps to protect their most valuable
      assets. You should be aware of home title protection and the potential implications of home title theft with a
      mortgage.`,
    link: "/can-your-home-title-be-stolen-if-you-have-a-mortgage",
  },
  {
    image: QuitClaimImg,
    title: `Quitclaim Deed Fraud: What is it and how to prevent it`,
    description: `Quitclaim deeds are a common tool in real estate for transferring
    property interest, but they come with risks that can expose both parties to fraud. As quitclaim deed fraud becomes more prevalent,
    understanding how these documents work and how they can be misused is crucial for property owners and buyers. Learn the ins and outs
    of quitclaim deeds and how to prevent fraud when you use this type of real estate transfer.`,
    link: "/blog/quitclaim-deed-fraud",
  },
  {
    image: HELOC_FraudImg,
    title: `What is HELOC Fraud and ways to protect yourself`,
    description: `Homeowners face the risk of HELOC fraud, a type of mortgage scam
      that can drain home equity without the owner's knowledge. Understanding this threat is essential for anyone looking to
      protect their assets. Breaking down HELOC fraud, explaining how these scams operate, and offering strategies to safeguard your
      home equity can help. Learn the difference between a HELOC and a home equity loan, and discover how fraudsters use tactics like
      deed theft and identity theft.`,
    link: "/blog/heloc-fraud",
  },
  {
    image: ProactiveMonitorImg,
    title: `Proactive steps on how to monitor your home title`,
    description: `Protecting your home title from fraud is a necessary task that property owners must regularly complete. As one of your most
      valuable assets, your home faces potential risks from title fraud that can compromise security. Whether you’re a first-time
      homeowner or a seasoned property owner, you need to stay vigilant about your home title and protect your investment. Discover
      practical strategies for monitoring your home title, including using services like EquityProtect to effectively detect
      unauthorized activity and protect your property from fraudulent claims.`,
    link: "/blog/proactive-steps-monitor-home-title",
  },
  {
    image: ForgedDeedImg,
    title: `How to Protect Yourself from a Forged Deed`,
    description: `Deed fraud is a growing threat to homeowners, where criminals
      forge documents to claim property ownership illegally. This crime can result in severe financial and legal issues. Understanding
      deed fraud is necessary for anyone looking to protect their real estate investments. Discover practical strategies that work as a
      deed fraud guard, from setting up alerts to advanced protection measures. Whether you're experienced in real estate or just
      starting, these insights will help you safeguard your most valuable asset.`,
    link: "/blog/deed-fraud-protection",
  },
  {
    image: LoseWalletImg,
    title: `Steps to Take When You Lose Your Wallet`,
    description: `Losing your wallet can feel overwhelming, especially when it
      holds essentials like your ID, credit cards, and documents. If you're thinking, "What do I do? I lost my wallet," acting fast
      will help protect your finances and personal information. Whether it's a lost or stolen wallet, taking immediate steps can
      minimize risks like unauthorized transactions and identity theft.`,
    link: "/blog/steps-to-take-lost-wallet",
  },
  {
    image: NewHomeownersImg,
    title: `Title Theft Risks for New Homeowners`,
    description: `Safeguarding your investment from title theft is a priority for
      new homeowners. This type of fraud involves someone falsely claiming ownership of your property, which can result in
      substantial financial consequences.`,
    link: "/blog/title-protection-new-homeowners",
  },

  // Add more stories
];

const Blogs = () => {
  const [isSwiping, setIsSwiping] = useState(false);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    cssEase: "ease-in-out", // Smooth easing transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 4000, // Slide changes every 3 seconds
    pauseOnHover: true, // Pause when the user hovers over the slider
    pauseOnFocus: true, // Pause when the slider gains focus
    swipeToSlide: true, // Smooth swipe to slide
    draggable: true, // Smooth dragging
    beforeChange: () => {
      setIsSwiping(true);
    },
    afterChange: () => {
      setIsSwiping(false);
    },
    onSwipe: () => {
      setIsSwiping(false);
    },
    swipeEvent: () => {
      setIsSwiping(true);
    },
  };

  const readMoreText = (text, link) => (
    <>
      <p className="para_texts">
        {text.slice(0, 133)}
        {text.length > 130 ? "..." : ""}
      </p>
      <span
        onClick={(event) => {
          event.stopPropagation();
        }}
        //  className="read-or-hide"
      >
        <p className="read_more_blog">
          <Link
            to={link}
            //target="_blank"
          >
            {" Read More >"}
          </Link>
        </p>
      </span>
    </>
  );

  return (
    <div className="blogs-content">
      <div className="first_section_div">
        <section className="d-flex first-blog-section">
          <Container fluid className="text-white main_container">
            <Row className=" justify-content-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={8}
                className="header_texts"
              >
                <>
                  <h1 className="fw-700 mb-3 main-text text-center">Blog</h1>
                </>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="blog_title learn_ep_section">
          <Container fluid className="p-0 card_top blog_dots">
            <Slider {...settings}>
              {blog_stories.map((story, index) => (
                <Link to={story?.link} target="_blank">
                  <Row className="text-start blog_card">
                    <Col xs={12} sm={12} md={6} className="p-0">
                      <Box className="m-0 markgroup_img">
                        <img
                          src={story?.image}
                          className="h-100 w-100"
                          alt="Real Estate"
                        />
                      </Box>
                    </Col>
                    <Col xs={12} sm={12} md={6} className="">
                      <Box className="blog_content">
                        <>
                          <Typography className="title mb-4 d-flex text-start text-capitalize">
                            {story?.title}
                          </Typography>

                          {readMoreText(story?.description, story?.link)}
                        </>
                      </Box>
                    </Col>
                  </Row>
                </Link>
              ))}
            </Slider>
          </Container>
        </section>
      </div>

      <section className="story_section">
        <Container fluid className="mx-150">
          <div>
            <RealStoryDesign
              subTitle="hide"
              title="Other articles"
              IsGrid={true}
              readMoreClass="read_more_blog"
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Blogs;
