import React from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const FAQs_Data = [
  {
    que: `What is home title fraud, and how does it affect homeowners?`,
    ans: `Home title fraud occurs when someone illegally transfers your home
          ownership or places liens on your property without your knowledge.
          It can result in financial loss, legal complications, and months
          of stress to reverse the damage.`,
  },
  {
    que: `How does EquityProtect differ from other property title lock companies?`,
    ans: `Unlike most companies that only monitor your title for fraud, 
          EquityProtect actively protects it using a patented process 
          and blockchain technology. We don’t just notify you after 
          fraud occurs; we prevent it from happening.`,
  },
  {
    que: `What makes your protection service more reliable than standard title monitoring?`,
    ans: `Standard title monitoring only informs you after fraud happens, 
          but EquityProtect secures your title using biometric and 
          blockchain technology. We lock down your title, preventing 
          unauthorized transfers or liens.`,
  },
  {
    que: `How does EquityProtect use blockchain to secure my home title?`,
    ans: `Blockchain technology creates a secure, tamper-proof record 
          of your home ownership. This prevents unauthorized changes 
          and ensures that only legitimate transactions can be made.`,
  },
  {
    que: `What is the cost of EquityProtect's service compared to other providers?`,
    ans: `Other property title lock companies charge approximately $250 per year to JUST MONITOR. 
          In 5 years, you will have spent approximately $1,000 to simply be told that something 
          has or has not occurred on your property. EquityProtect is the only company that provides 
          assurance which provides blockchain protection to your home ownership. We truly protect you 
          from fraud and theft for far less. 5 years of protection will cost less than $680 with EquityProtect.`,
  },
  {
    que: `How quickly does EquityProtect detect and respond to potential fraud?`,
    ans: `EquityProtect monitors your title 24/7, immediately detecting, 
          responding, and preventing any attempts to tamper with your home title or equity.`,
  },
  {
    que: `Is my property monitored 24/7 with EquityProtect?`,
    ans: `Yes, EquityProtect provides round-the-clock protection for your 
          property, ensuring real-time monitoring and prevention of any fraudulent activity.`,
  },
  {
    que: `How does EquityProtect validate that I am the rightful owner of my property?`,
    ans: `We use multiple data aggregators to verify your ownership through challenge 
          questions that only you, as the rightful owner, can answer.`,
  },
  {
    que: `How does biometric protection work in your system?`,
    ans: `After setting up your account, we take a headshot using your computer 
          camera or mobile device. This biometric protection ensures that only 
          you can access your account and unlock your title.`,
  },
  {
    que: `Can I protect multiple properties with one EquityProtect account?`,
    ans: `Yes, one EquityProtect account can monitor and protect all of your properties.`,
  },
  {
    que: `What happens if someone tries to tamper with my home title while under EquityProtect's protection?`,
    ans: `If an attempt is made to tamper with your title, our system will block it, 
          preventing any unauthorized transfers or liens. You will also be alerted immediately.`,
  },
  {
    que: `How does EquityProtect's facial recognition system ensure security?`,
    ans: `Our facial recognition system ensures that only you, the verified homeowner, 
          can authorize changes to your title. This prevents unauthorized access to your property’s records.`,
  },
  {
    que: `What is the process for signing up with EquityProtect?`,
    ans: `The process involves creating an account, verifying your identity and ownership, 
          selecting a subscription plan, and setting up biometric protection, including a headshot and signature.`,
  },
  {
    que: `How does EquityProtect's "SmartPolicy" insurance coverage work?`,
    ans: `Our SmartPolicy covers ALL losses incurred during the time of enrollment PLUS 
          provides extra coverage when you decide to sell or refinance in the future. 
          Decreasing any future title products by up to 75%. Also allows lender to 
          access your SmartPolicy and get a near instant title commitment.`,
  },
  {
    que: `What are the differences between your subscription plans?`,
    ans: `There are two subscription plans. 
1. EquityProtect Monthly - $99.99 Set-up and Activation Fee + $19.99/month.
+ applicable sales tax, applicable Deposit for recording fee (One-time fee).

2. Equity Protect Yearly* - $279.99 for first Year + $99.99/ year per year for annual recertification. 
+ applicable sales tax, applicable Deposit for recording fee (One-time fee). 
*Savings of $59.88 for the first year and $139.98 every year after.

Both plans include 24/7 monitoring, SmartPolicy insuring your home ownership, access to your recorded property information at any time, Blockchain Protection of your property, and live support.`,
    preWrap: true,
  },
  {
    que: `What is included in the final step of the protection process with EquityProtect?`,
    ans: `The final step involves signing your name, which completes the setup and ensures 
          full protection of your title and equity under our system.`,
  },
  {
    que: `How long does it take to activate EquityProtect coverage?`,
    ans: `Once your account is set up and your ownership validated, coverage is activated 
          immediately. The entire process takes only a few minutes.`,
  },
  {
    que: `Does EquityProtect provide a free Risk Assessment?`,
    ans: `Yes! All you have to do is fill out the simple, short form on our website. Then, our system will locate your property and pull the most recent title data, and we'll send you your free report. This report will display the current vesting information on your title report. With over 1,100 data points meticulously analyzed, EquityProtect's Risk Assessment Report delivers unparalleled accuracy in evaluating the risk of equity theft and fraud.`,
  },
  {
    que: `What kind of customer support does EquityProtect offer?`,
    ans: `EquityProtect offers dedicated customer support to assist with setup, monitoring, 
          and any issues related to attempted title fraud or theft. Support is available 24/7.`,
  },
  {
    que: `Can I cancel my EquityProtect subscription at any time?`,
    ans: `Yes, you can cancel your subscription at any time, though we recommend maintaining 
          continuous coverage to ensure your title remains protected.`,
  },
  {
    que: `What specific types of fraud does EquityProtect protect against?`,
    ans: `EquityProtect safeguards against title fraud, equity theft, and unauthorized liens or transfers. 
          We protect you from any attempts to steal your home ownership or tamper with your title.`,
  },
  {
    que: `How does EquityProtect prevent fraudulent activity before it happens?`,
    ans: `We use a patented process involving blockchain security, facial recognition, 
          and continuous monitoring to lock down your title. This prevents unauthorized 
          access and stops fraudulent actions before they occur.`,
  },
  {
    que: `How does EquityProtect evaluate my risk?`,
    ans: `We cross-reference numerous data points, including ownership patterns, property values, 
          equity percentages, and property data changes to identify discrepancies or suspicious 
          activity that could signal potential fraud. Our sophisticated algorithms combine these 
          data points to generate a comprehensive risk score, highlighting the likelihood of equity 
          theft or fraud. This allows for targeted protection measures.`,
  },
  {
    que: `What happens if I sell my home while under EquityProtect’s protection?`,
    ans: `Once you sell your home, your protection continues until the sale is finalized. 
          You can then transfer your EquityProtect coverage to your new property with ease.`,
  },
  {
    que: `Does EquityProtect provide legal assistance in case of fraud?`,
    ans: `Yes, if your title is compromised, we offer full legal support to resolve the fraud, 
          including covering legal fees and working with you until the issue is completely rectified.`,
  },
  {
    que: `What could happen if I do not have title protection from EquityProtect?`,
    ans: `You could become a victim of property fraud and deed fraud – meaning your house may be stolen from you. 
          Criminals could then take out loans using your home as collateral. Your home could be listed for rent on popular 
          Classified Ad sites or vacation rental sites. And you could have years of stress (which could have been easily 
          prevented for only a few pennies a day with EquityProtect!)`,
  },
  {
    que: `How secure is the biometric data collected by EquityProtect?`,
    ans: `We take data security very seriously. All biometric data is encrypted and stored securely, 
          ensuring it is only used for verification purposes and protected against unauthorized access.`,
  },
  {
    que: `What should I do if I receive a fraud alert from EquityProtect?`,
    ans: `If you receive a fraud alert, follow the instructions provided in the alert. 
          Our team will work with you to verify the issue and take immediate action to stop any fraudulent activity.`,
  },
  {
    que: `Does EquityProtect cover commercial properties, or is it just for residential homes?`,
    ans: `EquityProtect primarily focuses on residential homes, but depending on the specific circumstances, 
          we may be able to extend coverage to certain types of commercial properties. Contact our team for more details.`,
  },
  {
    que: `How often does EquityProtect update its technology to stay ahead of emerging threats?`,
    ans: `We continuously update our technology to keep up with the latest cybersecurity trends and threats. 
          Our team works tirelessly to ensure that your title is protected against new and evolving forms of fraud.`,
  },
];

const FAQsAccordianDesign = () => {
  return (
    <Row className="justify-content-center faqs_row pb-3">
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="text-center">
          {/* <p className="notify_header">what are these?</p> */}
          <h3 className="sub_header mb-4">Questions & answers</h3>
          {/* <p className="subTitle mb-5"> 24/7 customer support included with all plans.</p> */}
        </div>
      </Col>
      <Col xs={12} sm={12} md={10} lg={10} xl={8}>
        <Accordion defaultActiveKey="4" id="supportFAQ_Wrapper">
          {FAQs_Data?.map((data, key) => {
            return (
              <Accordion.Item eventKey={key}>
                <Accordion.Header>{data?.que}</Accordion.Header>
                <Accordion.Body
                  className={`${data?.preWrap && "formatted-text"}`}
                >
                  {data?.ans}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Col>
    </Row>
  );
};

export default FAQsAccordianDesign;
