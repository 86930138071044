import axios from "axios";
import { toast } from "react-toastify";

import { BASE_URL, ROLES } from "../components/helpers/constants";
import {
  decryptPayload,
  windowsAndroidNativeMethods,
} from "../components/helpers/utils";

export const deleteAPIPayload = async (data) => {
  console.log("payload", data);
  let passingData = data.data === undefined ? {} : data.data;
  let device = decryptPayload(localStorage.getItem("isMobile"));

  // to identify logged in devices
  if (
    decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.customer) ||
    decryptPayload(localStorage.getItem("roles"))?.includes(
      ROLES.professional
    ) ||
    decryptPayload(localStorage.getItem("path"))?.includes("create-account")
  ) {
    if (device) {
      passingData = { ...passingData, device_type: device };
    } else {
      passingData = { ...passingData, device_type: "web" };
    }
  }

  return await deleteAPICall(data.url, passingData, data.token);
};

export const deleteAPICall = async (url, reqbody, token) => {
  return await axios
    .delete(`${BASE_URL}${url}`, {
      headers: {
        authorization: token
          ? `Bearer ${localStorage.getItem("authToken")}`
          : null,
      },
    })
    .then(function (response) {
      console.log("in GetPayload deleteRequest...", response.data);
      return response.data;
    })
    .catch(function (error) {
      console.error("Error in deleteAPIPayloaddeleteRequest", error);
      let data = {
        success: false,
        error: error.message,
        data: error.response?.data,
      };
      if (error.response?.status === 401) {
        const path = window.location.pathname;
        console.log("pathname delete ==>", path);
        if (path !== "/login" && !path?.includes("investors")) {
          localStorage.setItem("previousPath", path);
        }
        localStorage.removeItem("authToken");
        localStorage.removeItem("authorised");
        toast.error("Your token is expired or invalid. Please Login again.");

        // logout native method call for mobile app
        if (
          window?.webkit &&
          window?.webkit?.messageHandlers &&
          window?.webkit?.messageHandlers?.invokeNativeLogout
        ) {
          console.log("Logout Native App got called...");
          window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
        }
        // call Android App Logout method
        windowsAndroidNativeMethods("logout");

        window.location.replace("/login");
      }
      return data;
    });
};
