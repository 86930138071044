import React, { useState } from "react";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  PROPERTY_RISK_SUPPORT_PHONE,
  PROPERTY_RISK_SUPPORT_PHONE_FORMAT,
} from "../helpers/constants";
import { Form, Formik } from "formik";
import CustomTextField from "../common/CustomTextField";

const columns = [
  {
    id: "loandate",
    disablePadding: true,
    label: "Loan Date",
    minWidth: 110,
  },
  // {
  //   id: "Borrower Name(s)",
  //   disablePadding: true,
  //   label: "Borrower Name(s)",
  // },
  {
    id: "loanAmount",
    disablePadding: true,
    label: "Loan Amount",
    minWidth: 125,
  },
  {
    id: "loanType",
    disablePadding: true,
    label: "Loan Type",
    minWidth: 140,
  },
  {
    id: "lender",
    disablePadding: true,
    label: "Lender",
    minWidth: 100,
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sx={{ minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const validationSchema = () => {
  return Yup.object().shape({
    updated_loan_amount: Yup.string("Please enter the Loan Amount.")
      .required("Loan Amount is required")
      .max(15),
  });
};
const LoanProgram = ({
  propertyLoanAmountData,
  handleClose = () => {},
  generateReport = () => {},
}) => {
  const [isLoanAmountScreen, setIsLoanAmountScreen] = useState(false);

  const updateLoanAmount = (values) => {
    console.log("values: ", values);
    generateReport(parseFloat(values?.updated_loan_amount));
  };

  return (
    <>
      <Button
        onClick={() => handleClose()}
        className="modal-close-btn"
        style={{
          position: "absolute",
          right: 10,
          top: 8,
          background: "transparent",
        }}
      >
        <CloseIcon sx={{ color: "#9CB8A6" }} />
      </Button>
      <Box className="accountBoxWrapper">
        <Typography align="center" variant="h3" className="py-2 mb-1">
          {propertyLoanAmountData?.data?.address}
        </Typography>
        <Box className="mb-4">
          <Typography align="center" className="fw-400 para m-0">
            {isLoanAmountScreen ? (
              "The Risk report will be generated according to the loan amount you’ve updated here."
            ) : propertyLoanAmountData?.data?.finance_details?.length > 0 ? (
              <>
                Below are the loan details for this property. The risk report is
                being generated based on a loan amount of{" "}
                <b>${propertyLoanAmountData?.data?.loan_amount}</b>. If the loan
                amount is inaccurate, you may update it prior to generating the
                report.
              </>
            ) : null}
          </Typography>
        </Box>
        {isLoanAmountScreen ? (
          <div className="form-body">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                updated_loan_amount:
                  propertyLoanAmountData?.data?.loan_amount || "",
              }}
              validationSchema={validationSchema}
              onSubmit={updateLoanAmount}
            >
              {({ values, setFieldValue, touched, errors, isSubmitting }) => {
                return (
                  <Form>
                    <Grid
                      container
                      spacing={2}
                      textAlign={"center"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item xs={12} md={6}>
                        <CustomTextField
                          name="updated_loan_amount"
                          placeholder="Enter the Loan amount"
                          type="number"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue(
                                "updated_loan_amount",
                                event.target.value
                              );
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <div className="text-center stepBtn mt-3">
                      <Button
                        className="popup_Btn other_popup_btn m-1"
                        variant="contained"
                        onClick={() => setIsLoanAmountScreen(false)}
                      >
                        Back
                      </Button>
                      <Button
                        className="popup_Btn success_popup_btn m-1"
                        type="submit"
                        variant="contained"
                        //   onClick={loggedIn}
                      >
                        Generate Report
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        ) : (
          <>
            <Box>
              <TableContainer
                sx={{ maxHeight: 400 }}
                className="d-none d-sm-block"
              >
                <Table
                  sx={{ minWidth: 600 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <EnhancedTableHead
                    // onRequestSort={handleRequestSort}
                    rowCount={
                      propertyLoanAmountData?.success &&
                      propertyLoanAmountData?.data?.length
                    }
                    columns={columns}
                  />
                  <TableBody>
                    {propertyLoanAmountData?.success &&
                      propertyLoanAmountData?.data?.finance_details?.map(
                        (row, index) => {
                          return (
                            <TableRow tabIndex={-1} key={index}>
                              <TableCell align="left">
                                {row?.["Loan Date"] || "-"}
                              </TableCell>
                              {/* <TableCell align="left">
                              {ow?.owner_name || "-"}
                            </TableCell> */}
                              <TableCell align="left">
                                {row?.["Loan Amount"]
                                  ? `${row?.["Loan Amount"]}`
                                  : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {row?.["Loan Type"] || "-"}
                              </TableCell>
                              <TableCell align="left">
                                {row?.Lender || "-"}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
                {!propertyLoanAmountData?.data?.finance_details?.length > 0 && (
                  <Typography className="p-5 text-center" variant="h6">
                    Currently, we have not found any active loan associated with
                    this property in our records.
                  </Typography>
                )}
              </TableContainer>
            </Box>
            <Box>
              <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 pt-2 ">
                {propertyLoanAmountData?.data?.finance_details?.map(
                  (row, index) => {
                    return (
                      <>
                        <Stack
                          direction="row"
                          alignItems="baseline"
                          justifyContent={"space-between"}
                          spacing={0}
                          p={1.4}
                          pt={2}
                          mb={2}
                        >
                          <Stack direction="row" alignItems="baseline">
                            <div>
                              <Stack direction="column" px={1}>
                                <Typography
                                  className="key fw-500 pb-0"
                                  sx={{ fontSize: "12px !important" }}
                                >
                                  Loan Date
                                </Typography>
                                <Stack direction="column" spacing={0.5}>
                                  <Typography className="value">
                                    {row?.["Loan Recording Date"] || "-"}
                                  </Typography>
                                </Stack>

                                <Stack direction="row" className="">
                                  <>
                                    <Stack direction="column" spacing={0.5}>
                                      <Typography
                                        className="key fw-500"
                                        sx={{ fontSize: "12px !important" }}
                                      >
                                        Loan Amount
                                      </Typography>
                                      <Typography className="value">
                                        {row?.["Loan Amount"]
                                          ? `${row?.["Loan Amount"]}`
                                          : "-"}
                                      </Typography>
                                    </Stack>
                                  </>
                                  <>
                                    <Stack direction="column" spacing={0.5}>
                                      <Typography
                                        className="key fe-500"
                                        sx={{ fontSize: "12px !important" }}
                                      >
                                        Loan Type
                                      </Typography>
                                      <Typography className="value">
                                        {row?.["Loan Type"] || "-"}
                                      </Typography>
                                    </Stack>
                                  </>
                                </Stack>

                                <Stack direction="column">
                                  <Typography
                                    className="key fw-500"
                                    sx={{ fontSize: "12px !important" }}
                                  >
                                    Lender
                                  </Typography>
                                  <Stack direction="column" spacing={0.5}>
                                    <Typography className="value">
                                      {row?.Lender || "-"}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </div>
                          </Stack>
                        </Stack>

                        {index <
                          propertyLoanAmountData?.data?.finance_details
                            ?.length -
                            1 && (
                          <Divider
                            className="my-2 mt-3"
                            sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                          />
                        )}
                      </>
                    );
                  }
                )}

                {!propertyLoanAmountData?.data?.finance_details?.length > 0 && (
                  <Typography
                    className="d-block d-sm-none p-5 text-center"
                    variant="h6"
                  >
                    Currently, we have not found any active loan associated with
                    this property in our records.
                  </Typography>
                )}
              </Box>

              <Box className="pt-4">
                <Button
                  className="popup_Btn other_popup_btn m-1"
                  onClick={() => {
                    generateReport();
                  }}
                >
                  Generate Report
                </Button>
                <Button
                  className="popup_Btn success_popup_btn m-1"
                  onClick={() => setIsLoanAmountScreen(true)}
                >
                  Update Loan Amount
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {!isLoanAmountScreen && (
        <>
          <Divider />
          <Box className="mt-3 mx-5 mb-1">
            <Typography
              variant="body2"
              align="center"
              className="fw-500 para m-0"
            >
              If the loan reporting is not accurate, please contact customer
              service at{" "}
              <a href={`tel:${PROPERTY_RISK_SUPPORT_PHONE}`}>
                {PROPERTY_RISK_SUPPORT_PHONE_FORMAT}
              </a>{" "}
              as your previous lender did not file a release of lien.
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default LoanProgram;
