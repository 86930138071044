import React, { useState } from "react";

import { useSelector } from "react-redux";

import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Box, Tooltip, Zoom } from "@mui/material";
import { IconButton, Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import { PER_PAGE } from "../../helpers/constants";

const columns = [
  {
    key: 1,
    id: "no",
    disablePadding: true,
    label: "No.",
    minWidth: 50,
  },
  {
    key: 2,
    id: "name",
    disablePadding: true,
    label: "Name",
    minWidth: 115,
  },

  {
    key: 3,
    id: "email",
    disablePadding: true,
    label: "Email",
    sorting: true,
    minWidth: 80,
  },
  {
    key: 4,
    id: "phone",
    disablePadding: true,
    label: "Phone",
    sorting: true,
    minWidth: 80,
  },
  // {
  //   key: 5,
  //   id: "code",
  //   disablePadding: true,
  //   label: "Code",
  //   sorting: true,
  //   minWidth: 80,
  // },

  {
    key: 6,
    id: "url",
    disablePadding: true,
    label: "Referral Link",
    sorting: true,
    minWidth: 115,
  },
  // {
  //   key: 7,
  //   id: "setting",
  //   disablePadding: true,
  //   label: "Action",
  //   minWidth: 20,
  // },
];

const RepresentativeListTable = () => {
  const { repListData } = useSelector((state) => state.professionals);
  const [page, setPage] = useState(1);
  const [payload, setPayload] = useState({});

  const rowsData = repListData?.success ? repListData?.data : [];
  const count = Math.ceil(repListData?.count / PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  return (
    <Box sx={{ width: "100%" }} className="mt-2">
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            stickyHeader
          >
            <TableHeaderForSorting
              columns={columns}
              //   handleSorting={handleSorting}
              //   directionFlow={directionFlow}
              //   setSelectedColumn={setSelectedColumn}
              //   selectedColumn={selectedColumn}
            />
            <TableBody>
              {rowsData?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.address}
                  >
                    <TableCell align="left">
                      {(page - 1) * 10 + index + 1}
                    </TableCell>
                    <TableCell align="left" className="text-capitalize">
                      {row?.name || "-"}
                    </TableCell>
                    <TableCell align="left">{row?.email || "-"}</TableCell>
                    <TableCell align="left">{row?.phone || "-"}</TableCell>
                    {/* <TableCell align="left">
                      {row?.unique_code || "-"}
                    </TableCell> */}
                    <TableCell align="left">
                      <Tooltip
                        title={`Copy Referral Link`}
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                      >
                        <IconButton
                          sx={{
                            color: "#0395FF",
                            fontSize: "1.4rem",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(row?.referral_url);
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {!rowsData.length > 0 && (
            <Typography className="p-5 text-center" variant="h6">
              No Data Found.
            </Typography>
          )}
        </TableContainer>
      </Paper>

      {repListData?.count > PER_PAGE && (
        <Box id="pagination-container">
          <CustomPagination
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default RepresentativeListTable;
