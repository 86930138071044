import { SUBDOMAIN } from "../components/helpers/constants";

const setMeta = (title, description) => {
  // Helper function to set or create meta tags
  const updateMetaTag = (name, content) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`);
    if (metaTag) {
      metaTag.setAttribute("content", content);
    } else {
      metaTag = document.createElement("meta");
      metaTag.name = name;
      metaTag.content = content;
      document.head.appendChild(metaTag);
    }
  };

  // Update title and description for all specified meta tags
  updateMetaTag("title", title);
  document.title = title;
  updateMetaTag("description", description);
  updateMetaTag("og:title", title);
  updateMetaTag("og:description", description);
  updateMetaTag("twitter:title", title);
  updateMetaTag("twitter:description", description);
};

export const setMetaDataForSEO = (pathname, domainName) => {
  let schemaData = null;
  switch (pathname) {
    case "/":
      setMeta(
        domainName?.includes(SUBDOMAIN?.provider)
          ? "Home Equity Protection by EquityProtect"
          : "EquityProtect | Home Equity Protection from Title Fraud",
        domainName?.includes(SUBDOMAIN?.provider)
          ? "Protect your home equity with EquityProtect. Combat the biggest threat to your financial future and safeguard your most valuable asset with expert solutions."
          : "Protect your home equity with EquityProtect. Our solutions help secure your financial future by safeguarding your most valuable asset. Get expert guidance today."
      );
      break;
    case "/how-it-works":
      setMeta(
        "How Home Equity Protection Works | EquityProtect",
        "Learn how EquityProtect secures your home equity with tailored protection plans. Understand the process and safeguard your financial future with trusted solutions."
      );
      break;
    case "/pricing":
      setMeta(
        "Home Equity Protection Pricing | EquityProtect",
        "Find competitive pricing for home equity protection plans at EquityProtect. Protect your home's value with affordable solutions designed to secure your financial future."
      );
      break;
    case "/create-account":
      setMeta(
        "Create Account | EquityProtect",
        "Create your account with EquityProtect to securely protect your home equity and gain access to advanced tools and services. Get started today!"
      );
      break;
    case "/property-risk-management":
      setMeta(
        "Property Risk Management Services | EquityProtect",
        "EquityProtect offers expert property risk management services to help you protect your home equity. Take control of your investments with our reliable risk management solutions."
      );
      break;
    case "/title-protection-real-estate-investors":
      setMeta(
        "Title Protection for Real Estate Investors | EquityProtect",
        "Are your real estate investments at risk of title fraud? Learn how to protect your properties from title theft with EquityProtect. Get a free risk assessment now."
      );
      break;
    case "/title-protection-long-time-homeowners":
      setMeta(
        "Home Title Protection for Long-Time Homeowners | EquityProtect",
        "Protect your home from title theft. Learn about the title theft risks long-time homeowners face in the digital age. Safeguard your title & equity with EquityProtect."
      );
      break;
    case "/what-is-home-title-theft":
      setMeta(
        "Signs of Home Title Theft & How to Protect Yourself | EquityProtect",
        "Is your home at risk of fraud? Discover the growing threat of home title theft and how to safeguard your biggest investment with EquityProtect. Learn more."
      );
      break;

    case "/for-service-provider":
      setMeta(
        "Home Equity Protection for Service Providers | EquityProtect",
        "Safeguard your clients' home equity with EquityProtect. Provide reliable, tailored protection plans designed to secure ownership & prevent unauthorized access."
      );
      break;

    case "/gallagher":
      setMeta(
        "Mike Gallagher Recommends EquityProtect | EquityProtect",
        "Discover if your property is at risk of title theft with the home equity protection solution recommended by Mike Gallagher. Get a free risk assessment now."
      );
      break;

    case "/gorka":
      setMeta(
        "Dr. Sebastian Gorka Recommends EquityProtect | EquityProtect",
        "Find out if your property is vulnerable to title theft with the home equity protection trusted by Dr. Sebastian Gorka. Get your free risk assessment today."
      );
      break;

    case "/real-estate-scams":
      setMeta(
        "Common Real Estate Scams & How to Handle Them | EquityProtect",
        "Learn how to identify real estate red flags & common types of real estate fraud. Learn how to proactively protect your home title with EquityProtect."
      );
      break;

    case "/does-title-insurance-cover-title-theft":
      setMeta(
        "What Is Title Insurance & What Does It Cover? | EquityProtect",
        "Learn what title insurance is, what it covers, how it differs from title lock & why it’s vital for protecting your home ownership. Get peace of mind with EquityProtect."
      );
      break;

    case "/can-your-home-title-be-stolen-if-you-have-a-mortgage":
      setMeta(
        "Can Your Home Title Be Stolen If You Have a Mortgage | EquityProtect",
        "Does a mortgage protect you from home title theft? Learn how EquityProtect prevents thieves from selling your house or property without your knowledge."
      );
      break;

    case "/blog/quitclaim-deed-fraud":
      setMeta(
        "Quitclaim Deed Fraud: What Is It & Ways to Prevent It | EquityProtect",
        "Understand the vulnerabilities of quitclaim deeds, recognize fraudulent practices, and protect your property rights with expert tips and proactive measures."
      );
      schemaData = {
        "@context": "https://schema.org",
        "@type": "Article",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": "https://www.equityprotect.com/blog/quitclaim-deed-fraud",
        },
        headline: "Quitclaim Deed Fraud: What Is It & Ways to Prevent It",
        description:
          "Quitclaim deeds are a common tool in real estate for transferring property interest, but they come with risks that can expose both parties to fraud. As quitclaim deed fraud becomes more prevalent, understanding how these documents work and how they can be misused is crucial for property owners and buyers. Learn the ins and outs of quitclaim deeds and how to prevent fraud when you use this type of real estate transfer.",
        image:
          "https://www.equityprotect.com/static/media/RecognizingQuitclaim.d81905598c31173b0bc9.webp",
        author: {
          "@type": "",
          name: "EquityProtect",
          url: "https://www.equityprotect.com/",
        },
        publisher: {
          "@type": "Organization",
          name: "EquityProtect",
          logo: {
            "@type": "ImageObject",
            url: "https://www.equityprotect.com/static/media/equity.425de9cf932b9969560b.WebP",
          },
        },
        datePublished: "2024-10-19",
        dateModified: "2025-01-15",
      };

      break;

    case "/blog/heloc-fraud":
      setMeta(
        "What Is HELOC Fraud & Ways to Protect Yourself | EquityProtect",
        "Learn about HELOC fraud, how it occurs, & how to protect yourself from it. Safeguard your home’s equity with these essential tips from EquityProtect."
      );
      schemaData = {
        "@context": "https://schema.org",
        "@type": "Article",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": "https://www.equityprotect.com/blog/heloc-fraud",
        },
        headline: "What Is HELOC Fraud & Ways to Protect Yourself",
        description:
          "Homeowners face the risk of HELOC fraud, a type of mortgage scam that can drain home equity without the owner's knowledge. Understanding this threat is essential for anyone looking to protect their assets. Breaking down HELOC fraud, explaining how these scams operate, and offering strategies to safeguard your home equity can help. Learn the difference between a HELOC and a home equity loan, and discover how fraudsters use tactics like deed theft and identity theft.",
        image:
          "https://www.equityprotect.com/static/media/Stop_HELOC_Fraud.2673c83c7dbd250b9f4d.webp",
        author: {
          "@type": "Organization",
          name: "EquityProtect",
          url: "https://www.equityprotect.com/",
        },
        publisher: {
          "@type": "Organization",
          name: "EquityProtect",
          logo: {
            "@type": "ImageObject",
            url: "https://www.equityprotect.com/static/media/equity.425de9cf932b9969560b.WebP",
          },
        },
        datePublished: "2024-10-19",
        dateModified: "2025-01-15",
      };

      break;

    case "/blog/proactive-steps-monitor-home-title":
      setMeta(
        "Proactive Steps on How to Monitor Your Home Title | EquityProtect",
        "Learn how to monitor your home title & safeguard your credit with tips from EquityProtect including how to monitor reports and check property information."
      );
      schemaData = {
        "@context": "https://schema.org",
        "@type": "Article",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id":
            "https://www.equityprotect.com/blog/proactive-steps-monitor-home-title",
        },
        headline: "Proactive Steps on How to Monitor Your Home Title",
        description:
          "Protecting your home title from fraud is a necessary task that property owners must regularly complete. As one of your most valuable assets, your home faces potential risks from title fraud that can compromise security. Whether you’re a first-time homeowner or a seasoned property owner, you need to stay vigilant about your home title and protect your investment. Discover practical strategies for monitoring your home title, including using services like EquityProtect to effectively detect unauthorized activity and protect your property from fraudulent claims.",
        image:
          "https://www.equityprotect.com/static/media/ResourcesFromCounty.ac0eabdab9f60694f188.webp",
        author: {
          "@type": "Organization",
          name: "EquityProtect",
          url: "https://www.equityprotect.com/",
        },
        publisher: {
          "@type": "Organization",
          name: "EquityProtect",
          logo: {
            "@type": "ImageObject",
            url: "https://www.equityprotect.com/static/media/equity.425de9cf932b9969560b.WebP",
          },
        },
        datePublished: "2024-10-19",
        dateModified: "2025-01-15",
      };
      break;

    case "/blog/deed-fraud-protection":
      setMeta(
        "How to Protect Yourself from a Forged Deed | EquityProtect",
        "Discover what a forged deed is and how to protect yourself from deed fraud. Protect your home’s title & equity with these essential tips from EquityProtect."
      );
      break;

    case "/blog/steps-to-take-lost-wallet":
      setMeta(
        "Steps to Take When You Lose Your Wallet | EquityProtect",
        "Find crucial steps to take if your identity or documents are stolen, & learn how to protect your home’s title from potential theft with tips from EquityProtect."
      );
      break;

    case "/blog/title-protection-new-homeowners":
      setMeta(
        "Title Protection for New Homeowners | EquityProtect",
        "New homeowners are at risk of title fraud, even with title insurance. Protect your new title and growing equity with EquityProtect's patented system."
      );
      break;

    default:
      setMeta(
        "Home Lock - EquityProtect",
        "The First and Only True Property Title Lock - Prevents Home Stealing, Title Theft and Equity Protection"
      );
  }
  return schemaData;
};
