import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";

import CheckoutForm from "./CheckoutForm";
import { Loader } from "../../common/loader";
import { getObjLength, user_signup_details } from "../../helpers/utils";
import GetSubscriptionPlan from "./getSubscriptionPlan";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import InvestorsAnnaulSubscriptionPlan from "../../common/investorsAnnaulSubscriptionPlan";

export default function CreditCardForm({
  onBtnClick,
  resumeCount,
  errorPopup,
  reduceErrorCount,
  investor_id = null,
}) {
  const [open, setOpen] = useState(false);
  const [getPlansScreen, setGetPlansScreen] = useState(false);
  const [yearlyPlan, setYearlyPlan] = useState();
  const [paymetData, setPaymetData] = useState({});
  const [planDetails, setPlanDetails] = useState({});
  const dispatch = useDispatch();

  const {
    createAccResponse,
    mailingAddress,
    statesCodeList,
    statesLoader,
    subscriptionPlans,
    isPaymentDone,
  } = useSelector((state) => state.signUpUser);
  const { countyData } = useSelector((state) => state?.propertyRiskManagement);

  console.log("mailingAddress==>> ", mailingAddress);
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  const subscriptionScreen = JSON.parse(
    localStorage.getItem("subscriptionScreen")
  );
  const propetiesDetails = localStorage.getItem("propetiesDetails");
  const promoCode = localStorage.getItem("promoCode") || undefined;

  useEffect(() => {
    if (
      !getObjLength(createAccResponse) &&
      resumeCount > 0 &&
      resumeCount === 3
    ) {
      console.log("resume the flow...");
      let data = {
        user_id: userIdFromSession,
        step: 2,
        step_key: "GET_MAILING_ADDRESS",
      };
      dispatch(
        userSigupAction.getMailingAddress({
          url: "get-mailing-address",
          data: data,
        })
      );
    }
    let uId = getObjLength(createAccResponse)
      ? createAccResponse.data?.user_id
      : userIdFromSession;
    dispatch(
      userSigupAction.getIsPaymentDone({
        url: "payment-status/" + uId,
        data: {},
      })
    );
    dispatch(
      userSigupAction.getStateCode({ url: "get-states-code", data: {} })
    );
    if (getObjLength(countyData)) {
      if (countyData?.success) {
        dispatch(
          userSigupAction.getSubscriptionPlan({
            url: `county/${countyData?.data?.id}/get-plans`,
            data: {},
            type: "post",
          })
        );
      } else {
        dispatch(
          userSigupAction.getSubscriptionPlan({
            url: "get-plans",
            data: {},
            type: "post",
          })
        );
      }
    }
    handleToggle();
  }, []);
  useEffect(() => {
    if (getObjLength(subscriptionPlans)) {
      handleClose();
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    console.log("isPaymentDone ==>", isPaymentDone);
    if (getObjLength(isPaymentDone)) {
      if (isPaymentDone?.data?.payment_status === "success") {
        setGetPlansScreen(true);
      }
    }
  }, [isPaymentDone]);

  const handleClose = () => {
    if (subscriptionScreen === 0) {
      let plan = subscriptionPlans?.data?.find((i) => i.name === "Yearly");
      setYearlyPlan(plan);
      console.log("Yearly plan=>", plan);
      if (isPaymentDone?.data?.payment_status !== "success") {
        setGetPlansScreen(false);
      }
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleGetPlansScreen = (data, planData) => {
    setPaymetData(data);
    setPlanDetails(planData);
    setGetPlansScreen(true);
  };
  const investorsGetPlansScreen = (planData) => {
    //fo fb meta data
    let metadata = {};
    if (propetiesDetails) {
      metadata = {
        ...JSON.parse(propetiesDetails),
        plan_name: planDetails?.name,
        plan_id: planData?.plan_id,
        email: user_signup_details()?.email,
      };
    } else {
      metadata = {
        plan_name: planDetails?.name,
        email: user_signup_details()?.email,
        plan_id: planData?.plan_is,
      };
    }
    console.log("metadata==>>", metadata);
    localStorage.setItem("PlanDetails", JSON.stringify(metadata));

    let data = {
      user_id: getObjLength(createAccResponse)
        ? createAccResponse.data?.user_id
        : userIdFromSession,
      plan: planData?.plan_id,
    };
    dispatch(userSigupAction.addNewProperty({}));
    handleGetPlansScreen(data, planData);
  };
  console.log(paymetData, "plan=>", yearlyPlan);

  // defualt scroll bottom to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [getPlansScreen]);

  return (
    <>
      {getPlansScreen ? (
        <>
          <Box className="accountBoxWrapper">
            <Typography align="center" variant="h3">
              Credit Card details
            </Typography>

            {statesCodeList ? (
              // && Object.keys(mailingAddress).length > 0
              //<Elements stripe={stripePromise}>
              <CheckoutForm
                mailingAddress={mailingAddress?.data?.address}
                mailingData={mailingAddress?.data}
                stateCodesData={statesCodeList}
                onBtnClick={onBtnClick}
                planDetails={planDetails}
                paymetData={paymetData}
                subscriptionPlans={subscriptionPlans}
                reduceErrorCount={reduceErrorCount}
                errorPopup={errorPopup}
                goBackOnSubscription={setGetPlansScreen}
                investor_id={investor_id}
                // handleGetPlansScreen={handleGetPlansScreen}
              />
            ) : (
              //</Elements>
              <Loader open={statesLoader} />
            )}
          </Box>
        </>
      ) : !investor_id ? (
        <Box className="accountBoxWrapper">
          <Typography align="center" variant="h3">
            Choose Subscription Plan
          </Typography>
          {getObjLength(subscriptionPlans) && getObjLength(yearlyPlan) ? (
            <GetSubscriptionPlan
              subscriptionPlans={subscriptionPlans}
              onBtnClick={onBtnClick}
              yearlyPlan={yearlyPlan}
              handleGetPlansScreen={handleGetPlansScreen}
            />
          ) : (
            <Loader open={true} />
          )}
        </Box>
      ) : (
        <Box className="">
          {getObjLength(subscriptionPlans) && getObjLength(yearlyPlan) ? (
            <InvestorsAnnaulSubscriptionPlan
              uid={userIdFromSession}
              paySecure={investorsGetPlansScreen}
              promoCode={promoCode}
            />
          ) : (
            <Loader open={true} />
          )}
        </Box>
      )}
      <Loader open={open} />
    </>
  );
}
