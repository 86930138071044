import React, { useState } from "react";

import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";

import { Box, Typography } from "@mui/material";

import news1 from "../../assets/articles/news1.webp";
import news2 from "../../assets/articles/news2.webp";
import news3 from "../../assets/articles/news3.webp";
import news4 from "../../assets/articles/news4.webp";
import news5 from "../../assets/articles/news5.webp";
import news6 from "../../assets/articles/news6.webp";
import news7 from "../../assets/articles/news7.webp";
import news8 from "../../assets/articles/news8.webp";
import news9 from "../../assets/articles/news9.webp";
import news10 from "../../assets/articles/news10.webp";
import news11 from "../../assets/articles/news11.webp";
import news12 from "../../assets/articles/news12.webp";
import news13 from "../../assets/articles/news13.webp";
import news14 from "../../assets/articles/news14.webp";
import news15 from "../../assets/articles/news15.webp";
import news16 from "../../assets/articles/news16.webp";
import news17 from "../../assets/articles/news17.webp";
import RealStoryDesign from "../common/RealStoryDesign";

export const ep_News = [
  {
    image: news1,
    type: "IN THE NEWS",
    title: "Property Fraud prevention service available",
    subTitle: "Benton Review",
    date: "February 19, 2025",
    description: `Benton County’s Recorder Office will now offer EquityProtect to offer homeowners a free risk assessment.`,
    link: `https://www.bentonreview.com/articles/property-fraud-prevention-service-available/`,
  },
  {
    image: news2,
    type: "AWARD",
    title: "EquityProtect named HousingWire Tech 100 winner",
    subTitle: "HousingWire",
    date: "February 3, 2025",
    description: `The company’s technology addresses critical issues such as deed fraud, equity skimming, elder abuse and mortgage fraud.`,
    link: `https://www.housingwire.com/company-profile/2025-tech100-winner-equityprotect-com/`,
  },
  {
    image: news3,
    type: "IN THE NEWS",
    title: "Wildfire-related forbearance bill introduced in California",
    subTitle: "National Mortgage News",
    date: "January 29, 2025",
    description: `EquityProtect is working with Pacific Coast Title, an agency in California to offer free protection for those affected by the wildfires from title theft.`,
    link: `https://www.nationalmortgagenews.com/news/wildfire-related-forbearance-bill-introduced-in-california`,
  },
  {
    image: news4,
    type: "National Mortgage News",
    title:
      "EquityProtect and Pacific Coast Title Join Forces for Homeowner Security",
    subTitle: "Investors Hangout",
    date: "January 28, 2025",
    description: `In the wake of natural disasters, homeowners are most vulnerable to real estate fraud as cyberscammers seek to take advantage of the chaos.`,
    link: `https://investorshangout.com/equityprotect-and-pacific-coast-title-join-forces-for-homeowner-security-196779-/`,
  },
  {
    image: news5,
    type: "PRESS RELEASE",
    title:
      "EquityProtect Partners With Pacific Coast Title to Offer California Wildfire Victims Free Protection From Real Estate Fraud",
    subTitle: null,
    date: "January 28, 2025",
    description: `In the wake of natural disasters, homeowners are most vulnerable to real estate fraud as cyberscammers seek to take advantage of the chaos.`,
    link: `https://www.businesswire.com/news/home/20250128721260/en/EquityProtect-Partners-With-Pacific-Coast-Title-to-Offer-California-Wildfire-Victims-Free-Protection-From-Real-Estate-Fraud`,
  },

  {
    image: news6,
    type: "IN THE NEWS",
    title: "Georgia police warn against fraudulent quit claim scam",
    subTitle: "The Legal Description",
    date: "January 20, 2025",
    description: `Jon Dovidio, vice president of EquityProtect, said it remains vital for the public to remain on alert and practice due diligence.`,
    link: `https://www.thelegaldescription.com/tld/articlestld/georgia-police-warn-against-fraudulent-quit-claim-93450.aspx`,
  },
  {
    image: news7,
    type: "PODCAST",
    title:
      "EquityProtect’s Jon Dovidio talks title fraud on The Chrisman Commentary",
    subTitle: null,
    date: "January 1, 2025",
    description: `Jon Dovidio talks to Robbie Chrisman, host of the mortgage industry’s leading podcast The Chrisman Commentary, about how homeowners can protect themselves from deed fraud.`,
    link: `https://www.iheart.com/podcast/269-chrisman-commentary-daily-77103160/episode/12125-trump-administration-equityprotects-jon-dovidio-259932100/`,
  },
  {
    image: news8,
    type: "PODCAST",
    title:
      "EquityProtect President Jim Adams talks to the Radio Realtors about title theft",
    subTitle: null,
    date: "January 1, 2025",
    description: `Jim Adams shares how homeowners can safeguard their most valuable asset and ensure their home’s equity is secure.`,
    link: `https://theradiorealtors.podbean.com/e/%F0%9F%94%8A%F0%9F%94anexclusiveinterviewwithjim-adamscofounder-and-president-ofequity-protect%F0%9F%A6%B9%E2%80%8D%E2%99%82%EF%B8%8F%F0%9F%8F/`,
  },
  {
    image: news9,
    type: "PRESS RELEASE",
    title:
      "EquityProtect Receives Patents on Property Ownership Authentication System",
    subTitle: null,
    date: "October 21, 2024",
    description: `Real estate fraud prevention and data security provider EquityProtect was granted patents for its multi-computer processing technology designed to protect data from unauthorized modification.`,
    link: `https://wrenews.com/equity-protect-receives-patents-on-property-ownership-authentication-system/`,
  },
  {
    image: news10,
    type: "PRESS RELEASE",
    title: "EquityProtect Announces Strategic Partnership with RE/MAX Gold",
    subTitle: null,
    date: "October 4, 2024",
    description: `The collaboration is set to offer RE/MAX Gold client's exclusive discounted access to EquityProtect's innovative home equity protection product, ensuring comprehensive financial security for homeowners.`,
    link: `https://www.prlog.org/13041866-equityprotect-announces-strategic-partnership-with-remax-gold.html`,
  },
  {
    image: news11,
    type: "IN THE NEWS",
    title:
      "County Recorder’s Office Partners With Company On Home Fraud Prevention",
    subTitle: "Times Union Online",
    date: "June 18, 2024",
    description: `Kosciusko County announces partnership with Equity Protect in an effort to take a pro-active approach to property fraud.`,
    link: `https://timesuniononline.com/news/2024/jun/18/county-recorders-office-partners-with-company-on-home-fraud-prevention/`,
  },
  {
    image: news12,
    type: "IN THE NEWS",
    title:
      "County officials have approved a property fraud measure to help home and land owners",
    subTitle: "Daily Reporter",
    date: "June 7, 2024",
    description: `Hancock County Recorder’s Office partners with EquityProtect to give property owners the opportunity to obtain a free report showing their risk level for property fraud.`,
    link: `https://www.greenfieldreporter.com/2024/06/07/county-officials-have-approved-a-property-fraud-measure-to-help-home-and-land-owners/`,
  },
  {
    image: news13,
    type: "IN THE NEWS",
    title: "Companies team up to combat property fraud",
    subTitle: "The Title Report",
    date: "April 5, 2024",
    description: `Notarized.com and title technology provider EquityProtect are teaming up on a host of initiatives to combat property fraud, the companies recently announced.`,
    link: `https://www.thetitlereport.com/articles/companies-team-up-to-combat-property-fraud-91036.aspx`,
  },
  {
    image: news14,
    type: "PRESS RELEASE",
    title:
      "Notarized.com Takes Proactive Steps to Combat Property Fraud Through Education and Prevention",
    subTitle: null,
    date: "April 3, 2024",
    description: `By partnering with EquityProtect, a pioneer in innovative title fraud prevention technologies, Notarized.com empowers property owners and real estate professionals to assess transaction risks and implement preventive measures effectively.`,
    link: `https://www.prlog.org/13014579-notarizedcom-takes-proactive-steps-to-combat-property-fraud-through-education-and-prevention.html`,
  },
  {
    image: news15,
    type: "IN THE NEWS",
    title:
      "EquityProtect CEO speaks on launch of property risk assessment tool",
    subTitle: "The Title Report",
    date: "March 28, 2024",
    description: `Cybersecurity fintech provider EquityProtect is now offering comprehensive data on various properties’ vulnerabilities when it comes to title fraud and other financial crimes.`,
    link: `https://www.thetitlereport.com/Articles/EquityProtect-CEO-speaks-on-launch-of-property-ris-90977.aspx`,
  },
  {
    image: news16,
    type: "PRESS RELEASE",
    title: `EquityProtect Revolutionizes Property Protection with Launch of "EstateGuard Evaluation Report"`,
    subTitle: null,
    date: "March 25, 2024",
    description: `EquityProtect proudly announces the groundbreaking launch of the "EstateGuard Evaluation Report," marking a significant milestone in property risk assessment.`,
    link: `https://www.prlog.org/13013166-equityprotect-revolutionizes-property-protection-with-launch-of-estateguard-evaluation-report.html`,
  },
  {
    image: news17,
    type: "IN THE NEWS",
    title: "EquityProtect CEO talks M&A, hunting elk and solving deed fraud",
    subTitle: "HousingWire",
    date: "February 1, 2024",
    description: `Ryan Marshall sits down with HW Media Publisher Clayton Collins to talk about how he is using cutting-edge tech to eliminate title theft.`,
    link: `https://www.housingwire.com/videos/equityprotect-ceo-talks-ma-hunting-elk-and-solving-deed-fraud/`,
  },
];

const EPNews = () => {
  const [isSwiping, setIsSwiping] = useState(false);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    cssEase: "ease-in-out", // Smooth easing transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 4000, // Slide changes every 3 seconds
    pauseOnHover: true, // Pause when the user hovers over the slider
    pauseOnFocus: true, // Pause when the slider gains focus
    swipeToSlide: true, // Smooth swipe to slide
    draggable: true, // Smooth dragging
    beforeChange: () => {
      setIsSwiping(true);
    },
    afterChange: () => {
      setIsSwiping(false);
    },
    onSwipe: () => {
      setIsSwiping(false);
    },
    swipeEvent: () => {
      setIsSwiping(true);
    },
  };

  const readMoreText = (text, link) => (
    <>
      <p className="para_texts news_para_texts">
        {text.slice(0, 133)}
        {text.length > 130 ? "..." : ""}
      </p>
      <span
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <p className="read_more_blog">
          <a href={link} target="_blank" rel="noreferrer">
            {" Read More >"}
          </a>
        </p>
      </span>
    </>
  );

  return (
    <div className="blogs-content">
      <div className="first_section_div">
        <section className="d-flex first-blog-section">
          <Container fluid className="text-white main_container">
            <Row className=" justify-content-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={8}
                className="header_texts"
              >
                <>
                  <h1 className="fw-700 mb-3 main-text text-center">
                    EquityProtect in the News
                  </h1>
                </>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="blog_title learn_ep_section">
          <Container fluid className="p-0 card_top new_card_top">
            <Slider {...settings}>
              {ep_News.map((story, index) => (
                <a href={story?.link} target="_blank" rel="noreferrer">
                  <Row className="text-start news_card">
                    <Col xs={12} sm={12} md={12} lg={6} className="p-0">
                      <Box className="m-0 markgroup_img">
                        <img
                          src={story?.image}
                          className="h-100 w-100"
                          alt="Real Estate"
                        />
                      </Box>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} className="">
                      <Box className="news_content">
                        <>
                          <p className="notify_header">
                            {story?.type} | {story?.date}
                          </p>
                          {story?.subTitle && (
                            <p className="news_subTitle mb-0">
                              {story?.subTitle}
                            </p>
                          )}
                          <Typography className="news_title_texts title mb-4 d-flex text-start text-capitalize">
                            {story?.title}
                          </Typography>

                          {readMoreText(story?.description, story?.link)}
                        </>
                      </Box>
                    </Col>
                  </Row>
                </a>
              ))}
            </Slider>
          </Container>
        </section>
      </div>

      <section className="story_section">
        <Container fluid className="mx-150">
          <div>
            <RealStoryDesign
              subTitle="hide"
              title="Other articles"
              IsGrid={true}
              readMoreClass="read_more_blog"
              customData={ep_News}
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default EPNews;
