import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Box, FormHelperText, Stack } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import TotalPyamentPopup from "./TotalPyamentPopup";
import SupportEmailLink from "../../common/SupportEmailLink";
import SecurePaymentPanel from "../../common/SecurePaymentPanel";
import Aurthorised from "../../../assets/price_F&Q/aurthorize.png";
import ContcatAdminSupport from "../../common/ContcatAdminSupport";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";
import {
  currencyFormatter,
  decryptPayload,
  getObjLength,
  reactPixelEvent,
} from "../../helpers/utils";
import { subscriptionAction } from "../../../redux/slices/subscription/subscriptionSlice";
import {
  LOCAL_RECORDING_FEES,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../../helpers/constants";

const validationSchema = Yup.object().shape({
  first_name: Yup.string("Please enter your First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),
  last_name: Yup.string("Please enter your Last name.")
    .trim("The Last name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name is required"),
  address: Yup.string("Please enter your Address.")
    .strict(true)
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("Address is required"),

  city: Yup.string("Please enter your City.")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),
  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
});

let MONTHLY_PLAN_PER_MONTH;
let MONTHLY_PLAN_AMOUNT;
let MONTHLY_PLAN_FOR_BEGENING;
let YEARLY_PLAN_PER_MONTH;
let MONTHLY_PLAN_RECORDING_FEES;
let YEARLY_PLAN_RECORDING_FEES;

const DEFAULT_URL = process.env.REACT_APP_DEFAULT_URL;

export default function CheckoutForm({
  mailingAddress,
  mailingData,
  stateCodesData,
  onBtnClick,
  planDetails,
  paymetData,
  subscriptionPlans,
  errorPopup,
  reduceErrorCount,
  goBackOnSubscription,
  investor_id,
}) {
  const [stripeErr, setStripeErr] = useState("");
  const [apiErr, setApiErr] = useState("");
  const [open, setOpen] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checkTerms, setCheckTerms] = useState(true);
  const [finalAmountPopup, setFinalAmountPopup] = useState(false);
  const [dataflag, setDataFlag] = useState(false);
  const [paymentPayload, setPaymentPayload] = useState(false);
  const [valdiateModal, setValdiateModal] = useState(false);

  const dispatch = useDispatch();
  const creditCardInfo = useSelector(
    (state) => state.signUpUser.creditCardInfo
  );
  const crerateAccData = useSelector(
    (state) => state.signUpUser.createAccResponse
  );
  const {
    paymentData,
    paymnetLoader,
    totalPayment,
    finalAmountPayload,
    totalPaymentDetails,
    statesLoader,
    isPaymentDone,
    propertyIds,
    properties,
    investorsPricingBreakdown,
    investorsPropertiesCount,
    investorsYearlyData,
  } = useSelector((state) => state.signUpUser);

  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));

  console.log("paymentData==>", paymentData);
  console.log(userIdFromSession, "credit card infro ==>>", creditCardInfo);

  let pc = localStorage.getItem("promoCode");
  let rc = localStorage.getItem("referralCode");

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardInfo]);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (values) => {
    // event.preventDefault();
    setCheckTerms(checked1);
    console.log(!values.promo_code, "values==>>", values);
    if (!values.referral_code) {
      delete values.referral_code;
    }
    if (!values.promo_code) {
      delete values.promo_code;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if (checked1) {
      handleToggle();
    }
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      console.error(result.error.message);
      setStripeErr(result.error.message);
      handleClose();
    } else {
      // handleToggle();
      setStripeErr("");
      let data = { ...values, step: 4, step_key: "SAVE_CREDIT_CARD" };
      data.token = result.token.id;
      data.user_id = getObjLength(crerateAccData)
        ? crerateAccData.data?.user_id
        : userIdFromSession;
      if (checked1) {
        stripeTokenHandler(data);
      }
    }
  };

  useEffect(() => {
    // if (Object.keys(paymentData).length > 0) {
    //   if (paymentData?.success) {
    //     setOpenPopup(true);
    //   } else {
    //     setOpen(false);
    //   }
    // }
    if (getObjLength(paymentData)) {
      if (paymentData?.success) {
        if (paymentData?.data?.actionRequired) {
          setValdiateModal(false);
          setOpen(false);
          return;
        }
        if (
          !paymentData?.data?.actionRequired &&
          paymentData?.data?.length === 0
        ) {
          // setOpenPopup(true);
          setValdiateModal(true);
          setOpen(false);
          return;
        }
      } else {
        setValdiateModal(true);
        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData]);

  useEffect(() => {
    if (getObjLength(isPaymentDone)) {
      if (isPaymentDone?.success) {
        if (isPaymentDone?.data?.payment_status === "success") {
          // setOpenPopup(true);
          setValdiateModal(true);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData]);

  const stripeTokenHandler = (data) => {
    console.log(creditCardInfo, "== Stripe Token Handler==>>", data);
    // if (creditCardInfo && creditCardInfo?.success) {
    // handleGetPlansScreen();
    cardPayment(data);
    // onBtnClick();
    // } else {
    //   dispatch(
    //     userSigupAction.saveCreditCardInfo({
    //       url: "save-credit-card",
    //       data: data,
    //     })
    //   );
    // }
  };

  const validateErr = (event) => {
    console.log("validate error==>", event);
    setStripeErr("");
  };

  const handleClose = () => {
    console.log("creditCardInfo==>", creditCardInfo);
    if (creditCardInfo?.success) {
      // handleGetPlansScreen();
      cardPayment();
      // onBtnClick();
    }
    if (!creditCardInfo?.sucess) {
      setApiErr(creditCardInfo?.data?.message);
    }

    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const cardPayment = (dd) => {
    console.log("pc==>>", pc);
    setOpen(true);
    let data = {};

    if (pc?.length > 0) {
      data = {
        ...paymetData,
        promo_code: pc,
        ...dd,
      };
    } else {
      data = {
        ...paymetData,
        ...dd,
      };
    }

    if (rc?.length > 0) {
      data = {
        ...paymetData,
        referral_code: rc,
        ...dd,
      };
    } else {
      data = {
        ...paymetData,
        ...dd,
      };
    }
    data = { ...data, property_id: propertyIds, step: 4, step_key: "PAYMENT" };
    console.log("creditcard payment data==>", data);
    setPaymentPayload(data);
    console.log(
      pc,
      "===",
      data?.promo_code,
      "finalAmountPayload==>",
      finalAmountPayload
    );
    if (pc?.length > 0 || data?.promo_code?.length > 0) {
      if (pc === data?.promo_code) {
        dispatch(
          userSigupAction.getPayment({
            url: "payment",
            data: data,
          })
        );
      } else {
        setOpen(false);
        let paymnetData = { ...finalAmountPayload };
        // if (data?.promo_code?.length > 0) {
        paymnetData.promo_code = data?.promo_code;
        // }
        console.log("passed data==>", paymnetData);
        let url = "get-final-amount";

        // for investors when they add promocode
        if (investor_id) {
          url = "investors-price-details";
          paymnetData = {
            user_id: data?.user_id,
            property_id: data?.property_id,
            promo_code: data?.promo_code,
            plan_id: data?.plan,
          };
        }

        dispatch(
          userSigupAction.getTotalPaymentDetails({
            url: url,
            data: paymnetData,
          })
        );
      }
    } else {
      setOpen(false);
      dispatch(
        userSigupAction.getPayment({
          url: "payment",
          data: data,
        })
      );
    }
  };
  const paymentDone = () => {
    console.log("Payment done");
    // setOpenPopup(false);
    dispatch(subscriptionAction.clear3dSecurePaymentData({}));
    reactPixelEvent("Payment Info", localStorage.getItem("PlanDetails"));
    localStorage.setItem("currentStep", "4");
    localStorage.setItem("skip", "1");
    localStorage.removeItem("isOwnerVerified");
    localStorage.setItem("errorCount", 0);

    onBtnClick();
  };

  const FieldStyle = {
    borderRadius: "3px",
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const ss = Object.values(stateCodesData);
  const stateValue = ss.filter((e) => e === mailingAddress?.state);

  const onChecked = (e) => {
    console.log("cjceked val==?", e.target.checked);
    setChecked1(e.target.checked);
    if (!e.target.checked) {
      setCheckTerms(e.target.checked);
    } else {
      setCheckTerms(true);
    }
  };
  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };

  if (getObjLength(subscriptionPlans) && subscriptionPlans?.success) {
    if ((subscriptionPlans?.data).length > 0) {
      const monthlyPlans = subscriptionPlans?.data?.find(
        (data) => data?.name === "Monthly"
      );
      const yearlyPlans = subscriptionPlans?.data?.find(
        (data) => data?.name === "Yearly"
      );
      MONTHLY_PLAN_PER_MONTH = monthlyPlans.recurring_amount;
      MONTHLY_PLAN_AMOUNT = monthlyPlans.amount;
      MONTHLY_PLAN_FOR_BEGENING = monthlyPlans.monthly_plan_for_begening;
      YEARLY_PLAN_PER_MONTH = yearlyPlans?.recurring_amount;
      MONTHLY_PLAN_RECORDING_FEES = monthlyPlans.recording_fee;
      YEARLY_PLAN_RECORDING_FEES = yearlyPlans?.recording_fee;
    }
  }

  const submitChanges = () => {
    console.log("paymentPayload==> ", paymentPayload);
    dispatch(
      userSigupAction.getPayment({
        url: "payment",
        data: paymentPayload,
      })
    );
    setFinalAmountPopup(false);
  };

  const onSuccessFun = () => {
    setOpen(false);
    console.log("success on 3d payment");
  };

  const onFailedFun = () => {
    setOpen(false);
    console.log("failed on 3d payment");
  };

  useEffect(() => {
    console.log("totalPaymentDetails==>", totalPaymentDetails);
    if (getObjLength(totalPaymentDetails)) {
      setFinalAmountPopup(true);
      if (totalPaymentDetails?.success) {
        setDataFlag(true);
      } else {
        setDataFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPaymentDetails]);

  function successMsg() {
    return (
      <>
        <p className="text-success fw-500 mt-3">
          {/* {paymentData?.message} */}
          Congratulations!
        </p>
        <p>
          EquityProtect will now start monitoring your property and begin the
          process of locking down your title. In the meantime, watch your mail
          for a postcard that will be sent to your mailing address which will be
          the final verification process. Please follow the instructions and use
          the code on the postcard to finalize the process.
        </p>
        <Box className="mb-4 alert-modal-btn">
          <Button className="popup_Btn success_popup_btn" onClick={paymentDone}>
            Next step
          </Button>
        </Box>
      </>
    );
  }
  let county_name = decryptPayload(localStorage.getItem("county-code"));
  return (
    <>
      <SecurePaymentPanel
        displayLabel={false}
        cardData={null}
        paymentData={paymentData}
        onSuccessFun={onSuccessFun}
        onFailedFun={onFailedFun}
        cardList={false}
        userId={
          getObjLength(crerateAccData)
            ? crerateAccData.data?.user_id
            : userIdFromSession
        }
        successMsg={successMsg()}
        successClose={false}
        last_steps={{
          properties: properties,
          step: 4,
          step_key: "PAYMENT",
          from_signup: true,
        }}
      />

      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          first_name: mailingData?.first_name || "",
          last_name: mailingData?.last_name || "",
          address: mailingAddress?.address || "",
          city: mailingAddress?.city || "",
          state: stateValue[0] || "",
          zip: (mailingAddress && (mailingAddress?.zip).slice(0, 5)) || "",
          referral_code: rc ? rc : null,
          promo_code: pc ? pc : null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, touched, errors }) => {
          return (
            <Form>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid xs={12} md={12} px={1} pb={1} mb={2}>
                  <CardSection
                    stripeErr={stripeErr}
                    validateErr={validateErr}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="First Name"
                    name="first_name"
                    value={values?.first_name}
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    onChange={(event) => {
                      setFieldValue("first_name", event.target.value);
                    }}
                    helperText={touched.first_name && errors.first_name}
                    error={touched.first_name && Boolean(errors.first_name)}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Last Name"
                    name="last_name"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    value={values?.last_name}
                    onChange={(event) => {
                      setFieldValue("last_name", event.target.value);
                    }}
                    helperText={touched.last_name && errors.last_name}
                    error={touched.last_name && Boolean(errors.last_name)}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Address"
                    name="address"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    value={values?.address}
                    onChange={(event) => {
                      setFieldValue("address", event.target.value);
                    }}
                    helperText={touched.address && errors.address}
                    error={touched.address && Boolean(errors.address)}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="City"
                    name="city"
                    value={values?.city}
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    onChange={(event) => {
                      setFieldValue("city", event.target.value);
                    }}
                    helperText={touched.city && errors.city}
                    error={touched.city && Boolean(errors.city)}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <FormControl
                    fullWidth
                    className="sign-textField "
                    error={touched.state && Boolean(errors.state)}
                  >
                    <InputLabel
                      // id="demo-simple-select-placeholder"
                      label="State"
                    >
                      State
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-placeholder"
                      id="demo-simple-select"
                      value={values?.state}
                      label="State"
                      name="state"
                      variant="outlined"
                      onChange={(event) => {
                        setFieldValue("state", event.target.value);
                      }}
                      MenuProps={MenuProps}
                    >
                      {ss?.map((state, key) => (
                        <MenuItem value={state}>{state}</MenuItem>
                      ))}
                    </Select>
                    {touched.state && Boolean(errors.state) && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.state && errors.state}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Zip"
                    name="zip"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    value={values?.zip}
                    onChange={(event) => {
                      setFieldValue("zip", event.target.value);
                    }}
                    helperText={touched.zip && errors.zip}
                    error={touched.zip && Boolean(errors.zip)}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Promo Code"
                    name="promo_code"
                    value={values?.promo_code}
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#00000075",
                      },
                      shrink: true,
                    }}
                    placeholder={"Optional"}
                    onChange={(event) => {
                      setFieldValue("promo_code", event.target.value);
                    }}
                    // helperText={touched.promo_code && errors.promo_code}
                    // error={touched.promo_code && Boolean(errors.promo_code)}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    // id="outlined-basic"
                    label="Referral Code"
                    name="referral_code"
                    value={values?.referral_code}
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#00000075",
                      },
                      shrink: true,
                    }}
                    placeholder={"Optional"}
                    onChange={(event) => {
                      setFieldValue("referral_code", event.target.value);
                    }}
                    // helperText={touched.referral_code && errors.referral_code}
                    // error={touched.referral_code && Boolean(errors.referral_code)}
                  />
                </Grid>
              </Grid>
              {apiErr && (
                <>
                  <p className="text-danger text-center mt-3">{apiErr}</p>
                  {errorPopup && (
                    <ContcatAdminSupport
                      message={apiErr}
                      errorPopup={errorPopup}
                      reduceErrorCount={reduceErrorCount}
                    />
                  )}
                </>
              )}
              {Object.keys(paymentData).length > 0 && !paymentData?.success && (
                <p className="text-danger text-center mt-4 mb-0">
                  {paymentData?.data?.message}
                </p>
              )}
              {!mailingData?.success && mailingData?.message && (
                <>
                  <p className="text-danger text-center">
                    {mailingData?.message}
                  </p>
                </>
              )}
              <Box textAlign={"start"} py={5} px={1}>
                <Stack
                  direction={"column"}
                  spacing={1}
                  justifyContent={"flex-start"}
                  alignItems={"start"}
                >
                  <Stack
                    direction={"row"}
                    className={!checkTerms && "border border-danger p-2"}
                  >
                    <span className="pt-1">
                      <CustomizedCheckbox onChecked={onChecked} />
                    </span>
                    <span>
                      By checking this box, you are agreeing to the terms below,
                      including EquityProtect's Subscription and Automatic
                      Renewal Payment Authorization terms and{" "}
                      <a
                        href={`${DEFAULT_URL}api/service_agreement_2023.pdf`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Terms and Conditions
                      </a>{" "}
                      of Use.
                    </span>
                  </Stack>
                  {!checkTerms && (
                    <p className="m-0 px-0 text-center text-danger">
                      Please select given terms to proceed further.
                    </p>
                  )}
                  <br />
                  <span>
                    Subscription & Automatic Renewal Payment Authorization. The
                    subscription begins when your purchase is completed, and it
                    will automatically renew before each renewal period (plus
                    applicable state taxes). By subscribing, you authorize us to
                    automatically charge your payment method the applicable
                    renewal price (plus applicable state taxes) before each
                    renewal, unless you cancel through your account dashboard,
                    or contact &nbsp;{" "}
                    <SupportEmailLink
                      email={SUPPORT_EMAIL}
                      text={SUPPORT_EMAIL}
                    />
                    , or call &nbsp;
                    <Callto phone={`${SUPPORT_PHONE}`}>
                      {SUPPORT_PHONE_FORMAT}
                    </Callto>
                  </span>
                </Stack>
              </Box>
              <Row className="d-flex align-items-center justify-content-center mt-5 break-word">
                <Col xs={12} sm={12} md={12} lg={5} xl={5} className="stepBtn">
                  <Button
                    disabled={!stripe}
                    type="submit"
                    className="next_button mb-3"
                    variant="contained"
                  >
                    Start My Protection
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                  {planDetails?.name === "Monthly" ? (
                    <>
                      {county_name ? (
                        <p className="para mb-0 activation_text font_16">
                          You selected the Monthly Subscription for
                          <span className="fw-900 price_clr">
                            {" "}
                            ${(MONTHLY_PLAN_AMOUNT / 100)?.toFixed(2)}
                          </span>{" "}
                          for the first month and then
                          <span className="fw-900 price_clr">
                            ${MONTHLY_PLAN_PER_MONTH / 100}
                          </span>{" "}
                          each following month.
                        </p>
                      ) : (
                        <p className="para mb-0 activation_text font_16">
                          You selected the Monthly Subscription for
                          <span className="fw-900 price_clr">
                            {" "}
                            ${MONTHLY_PLAN_PER_MONTH / 100}
                          </span>{" "}
                          per month + {""}
                          <span className="fw-900 price_clr">
                            ${planDetails?.setup_fees}
                          </span>{" "}
                          Setup fee for the first year.
                        </p>
                      )}
                      <p className="para mb-0 activation_text font_16">
                        {/* <span className="fw-900 price_clr">
                          {currencyFormatter(LOCAL_RECORDING_FEES)}{" "}
                        </span> */}
                        You have to pay applicable sales tax
                        {MONTHLY_PLAN_RECORDING_FEES
                          ? "+ applicable Deposit for recording fees (One time fee)"
                          : "."}
                      </p>
                      <p className="para mb-0 mt-3 activation_text font_16">
                        Your card will be charged{" "}
                        <span className="fw-900 price_clr">
                          $
                          {county_name
                            ? (MONTHLY_PLAN_AMOUNT / 100)?.toFixed(2)
                            : MONTHLY_PLAN_FOR_BEGENING}
                        </span>{" "}
                        once your subscription begins then{" "}
                        <span className="fw-900 price_clr">
                          ${MONTHLY_PLAN_PER_MONTH / 100}
                        </span>{" "}
                        each month there after. This will apply for each
                        property we have under our custodian.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="para mb-0 activation_text font_16">
                        You selected the Annual Subscription for{" "}
                        <span className="fw-900 price_clr">
                          $
                          {(
                            (investor_id
                              ? investorsYearlyData?.amount
                              : planDetails?.amount) / 100
                          ).toFixed(2)}
                        </span>{" "}
                        for the first year and then{" "}
                        <span className="fw-900 price_clr">
                          $
                          {(investor_id
                            ? investorsYearlyData?.recurring_amount
                            : YEARLY_PLAN_PER_MONTH) / 100}
                        </span>{" "}
                        each following year.
                      </p>
                      <p className="para mb-0 activation_text font_16">
                        {/* <span className="fw-900 price_clr">
                          &nbsp;{currencyFormatter(LOCAL_RECORDING_FEES)}&nbsp;
                        </span> */}
                        You have to pay applicable sales tax
                        {investor_id
                          ? investorsYearlyData?.recording_fee
                          : YEARLY_PLAN_RECORDING_FEES
                          ? " + applicable Deposit for recording fees (One time fee)"
                          : "."}
                      </p>
                      <p className="para mt-3 mb-0 activation_text font_16">
                        Your card will be charged{" "}
                        <span className="fw-900 price_clr">
                          {" "}
                          $
                          {(
                            (investor_id
                              ? investorsYearlyData?.amount
                              : planDetails?.amount) / 100
                          ).toFixed(2)}
                        </span>{" "}
                        once your subscription begins. This will apply for each
                        property we have under our custodian.
                      </p>
                    </>
                  )}
                  <p className="para mt-3 mb-0 activation_text font_16">
                    Total amount will be deducted from your credit card is{" "}
                    <span className="fw-900 price_clr">
                      $
                      {investor_id
                        ? investorsPricingBreakdown?.total
                        : totalPayment?.data?.total}
                    </span>{" "}
                    for{" "}
                    <span className="fw-900 price_clr">
                      {investor_id
                        ? investorsPropertiesCount
                        : totalPayment?.data?.no_of_properties}
                    </span>{" "}
                    {(investor_id
                      ? investorsPropertiesCount
                      : totalPayment?.data?.no_of_properties) > 1
                      ? "properties"
                      : "property"}{" "}
                    you've added.
                  </p>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      {Object.keys(paymentData).length > 0 && !paymentData?.success && (
        <>
          {/* <p className="text-danger text-center mt-4">
            {paymentData?.data?.message}
          </p> */}
          {errorPopup && (
            <ContcatAdminSupport
              message={paymentData?.data?.message}
              errorPopup={errorPopup}
              reduceErrorCount={reduceErrorCount}
            />
          )}
        </>
      )}
      {/* {!mailingData?.success && (
        <>
          <p className="text-danger text-center">{mailingData?.message}</p>
        </>
      )} */}
      <Box className="text-center mt-5">
        <Button
          variant="contained"
          className="popup_Btn other_popup_btn"
          onClick={() => goBackOnSubscription(false)}
        >
          Go Back
        </Button>
      </Box>

      {/* for normal payment flow */}
      {getObjLength(paymentData) && valdiateModal && paymentData?.success && (
        // openPopup &&
        <AlertDialog
          type="Success"
          openPopup={true}
          onClose={false}
          mesage={successMsg()}
        />
      )}

      {/* if paymnet process interrupt */}
      {getObjLength(isPaymentDone) &&
        valdiateModal &&
        isPaymentDone?.success &&
        isPaymentDone?.data?.payment_status === "success" && (
          // openPopup &&
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={false}
            mesage={successMsg()}
          />
        )}

      <Loader
        open={open}
        message={"Do not close or reload the page while making payment."}
      />
      <Loader
        open={paymnetLoader || statesLoader}
        message={"Do not close or reload the page while making payment."}
      />
      <hr />
      <div className="text-center mt-3 mb-0 w-100">
        <img src={Aurthorised} alt="Aurthorised" />
      </div>

      {/* When promocode has changed */}
      {finalAmountPopup &&
        (dataflag ? (
          <TotalPyamentPopup
            dataflag={dataflag}
            setDataFlag={setDataFlag}
            alert={finalAmountPopup}
            setAlert={setFinalAmountPopup}
            closePopup={() => setFinalAmountPopup(false)}
            planDetails={planDetails}
            isClose={true}
            paySecure={submitChanges}
            investor_id={investor_id}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={finalAmountPopup}
            onClose={true}
            onCloseFun={() => setFinalAmountPopup(false)}
            mesage={totalPaymentDetails?.data?.message}
            buttons={
              <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                <Button
                  className="popup_Btn error_popup_btn m-2"
                  onClick={() => setFinalAmountPopup(false)}
                >
                  cancel
                </Button>
              </Stack>
            }
          />
        ))}
    </>
  );
}
