import React from "react";

import { useSelector } from "react-redux";

import { Button, Stack, Typography } from "@mui/material";

import AlertDialog from "../../common/AlertDialog";
import { currencyFormatter } from "../../helpers/utils";

const TotalPyamentPopup = ({
  dataflag = false,
  setDataFlag = () => {},
  alert = false,
  setAlert = () => {},
  planDetails,
  closePopup,
  isClose = true,
  paySecure = () => {},
  investor_id = null,
}) => {
  // const { totalPaymentDetails } = useSelector((state) => state.signUpUser);
  const {
    totalPaymentDetails,
    investorsPricingBreakdown,
    investorsYearlyData,
  } = useSelector((state) => state.signUpUser);
  console.log(dataflag, "alert==>>", alert);
  const totalPaymentData = investor_id
    ? investorsPricingBreakdown
    : totalPaymentDetails?.data;
  return (
    <>
      {alert && dataflag ? (
        <>
          <AlertDialog
            type="Info"
            openPopup={alert}
            onClose={isClose}
            onCloseFun={() => {
              setAlert(false);
            }}
            mesage={
              <>
                <Typography align="center" variant="h6">
                  Total Amount Details
                  {planDetails
                    ? ` for ${planDetails?.name || planDetails?.plan_Name} Plan`
                    : null}
                </Typography>
                <p className="para">
                  Total Properties: <b>{totalPaymentData?.no_of_properties} </b>
                  <br className="d-block d-sm-none" />
                  <span className="d-none d-sm-inline-block"> and </span> Plan
                  amount:{" "}
                  <b>
                    $
                    {investor_id
                      ? totalPaymentData?.subscription_amount_actual
                      : totalPaymentData?.sub_amount}{" "}
                  </b>
                  <br />
                  Discount:{" "}
                  <b>
                    {investor_id
                      ? currencyFormatter(
                          parseFloat(
                            totalPaymentData?.discount?.replaceAll(",", "")
                          ) +
                            parseFloat(
                              totalPaymentData?.promocode_discount?.replaceAll(
                                ",",
                                ""
                              )
                            )
                        )
                      : "$" + totalPaymentData?.discount}{" "}
                  </b>{" "}
                  <br className="d-block d-sm-none" />
                  <span className="d-none d-sm-inline-block"> and </span>{" "}
                  Sales/Local taxes:{" "}
                  <b>${totalPaymentData?.sales_tax_amount_actual} </b>
                  {parseFloat(totalPaymentData?.recording_amount_actual) >
                    0 && (
                    <>
                      <br />
                      Deposit for recording fees:{" "}
                      <b>${totalPaymentData?.recording_amount_actual} </b>{" "}
                      <br className="d-block d-sm-none" />
                      <span className="d-none d-sm-inline-block">
                        {" "}
                        and{" "}
                      </span>{" "}
                      Setup Fees: <b>${totalPaymentData?.setup_fees_actual}</b>
                    </>
                  )}
                  <br />
                  <br className="d-block d-sm-none" />
                  Total Payable Amount: <b>${totalPaymentData?.total} </b>
                </p>
              </>
            }
            buttons={
              <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                <Button
                  onClick={() => closePopup("cancel")}
                  type="submit"
                  className=" mx-2 popup_Btn other_popup_btn"
                  variant="contained"
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    closePopup("submit");
                    paySecure();
                  }}
                  type="submit"
                  className="mx-2 popup_Btn success_popup_btn"
                  variant="contained"
                >
                  Next
                </Button>
              </Stack>
            }
          />
        </>
      ) : (
        <AlertDialog
          type="Error"
          openPopup={alert}
          onClose={true}
          onCloseFun={isClose}
          mesage={totalPaymentData?.message}
          buttons={
            <Stack spacing={2} direction={"row"} justifyContent={"center"}>
              <Button
                className="popup_Btn error_popup_btn m-2"
                onClick={() => {
                  setAlert(false);
                  closePopup("cancel");
                }}
              >
                cancel
              </Button>
            </Stack>
          }
        />
      )}
    </>
  );
};

export default TotalPyamentPopup;
