/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from "react";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { Box, Button, Grid, IconButton, Paper } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { AdminProfileForm } from "./AdminProfileForm";
import SupportEmailLink from "../../common/SupportEmailLink";
import useClearLocalStorage from "../../hooks/useClearLocalStorage";
import ReferralLink from "../../userProfile/Dashboard/ReferralLink";
import { getObjLength, windowsAndroidNativeMethods } from "../../helpers/utils";
import ProfileDP from "../../../assets/dashboard/Dummy_Profile_photo.png";
import { userSigInAction } from "../../../redux/slices/user/userSignInSlice";
import UploadProfilePictureModal from "../../common/UploadProfilePictureModal";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import {
  DEFAULT_URL,
  ROLES,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../../helpers/constants";

import "../../../styles/dashboard.scss";

const styles = {
  "&.MuiButton-outlined": {
    background: "#FFFFFF",
    border: "1px solid #15BE53",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#15BE53",
    padding: "11px",
    minWidth: "auto",
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
};

const Paperstyles = {
  "&.MuiPaper-elevation0": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
    borderRadius: "4px",
    p: 3,
    mb: 2,
  },
};

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export default function AdminProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const { clearLocalStorageValues } = useClearLocalStorage();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isEditProfilePictureModalOpen, setIsEditProfilePictureModalOpen] =
    useState(false);

  const {
    cardDetails,
    userProfileDetails,
    beneficiaryLoader,
    addressLoader,
    editedUserProfileDetails: editedData,
    editProfileLoader,
    profileLoader,
    referralLoader,
  } = useSelector((state) => state.userProfile);

  useEffect(() => {
    console.log("profile data called==>", userProfileDetails);
    if (userProfileDetails?.success && cardDetails?.success) {
      handleClose();
    }

    if (getObjLength(userProfileDetails) && userProfileDetails?.success) {
      if (userProfileDetails?.data?.status === "inactive") {
        localStorage.clear();
        localStorage.setItem("preference", true);
        window.location.href = `${DEFAULT_URL}login`;
      }
    }
  }, [userProfileDetails, cardDetails]);

  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };

  const handleClose = () => {
    if (userProfileDetails?.success) {
      setEdit(false);
    }
    setOpen(false);
  };
  const clearUserData = () => {
    dispatch(userProfileAction.clearUserProfileData());
  };

  const handleSignOut = () => {
    dispatch(
      userProfileAction.logoutUser({ url: "logout", data: {}, token: true })
    );
    dispatch(userSigInAction.successLogout());
    clearLocalStorageValues();

    // logout native method call for mobile app
    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.invokeNativeLogout
    ) {
      console.log("Logout Native App got called...");
      window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
    }
    // call Android App Logout method
    windowsAndroidNativeMethods("logout");

    navigate("/login");
  };

  // handle edit profile picture button
  const handleEditProfilePicBtn = () => {
    if (formRef?.current) {
      formRef?.current?.resetForm();
    }
    setEdit(false);
    setIsEditProfilePictureModalOpen(true);
  };

  // handle edit profile picture button
  const handleEditProfilePicModalClose = () => {
    setIsEditProfilePictureModalOpen(false);
    setEdit(false);
  };

  // handle upload profile picture
  const handleUploadProfilePicture = (base64File) => {
    const formData = new FormData();

    formData.append("company_name", userProfileDetails?.data?.company_name);
    formData.append("first_name", userProfileDetails?.data?.first_name);
    formData.append("last_name", userProfileDetails?.data?.last_name);
    formData.append(
      "phone_number",
      userProfileDetails?.data?.phone_number || ""
    );
    formData.append("profile_img", base64File);

    dispatch(
      userProfileAction.editUserProfileData({
        url: "admin/update-profile",
        data: formData,
        token: true,
      })
    );
  };

  useEffect(() => {
    dispatch(
      userProfileAction.getReferralData({
        url: "generate-referral-link",
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Box className="profile-wrapper">
        {Object.keys(userProfileDetails).length > 0 ? (
          userProfileDetails?.success ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Typography variant="h6" className="title">
                  My Profile
                </Typography>
                <Button
                  variant="contained"
                  className="signoutBtn d-none d-md-block"
                  onClick={handleSignOut}
                >
                  Sign Out
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Paper
                    elevation={0}
                    className="personal-info"
                    sx={Paperstyles}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="h6" className="card-title" my={1.6}>
                        Personal information
                      </Typography>
                      {!edit && (
                        <>
                          <Button
                            variant="outlined"
                            className="d-none d-md-block EditBtn"
                            sx={styles}
                            onClick={() => setEdit(!edit)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            className="d-block d-md-none EditBtn p-2"
                            sx={styles}
                            onClick={() => setEdit(!edit)}
                          >
                            <ModeEditOutlineOutlinedIcon fontSize="small" />
                          </Button>
                        </>
                      )}
                    </Box>
                    <Box className="d-flex align-items-center">
                      <Box className="profile-photo-box">
                        <img
                          src={
                            userProfileDetails?.data?.profile_img
                              ? userProfileDetails?.data?.profile_img
                              : ProfileDP
                          }
                          alt="Profile"
                          className="profilePic"
                        />
                        <IconButton
                          className="profile-photo-edit-btn"
                          onClick={handleEditProfilePicBtn}
                        >
                          <ModeEditOutlineOutlinedIcon fontSize="large" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box className="form" sx={{ mt: 5 }}>
                      {userProfileDetails?.success && (
                        <>
                          {/* <DashboadrForm
                            userProfileDetails={userProfileDetails}
                            stateData={stateData}
                            edit={edit}
                          /> */}
                          <AdminProfileForm
                            userProfileDetails={userProfileDetails}
                            edit={edit}
                            setEdit={setEdit}
                            formRef={formRef}
                          />
                        </>
                      )}
                    </Box>
                  </Paper>
                </Grid>
                <Grid
                  className="pt-1 pt-lg-3"
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  {/* <Paper
                    elevation={0}
                    className="trusted-contacts"
                    sx={Paperstyles}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 1 }}
                    >
                      <Typography variant="h6" className="card-title">
                        Trusted contacts
                      </Typography>
                      <Button
                        variant="outlined"
                        className="d-none d-md-block EditBtn"
                        sx={styles}
                      >
                        Add
                      </Button>
                      <Button
                        variant="outlined"
                        className="d-block d-md-none EditBtn p-2"
                        sx={styles}
                      >
                        <ModeEditOutlineOutlinedIcon fontSize="small" />
                      </Button>
                    </Box>
                    <Typography
                      variant="body1"
                      className="w-75 caption mb-3 mb-lg-4"
                    >
                      People who you chose who can securely help if you ever
                      have trouble accessing your account
                    </Typography>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      position="relative"
                    >
                      <Box className="contacts d-flex flex-column">
                        <Typography variant="body1" className="person-name">
                          FirstName LastName
                        </Typography>
                        <Typography
                          variant="body2"
                          className="mb-1 person-relation"
                        >
                          Relation
                        </Typography>
                      </Box>
                    </Stack>
                    <Divider className="separator my-3" />
                  </Paper> */}
                  <Paper
                    elevation={0}
                    className="help-contact"
                    sx={Paperstyles}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="h6" className="card-title">
                        Help and contact
                      </Typography>
                    </Box>
                    <List
                      className="supportContact"
                      sx={{ width: "100%", bgcolor: "background.paper" }}
                    >
                      <ListItem disablePadding className="align-items-start">
                        <ListItemText className="mb-0 label">
                          Support:
                        </ListItemText>
                        <ListItemText className="mb-0 content">
                          <SupportEmailLink
                            email={SUPPORT_EMAIL}
                            text={"  Click here to contact us"}
                          />
                        </ListItemText>
                      </ListItem>
                      <ListItem disablePadding className="align-items-start">
                        <ListItemText className="mb-0 label">
                          Email:
                        </ListItemText>
                        <ListItemText className="mb-0 content">
                          <SupportEmailLink
                            email={SUPPORT_EMAIL}
                            text={SUPPORT_EMAIL}
                          />
                        </ListItemText>
                      </ListItem>
                      <ListItem disablePadding className="align-items-start">
                        <ListItemText className="mb-0 label">
                          By phone:
                        </ListItemText>
                        <ListItemText className="mb-0 content">
                          <div className="d-flex flex-column">
                            <Callto phone={`${SUPPORT_PHONE}`}>
                              {SUPPORT_PHONE_FORMAT}
                            </Callto>
                            <p className="mb-0">
                              MON-FRI: <span>8:00AM - 6:00PM</span>
                            </p>
                            <p className="mb-0">
                              SAT-SUN: <span>9:00AM - 5:00PM</span>
                            </p>
                          </div>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Paper>
                  {/* referal link */}
                  {userProfileDetails?.data?.role?.includes(ROLES.subAdmin) && (
                    <ReferralLink Paperstyles={Paperstyles} />
                  )}
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  className="signoutBtn d-block d-md-none w-100"
                  onClick={handleSignOut}
                >
                  Sign Out
                </Button>
              </Box>
            </>
          ) : (
            <Box className="text-center">
              <Button
                className="text-danger text-capitalize"
                onClick={clearUserData}
              >
                {userProfileDetails?.data?.message}
                <Link to={"/login"} className="bold text-decoration-none">
                  &nbsp;Click here to Login again.
                </Link>
              </Button>
            </Box>
          )
        ) : (
          <Loader open={true} />
        )}
      </Box>

      {/* upload profile picture modal */}
      <UploadProfilePictureModal
        open={isEditProfilePictureModalOpen}
        handleClose={handleEditProfilePicModalClose}
        handleUploadProfilePicture={handleUploadProfilePicture}
        loader={editProfileLoader}
      />

      {getObjLength(editedData) ? (
        editedData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userProfileAction.fetcheditedUserData({}));
              handleEditProfilePicModalClose();
              setEdit(false);
              dispatch(
                userProfileAction.userProfileData({
                  url: "profile",
                  data: {},
                  token: true,
                })
              );
            }}
            mesage={editedData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() =>
              dispatch(userProfileAction.fetcheditedUserData({}))
            }
            mesage={editedData?.data?.message}
          />
        )
      ) : null}

      <Loader
        open={
          open ||
          beneficiaryLoader ||
          addressLoader ||
          profileLoader ||
          editProfileLoader ||
          referralLoader
        }
      />
    </>
  );
}
