import { useEffect } from "react";

const useInjectSchema = (schemaId, schemaData) => {
  useEffect(() => {
    if (!schemaData) return;

    // Create script tag
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.id = schemaId; // Unique ID to track and remove the script
    script.textContent = JSON.stringify(schemaData);

    document.head.appendChild(script);

    // Cleanup: Remove script when component unmounts
    return () => {
      const existingScript = document.getElementById(schemaId);
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [schemaId, schemaData]); // Runs only when schema data changes
};

export default useInjectSchema;
