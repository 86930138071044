import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { useTheme, useMediaQuery } from "@mui/material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import AddPromoCode from "./AddPromoCode";
import { Loader } from "../../common/loader";
import DialogBox from "../Dashboard/cardPopup";
import AddCardForm from "../Dashboard/addCardForm";
import VisaCard from "../../../assets/dashboard/visa.svg";
import MasterCard from "../../../assets/dashboard/mastercard.svg";
import DiscoverCard from "../../../assets/dashboard/discover.svg";
import { getMonthFromString, getObjLength } from "../../helpers/utils";
import AmericanCard from "../../../assets/dashboard/american-express.svg";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import TotalPyamentPopup from "../../user-signup/React-stripe/TotalPyamentPopup";
import CustomizedRadios from "../../common/customDesignedComponents/customRadiobutton";
import DrawerFromBottom from "../../common/DrawerFromBottom";
import zIndex from "@mui/material/styles/zIndex";

const Paperstyles = {
  "&.MuiPaper-elevation0": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
    borderRadius: "4px",
    p: 3,
    mb: 2,
  },
};

const CardList = ({
  displayLabel = true,
  cardData,
  // when admin adds property
  from = "",
  userId = null,
  callListingAPI = () => {},
  //for promocode
  addPromoCode = false,
  promoCodeValue = "",
  paySecure = () => {},
  uid = null,
  setTotalAmountModal = () => {},
  isUserCreateProperty = false,
  isOpenFromBottom,
  propertiesIds = undefined,
  selectedData = {},
}) => {
  // states
  const [creditCheck, setCreditCheck] = useState("");
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const [isPromocodeModalOpen, setIsPromocodeModalOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [dataflag, setDataFlag] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // store
  const cardDetails = useSelector((state) => state.userProfile.cardDetails);
  const reqLoader = useSelector((state) => state.properties.reqLoader);
  const {
    totalPaymentDetails,
    statesLoader,
    investorsID,
    investorsYearlyData,
  } = useSelector((state) => state.signUpUser);
  const { selectedPropertiesData, selectedProperty } = useSelector(
    (state) => state?.properties
  );
  const { investorReportResultData, invToken } = useSelector(
    (state) => state?.propertyRiskManagement
  );

  const dispatch = useDispatch();

  const [selectedCardId, setSelectedCardId] = useState("");

  const handleOpenAddNewCardModal = () => {
    setIsAddCardModalOpen(true);
  };
  const handleOpenPromocodeModal = () => {
    setIsPromocodeModalOpen(true);
  };

  const handleCloseAddNewCardModal = () => {
    setIsAddCardModalOpen(false);
  };
  const handleClosePromocodeModal = () => {
    setIsPromocodeModalOpen(false);
  };

  const closePromocodeModal = (action) => {
    console.log("actoin==>", action);
    setAlert(false);
    setDataFlag(false);
    if (action === "cancel") {
      promoCodeValue("");
    }
    dispatch(userSigupAction.fetchTotalPaymentDetails({}));
  };
  const addPromocode = (value) => {
    setTotalAmountModal(false); // to stop being open total amount popup from the parent compoent
    let plan_data = JSON.parse(localStorage.getItem("Plan_Data"));
    promoCodeValue(value?.promo_code);
    setPromoCode(value?.promo_code);
    let data = { ...plan_data, promo_code: value?.promo_code };
    console.log(data, "<==Promocode value==>", value);

    let url = "customer/get-final-amount";
    // for investors when they add promocode
    if (investorsID || invToken) {
      url = "investors-price-details";
      data = {
        user_id: userId || investorReportResultData?.data?.user?.id,
        property_id: selectedPropertiesData?.properties_Id ||
          propertiesIds || [selectedProperty?.property_id],
        property_ids: getObjLength(selectedData)
          ? [
              {
                address: selectedData?.address,
                property_id: selectedData?.property_id,
                state: selectedData?.state,
                city: selectedData?.city,
                county: selectedData?.county,
                zip: selectedData?.zip,
              },
            ]
          : undefined,
        promo_code: value?.promo_code,
        plan_id: investorsYearlyData?.plan_id,
      };
      if (invToken) {
        delete data?.property_ids;
      }
    }
    // if (isUserCreateProperty) {
    //   url = "customer/get-final-amount-v1";
    // }
    dispatch(
      userSigupAction.getTotalPaymentDetails({
        url: url,
        data: data,
      })
    );
    setIsPromocodeModalOpen(false);
  };

  const selectCredit = (e) => {
    console.log(cardDetails?.data, "Select creditBalance", e.target.value);
    setCreditCheck(parseInt(e.target.value));
    let selectedCardData = (cardDetails?.data).filter(
      (i) => i.id === parseInt(e.target.value)
    );
    console.log("selectedCardData==>", selectedCardData);
    setSelectedCardId(selectedCardData?.[0]?.id);

    cardData(selectedCardData);
  };

  useEffect(() => {
    cardData(
      (cardDetails?.success &&
      cardDetails?.data?.find((i) => i.default === 1)?.id
        ? cardDetails?.data?.find((i) => i.default === 1)?.exp_year <=
          new Date().getFullYear()
          ? getMonthFromString(
              cardDetails?.data?.find((i) => i.default === 1)?.exp_month,
              cardDetails?.data?.find((i) => i.default === 1)?.exp_year
            ) <
            new Date().getMonth() + 1
            ? ""
            : [cardDetails?.data?.find((i) => i.default === 1)]
          : [cardDetails?.data?.find((i) => i.default === 1)]
        : "") ||
        (cardDetails?.data?.[0]?.id
          ? cardDetails?.data?.[0]?.exp_year <= new Date().getFullYear()
            ? getMonthFromString(
                cardDetails?.data?.[0]?.exp_month,
                cardDetails?.data?.[0]?.exp_year
              ) <
              new Date().getMonth() + 1
              ? ""
              : [cardDetails?.data?.[0]]
            : [cardDetails?.data?.[0]]
          : "") ||
        null
    );

    setSelectedCardId(
      (cardDetails?.data?.find((i) => i?.default === 1)?.id
        ? cardDetails?.data?.find((i) => i?.default === 1)?.exp_year <=
          new Date().getFullYear()
          ? getMonthFromString(
              cardDetails?.data?.find((i) => i?.default === 1)?.exp_month,
              cardDetails?.data?.find((i) => i?.default === 1)?.exp_year
            ) <
            new Date().getMonth() + 1
            ? ""
            : cardDetails?.data?.find((i) => i?.default === 1)?.id
          : cardDetails?.data?.find((i) => i?.default === 1)?.id
        : "") ||
        (cardDetails?.data?.[0]?.id
          ? cardDetails?.data?.[0]?.exp_year <= new Date().getFullYear()
            ? getMonthFromString(
                cardDetails?.data?.[0]?.exp_month,
                cardDetails?.data?.[0]?.exp_year
              ) <
              new Date().getMonth() + 1
              ? ""
              : cardDetails?.data?.[0]?.id
            : cardDetails?.data?.[0]?.id
          : "") ||
        ""
    );
  }, [cardData, cardDetails?.data, cardDetails?.success]);

  // console.log(
  //   "card Details",
  //   cardDetails,
  //   "month ",
  //   getMonthFromString("Apr", 2023)
  // );

  // console.log("todays month", new Date().getMonth() + 1);
  // console.log("todays year", new Date().getFullYear());
  // console.log(
  //   "check month",
  //   getMonthFromString(
  //     cardDetails?.data?.find((i) => i.default === 1)?.exp_month,
  //     cardDetails?.data?.find((i) => i.default === 1)?.exp_year
  //   ) <
  //     new Date().getMonth() + 1
  // );
  // console.log(
  //   "check year",
  //   cardDetails?.data?.find((i) => i.default === 1)?.exp_year <
  //     new Date().getMonth() + 1
  // );

  // console.log("default selected", selectedCardId);
  console.log("open from bottom", isOpenFromBottom);
  useEffect(() => {
    if (getObjLength(totalPaymentDetails)) {
      setAlert(true);
      if (totalPaymentDetails?.success) {
        setDataFlag(true);
      } else {
        setDataFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPaymentDetails]);
  console.log("isOpenFromBottom", isOpenFromBottom);
  return (
    <Box
      className={`step3 documentSteps plans-content-spacing paymentStep mt-${
        !isOpenFromBottom ? "0" : "4"
      }`}
    >
      <Paper elevation={0} className="payment-info p-0 p-sm-3" sx={Paperstyles}>
        <Box
          sx={
            isMobile && {
              display: "flex",
              flexDirection: `${addPromoCode ? "column" : "row"}`,
              justifyContent: "space-between",
            }
          }
        >
          <Box
            className={`d-flex align-items-center justify-content-between `}
            sx={{ mb: 3 }}
          >
            <Typography
              variant="h6"
              className="card-title p-1 px-2"
              sx={{
                fontSize: "14px",
                lineHeight: "18px",
                color: "#0395ff",
              }}
            >
              Payment information
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                className="popup_Btn success_popup_btn d-none d-sm-block"
                variant="outlined"
                sx={{ color: "#FFF" }}
                onClick={handleOpenAddNewCardModal}
              >
                Add Card
              </Button>

              {addPromoCode &&
                (!cardDetails?.data?.length > 0 ? (
                  <Button
                    className="popup_Btn success_popup_btn d-none d-sm-block cursor-disable"
                    variant="outlined"
                    sx={{ color: "#FFF" }}
                    // onClick={handleOpenPromocodeModal}
                  >
                    Add Promocode
                  </Button>
                ) : (
                  <Button
                    className="popup_Btn success_popup_btn d-none d-sm-block"
                    variant="outlined"
                    sx={{ color: "#FFF" }}
                    onClick={handleOpenPromocodeModal}
                  >
                    Add Promocode
                  </Button>
                ))}
            </Stack>
          </Box>
          <Stack
            direction={"row"}
            className="d-flex d-sm-none justify-content-between card-list-btn"
          >
            <Button
              className="popup_Btn success_popup_btn d-flex d-sm-none justify-content-center align-items-center my-3"
              variant="outlined"
              onClick={handleOpenAddNewCardModal}
              startIcon={<AddOutlinedIcon />}
              sx={{
                color: "#FFF",
                minWidth: "108px",
                fontSize: "10px !important",
              }}
            >
              Card
            </Button>
            {addPromoCode &&
              (!cardDetails?.data?.length > 0 ? (
                <Button
                  className=" cursor-disable popup_Btn success_popup_btn d-flex d-sm-none justify-content-center align-items-center my-3"
                  variant="outlined"
                  // onClick={handleOpenPromocodeModal}
                  startIcon={<SellOutlinedIcon />}
                  sx={{
                    color: "#FFF",
                    minWidth: "108px",
                    fontSize: "10px !important",
                  }}
                >
                  Promocode
                </Button>
              ) : (
                <Button
                  className="popup_Btn success_popup_btn d-flex d-sm-none justify-content-center align-items-center my-3"
                  variant="outlined"
                  onClick={handleOpenPromocodeModal}
                  startIcon={<SellOutlinedIcon />}
                  sx={{
                    color: "#FFF",
                    minWidth: "108px",
                    fontSize: "10px !important",
                  }}
                >
                  Promocode
                </Button>
              ))}
          </Stack>
        </Box>
        <Grid container className="grid-card-list-btn" spacing={1}>
          <Grid item xs={12} sm={6}>
            <Button
              className="popup_Btn success_popup_btn mx-3"
              variant="outlined"
              onClick={handleOpenAddNewCardModal}
              startIcon={<AddOutlinedIcon />}
            >
              Card
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            {addPromoCode &&
              (!cardDetails?.data?.length > 0 ? (
                <Button
                  className="cursor-disable popup_Btn success_popup_btn mx-3"
                  variant="outlined"
                  // onClick={handleOpenPromocodeModal}
                  startIcon={<SellOutlinedIcon />}
                >
                  Promocode
                </Button>
              ) : (
                <Button
                  className="popup_Btn success_popup_btn mx-3"
                  variant="outlined"
                  onClick={handleOpenPromocodeModal}
                  startIcon={<SellOutlinedIcon />}
                >
                  Promocode
                </Button>
              ))}
          </Grid>
        </Grid>
        <RadioGroup
          value={selectedCardId}
          aria-labelledby="payment-types"
          name="payment-modes"
          onChange={selectCredit}
        >
          {Object.keys(cardDetails).length > 0 &&
            cardDetails?.data?.map((data, key) => (
              <>
                {/* desktop view */}
                <Box
                  className="d-none d-sm-flex justify-content-center align-items-center"
                  sx={{
                    p: 1,
                    my: 1,
                    mx: 1.5,
                    boxShadow: "10px 10px 10px rgba(52, 75, 91, 0.1)",
                    borderRadius: "4px",
                    backgroundColor: data?.id
                      ? data?.exp_year <= new Date().getFullYear()
                        ? getMonthFromString(data?.exp_month, data?.exp_year) <
                          new Date().getMonth() + 1
                          ? "rgba(204, 204, 204, 0.5)"
                          : false
                        : false
                      : "rgba(204, 204, 204, 0.5)",
                    maxHeight: "65px",
                  }}
                >
                  <FormControlLabel
                    value={data?.id}
                    disableTypography={true}
                    disabled={
                      data?.id
                        ? data?.exp_year <= new Date().getFullYear()
                          ? getMonthFromString(
                              data?.exp_month,
                              data?.exp_year
                            ) <
                            new Date().getMonth() + 1
                            ? true
                            : false
                          : false
                        : true
                    }
                    control={<CustomizedRadios />}
                    sx={{ width: "100%" }}
                    label={
                      <>
                        {/* desktop view */}
                        <Grid container className="pt-2 w-100" key={key}>
                          <Grid
                            className="d-flex justify-content-start"
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            mb={1}
                            // className="d-flex align-items-center"
                          >
                            {data.brand === "MasterCard" ? (
                              <img
                                width={"10%"}
                                src={MasterCard}
                                alt="Master Card"
                              />
                            ) : data.brand === "Visa" ? (
                              <img
                                width={"10% "}
                                src={VisaCard}
                                alt="Visa Card"
                              />
                            ) : data.brand === "American Express" ? (
                              <img
                                width={"10%"}
                                src={AmericanCard}
                                alt="American Card"
                              />
                            ) : data.brand === "Discover" ? (
                              <img
                                width={"10%"}
                                src={DiscoverCard}
                                alt="Discover Card"
                              />
                            ) : (
                              <CreditCardIcon fontSize="large" />
                            )}

                            <Typography
                              variant="caption"
                              className="visa-num ms-3 text-uppercase "
                            >
                              {data.brand} - **** {data.last4}
                              {data.default === 1 && (
                                <>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Chip
                                    sx={{ backgroundColor: "#46b14c33" }}
                                    className="fw-600 text-success"
                                    label="DEFAULT"
                                  />
                                </>
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            className="d-flex justify-content-center align-items-center"
                          >
                            Expiry Date, {data.exp_month} {data.exp_year}
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </Box>

                {/* mobile view */}
                <Box
                  className="d-flex d-sm-none"
                  sx={{
                    p: 1,
                    my: 1,
                    mx: 1.5,
                    borderRadius: "4px",
                    backgroundColor: data?.id
                      ? data?.exp_year <= new Date().getFullYear()
                        ? getMonthFromString(data?.exp_month, data?.exp_year) <
                          new Date().getMonth() + 1
                          ? "rgba(204, 204, 204, 0.5)"
                          : false
                        : false
                      : "rgba(204, 204, 204, 0.5)",
                    maxHeight: "65px",
                  }}
                >
                  <FormControlLabel
                    value={data?.id}
                    control={<CustomizedRadios />}
                    disabled={
                      data?.id
                        ? data?.exp_year <= new Date().getFullYear()
                          ? getMonthFromString(
                              data?.exp_month,
                              data?.exp_year
                            ) <
                            new Date().getMonth() + 1
                            ? true
                            : false
                          : false
                        : true
                    }
                    label={
                      <>
                        {/* mobile view */}
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="space-between"
                        >
                          <Stack direction="column">
                            <Box
                              sx={{
                                minWidth: data.default === 1 ? "200px" : "",
                              }}
                            >
                              {data.brand === "MasterCard" ? (
                                <img
                                  width={"25px"}
                                  src={MasterCard}
                                  alt="Master Card"
                                />
                              ) : data.brand === "Visa" ? (
                                <img
                                  width={"25px"}
                                  src={VisaCard}
                                  alt="Visa Card"
                                />
                              ) : data.brand === "American Express" ? (
                                <img
                                  width={"25px"}
                                  src={AmericanCard}
                                  alt="American Card"
                                />
                              ) : data.brand === "Discover" ? (
                                <img
                                  width={"25px"}
                                  src={DiscoverCard}
                                  alt="Discover Card"
                                />
                              ) : (
                                <CreditCardIcon fontSize="large" />
                              )}
                              <Typography
                                variant="caption"
                                className="visa-num ms-2 text-uppercase"
                                sx={{ mr: 1 }}
                              >
                                **** {data.last4}
                              </Typography>
                              {data.default === 1 && (
                                <IconButton
                                  sx={{ color: "#43D551 !important" }}
                                  disabled={true}
                                >
                                  <CheckCircleIcon />
                                </IconButton>
                              )}
                            </Box>
                            <Typography fontSize="12px">
                              Expiry Date, {data.exp_month} {data.exp_year}
                            </Typography>
                          </Stack>
                        </Stack>
                      </>
                    }
                  />
                </Box>
              </>
            ))}

          {!cardDetails?.data?.length > 0 && (
            <Typography className="p-5 text-center" variant="h6">
              No Card. Found Please add the Credit card.
            </Typography>
          )}
        </RadioGroup>
      </Paper>
      {isAddCardModalOpen && !isOpenFromBottom && (
        <DialogBox
          width="sm"
          openPopup={isAddCardModalOpen && !isOpenFromBottom}
          setOpenPopup={setIsAddCardModalOpen}
          onClose={handleCloseAddNewCardModal}
          mesage={
            <>
              {/* <Elements stripe={stripePromise}>*/}
              {from === "add-subscriptions" || from === "add-property" ? (
                <AddCardForm
                  fromAdmin={true}
                  openPopup={isAddCardModalOpen}
                  uid={userId || uid}
                  onClose={() => setIsAddCardModalOpen(false)}
                  callListingAPI={callListingAPI}
                />
              ) : (
                <AddCardForm
                  openPopup={isAddCardModalOpen}
                  handleCloseAddCardModal={() => setIsAddCardModalOpen(false)}
                  fromAdmin={false}
                  callListingAPI={invToken ? callListingAPI : undefined}
                />
              )}
            </>
          }
        />
      )}

      {isAddCardModalOpen && isOpenFromBottom && (
        <DrawerFromBottom
          width={"720px"}
          open={isAddCardModalOpen && isOpenFromBottom}
          onClose={handleCloseAddNewCardModal}
          drawerUponModal={true}
        >
          <Typography
            variant="subtitle1"
            className="py-1 formHeader"
            sx={{ px: "10px", position: "relative" }}
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
            <Button
              onClick={() => setIsAddCardModalOpen(false)}
              className="modal-close-btn close-text"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                alignSelf: "right",
                background: "transparent",
                textTransform: "none",
                justifyContent: "end",
              }}
            >
              <CloseIcon sx={{ fontSize: 20, color: "white" }} />
            </Button>
          </Typography>
          {from === "add-subscriptions" || from === "add-property" ? (
            <AddCardForm
              fromAdmin={true}
              openPopup={isAddCardModalOpen}
              uid={userId || uid}
              onClose={() => setIsAddCardModalOpen(false)}
              callListingAPI={callListingAPI}
              isOpenFromBottom={isOpenFromBottom}
            />
          ) : (
            <AddCardForm
              openPopup={isAddCardModalOpen}
              handleCloseAddCardModal={() => setIsAddCardModalOpen(false)}
              fromAdmin={false}
              isOpenFromBottom={isOpenFromBottom}
            />
          )}
        </DrawerFromBottom>
      )}

      {isPromocodeModalOpen && (
        <AddPromoCode
          open={isPromocodeModalOpen}
          setOpenPopup={setIsPromocodeModalOpen}
          handleClose={handleClosePromocodeModal}
          onSubmit={addPromocode}
        />
      )}

      {alert && (
        <TotalPyamentPopup
          dataflag={dataflag}
          setDataFlag={setDataFlag}
          alert={alert}
          setAlert={setAlert}
          promoCodeValue={promoCodeValue}
          closePopup={closePromocodeModal}
          planDetails={JSON.parse(localStorage.getItem("planDetails"))}
          isClose={false}
          paySecure={paySecure}
          investor_id={investorsID || invToken}
        />
      )}
      <Loader open={reqLoader || statesLoader} />
    </Box>
  );
};

export default CardList;
