import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Paper, Stack, Typography } from "@mui/material";

import { Loader } from "../../common/loader";
import TitleCompanyListTable from "./TitleCompanyListTable";
import RepresentativeListTable from "./RepresentativeListTable";
import BackArrow from "../../../assets/property-user-list/arrow_back.svg";
import { professionalAction } from "../../../redux/slices/admin/professionals/professionalsSlice";
const TitleCompany = () => {
  const dispatch = useDispatch();

  const [displayRepTable, setDisplayRepTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { titleCompanyLoader, titleCompanyListData } = useSelector(
    (state) => state.professionals
  );
  const handleViewRepresentativeBtn = (data) => {
    dispatch(
      professionalAction?.getRepListData({
        url: `admin/broker/get-representative/${data?.id}`,
        data: {},
        token: true,
      })
    );
    setDisplayRepTable(true);
  };
  const backToBrokerList = (data) => {
    brokersListAPICall();
    setDisplayRepTable(false);
  };
  const brokersListAPICall = () => {
    dispatch(
      professionalAction?.getTitleCompanyListData({
        url: `admin/broker/get-brokers`,
        data: {},
        token: true,
      })
    );
  };
  useEffect(() => {
    brokersListAPICall();
  }, [dispatch]);

  return (
    <Box>
      <Paper
        elevation={0}
        className=" d-flex justify-content-between"
        sx={{ background: "none" }}
      >
        <Box className="view-edit-page" sx={{ mb: 1 }}>
          {displayRepTable && (
            <Stack
              direction="row"
              spacing={1.5}
              alignItems="center"
              onClick={backToBrokerList}
            >
              <img
                alt="back arrow"
                src={BackArrow}
                height="12px"
                width="12px"
                className="cursor-pointer"
              />
              <Typography className="back-to-user-list">
                Back to Affiliate Companies
              </Typography>
            </Stack>
          )}
          <Typography
            variant="h6"
            className={`card-title fw-600 ${displayRepTable && "mt-3"}`}
          >
            {displayRepTable ? selectedRow?.full_name : "Affiliate Companies"}
          </Typography>
        </Box>
        {/* <Box className="">
              <Button
                className="success-btn admin_buttons"
                variant="contained"
                // onClick={() => {
                //   setOpenPopup(true);
                // }}
              >
                Add Recording Fees
              </Button>
            </Box> */}
      </Paper>
      {displayRepTable ? (
        <RepresentativeListTable />
      ) : (
        <TitleCompanyListTable
          handleViewRepresentativeBtn={handleViewRepresentativeBtn}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
        />
      )}
      <Loader open={titleCompanyLoader} />
    </Box>
  );
};

export default TitleCompany;
