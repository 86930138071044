import React, { useState } from "react";

import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Box, Stack, Typography } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import { ROLES, userTimeZone } from "../../helpers/constants";
import DownloadCSV from "../exportCSV/downloadCSV";
import { decryptPayload, getObjLength } from "../../helpers/utils";
import AdminFreeMonitoringListTable from "./AdminFreeMonitoringListTable";
import { exportCSVDetialsAction } from "../../../redux/slices/export-csv/exportcsvSlice";
import AdminProeprtyRiskReport from "./AdminProeprtyRiskReport";
import { propertyRiskManagementAction } from "../../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

const AdminFreeMonitoring = () => {
  const [propertiesIDList, setPropertiesIDList] = useState([]);
  const [deletePropertyModal, setDeletePropertyModal] = useState(false);
  const [isRiskReportForm, setIsRiskReportForm] = useState(false);
  const [selectAllFlag, setSelectAllFlag] = useState(false);
  const [csv_Payload, setCSV_Payload] = useState([]);
  const [isRiskReport, setIsRiskReport] = useState(false);

  const dispatch = useDispatch();

  const handleDeleteProperty = (action) => {
    setDeletePropertyModal(true);
  };
  let county_admin = decryptPayload(localStorage.getItem("roles"))?.includes(
    ROLES.countyRecorder
  );

  const { exportCsvLoader, exportCSVFileData } = useSelector(
    (state) => state.exportCSVDetails
  );

  const handleExportCSV = () => {
    console.log("payload-->", csv_Payload);
    dispatch(
      exportCSVDetialsAction.exportCSVFile({
        url: "export-property-monitoring-csv",
        data: { ...csv_Payload, user_time_zone: userTimeZone },
        token: true,
      })
    );
  };

  const handleCloseDownloadModal = (action) => {
    dispatch(exportCSVDetialsAction.getExportCSVFile({}));
  };

  const handleRiskReportForm = () => {
    setIsRiskReportForm(true);
  };
  const closeSubscribeModal = (action) => {
    if (action === "list_table") {
      setIsRiskReportForm(false);
    }
    setIsRiskReport(false);
    dispatch(propertyRiskManagementAction.clearPropertyRiskManagementData({}));
    dispatch(propertyRiskManagementAction.fetchSubscribeFreeMonitoring({}));
  };

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6" className="title">
          {isRiskReportForm ? "Property Risk Report" : "Property Monitoring"}
        </Typography>
        {isRiskReportForm ? (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {isRiskReport && (
              <Button
                className="success-btn d-none d-sm-block mx-2 admin_buttons"
                onClick={() => {
                  closeSubscribeModal();
                }}
              >
                Return to Form
              </Button>
            )}
            <Button
              className="success-btn d-none d-sm-block mx-2 admin_buttons"
              onClick={() => {
                closeSubscribeModal("list_table");
              }}
            >
              Return to List
            </Button>
          </Stack>
        ) : !county_admin ? (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Button
              className="success-btn d-none d-sm-block mx-2 admin_buttons"
              onClick={handleRiskReportForm}
            >
              Search Property Report
            </Button>
            <Box className="">
              <Button
                className="success-btn d-none d-sm-block mx-2 admin_buttons"
                onClick={() => {
                  handleExportCSV();
                }}
              >
                Export CSV
              </Button>
              <Button
                className="success-btn d-flex d-sm-none"
                onClick={() => {
                  handleExportCSV();
                }}
                startIcon={<IosShareIcon />}
                sx={{ color: "#FFF" }}
              >
                Export
              </Button>
            </Box>
            {propertiesIDList?.length > 0 && (
              <Button
                className="d-none d-sm-block popup_Btn admin_buttons"
                style={{ backgroundColor: "#f43455", borderRadius: "25px" }}
                onClick={() => {
                  console.log("On delete click");
                  handleDeleteProperty();
                }}
              >
                Delete Properties
              </Button>
            )}
          </Stack>
        ) : null}
      </Stack>
      <Box
        sx={{
          mt: 2,
        }}
      >
        {isRiskReportForm ? (
          <>
            <AdminProeprtyRiskReport
              isRiskReport={isRiskReport}
              setIsRiskReport={setIsRiskReport}
              setIsRiskReportForm={setIsRiskReportForm}
              closeSubscribeModal={closeSubscribeModal}
            />
          </>
        ) : (
          <AdminFreeMonitoringListTable
            setPropertiesIDList={setPropertiesIDList}
            propertiesIDList={propertiesIDList}
            setDeletePropertyModal={setDeletePropertyModal}
            deletePropertyModal={deletePropertyModal}
            handleDeleteProperty={handleDeleteProperty}
            selectAllFlag={selectAllFlag}
            setSelectAllFlag={setSelectAllFlag}
            setCSV_Payload={setCSV_Payload}
          />
        )}
      </Box>

      {/* Export CSV */}
      {getObjLength(exportCSVFileData) && (
        <DialogBox
          width="md"
          style={{ backgroundColor: "#f6f9fc" }}
          contentClassName={""}
          openPopup={true}
          onClose={() => {
            dispatch(exportCSVDetialsAction.clearData({}));
          }}
          content={
            <DownloadCSV
              onClose={(action) => {
                handleCloseDownloadModal(action);
              }}
              exportCsvData={exportCSVFileData}
            />
          }
        />
      )}

      <Loader open={exportCsvLoader} />
    </>
  );
};

export default AdminFreeMonitoring;
