/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { Loader } from "../../common/loader";
import { ROLES } from "../../helpers/constants";
import AlertDialog from "../../common/AlertDialog";
import { decryptPayload } from "../../helpers/utils";
import CardSection from "../../common/StripeCardForm";
import Aurthorised from "../../../assets/price_F&Q/aurthorize.png";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const AddCardForm = ({
  openPopup,
  loader,
  fromAdmin = false,
  uid = null,
  onClose = () => {},
  handleCloseAddCardModal = () => {},
  callListingAPI = () => {},
  isOpenFromBottom = false,
}) => {
  const [open, setOpen] = useState(false);
  const [stripeErr, setStripeErr] = useState("");
  const [retry, setRetry] = useState(false);
  const [alert, setAlert] = useState("");
  const [errAlert, setErrAlert] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  // store data
  const { addNewCard, cardDetails, userProfileDetails } = useSelector(
    (state) => state?.userProfile
  );
  const { investorReportResultData, invToken } = useSelector(
    (state) => state?.propertyRiskManagement
  );

  useEffect(() => {
    handleClose();
  }, [addNewCard, cardDetails]);

  useEffect(() => {
    console.log("openPopup called==>>", openPopup);
    clearStates();
  }, []);

  const clearStates = () => {
    setOpen(false);
    setStripeErr("");
    setRetry(false);
  };
  const handleClose = (err) => {
    console.log(err, "stripeErr==>", err?.length);
    if (Object.keys(addNewCard).length > 0 && !err) {
      setAlert(addNewCard?.success);
      setErrAlert(addNewCard?.success);
      setRetry(true);
    } else {
      setRetry(false);
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const validateErr = (event) => {
    console.log("validate error==>", event);
    setStripeErr("");
  };
  const handleSubmit = async (values) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    handleToggle();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      console.error(result.error.message);
      setStripeErr(result.error.message);
      handleClose(result.error.message);
    } else {
      setStripeErr("");
      let data = { token: result.token.id };

      stripeTokenHandler(data);
    }
  };

  const stripeTokenHandler = (data) => {
    console.log("Stripe Token Handler==>>", data);
    if (fromAdmin) {
      dispatch(
        userProfileAction.addNewCard({
          url: `admin/users/${uid}/card`,
          data: data,
          token: true,
        })
      );
    } else {
      if (
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        ) &&
        !invToken
      ) {
        dispatch(
          userProfileAction.addNewCard({
            url: "service-provider/card/create",
            data: data,
            token: true,
          })
        );
      } else if (
        userProfileDetails?.data?.user_type?.includes(ROLES?.professional) &&
        !userProfileDetails?.data?.role?.includes(ROLES?.customer) &&
        !invToken
      ) {
        dispatch(
          userProfileAction.addNewCard({
            url: "professional/card/create",
            data: data,
            token: true,
          })
        );
      } else {
        let url = "customer/card/create";
        let payload = data;
        if (invToken) {
          url = "investor-save-card";
          payload.investor_token = invToken;
          payload.user_id = investorReportResultData?.data?.user?.id;
        }
        console.log(url, "-----", payload);

        dispatch(
          userProfileAction.addNewCard({
            url,
            data: payload,
            token: true,
          })
        );
      }
    }
  };
  const tryAgain = () => {
    setRetry(false);
  };
  console.log("isOpenFromBottom", isOpenFromBottom);
  const cardAdded = () => {
    handleToggle();
    handleCloseAddCardModal();
    if (addNewCard?.success && !fromAdmin && !invToken) {
      if (
        userProfileDetails?.data?.user_type?.includes(ROLES?.professional) &&
        !userProfileDetails?.data?.role?.includes(ROLES?.customer)
      ) {
        dispatch(
          userProfileAction.userCardDetails({
            url: "professional/card/list",
            data: {},
            token: true,
          })
        );
      } else {
        dispatch(
          userProfileAction.userCardDetails({
            url: "customer/card/list",
            data: {},
            token: true,
          })
        );
      }
    }
    if (fromAdmin || invToken) {
      callListingAPI();
      onClose();
    }
  };
  return (
    <>
      <Box className="p-3">
        {fromAdmin && (
          <Button
            onClick={() => onClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        )}
        {retry ? (
          <>
            {addNewCard?.success && !isOpenFromBottom && (
              <AlertDialog
                type="Success"
                openPopup={alert && !isOpenFromBottom}
                onClose={true}
                mesage={addNewCard?.message}
                loader={open}
                onCloseFun={cardAdded}
                buttons={
                  <Button
                    className="popup_Btn success_popup_btn"
                    onClick={cardAdded}
                  >
                    Ok, All Good
                  </Button>
                }
              />
            )}
            {!errAlert && !isOpenFromBottom && (
              <AlertDialog
                type="Error"
                openPopup={!errAlert && !alert && !isOpenFromBottom}
                onClose={true}
                mesage={addNewCard?.data?.message}
                onCloseFun={tryAgain}
                buttons={
                  <Button
                    className="popup_Btn error_popup_btn "
                    onClick={tryAgain}
                  >
                    Try again
                  </Button>
                }
              />
            )}
            {addNewCard?.success && isOpenFromBottom && (
              <PlainAlertMessage
                type="Success"
                openPopup={alert}
                onClose={true}
                message={addNewCard?.message}
                loader={open}
                onCloseFun={cardAdded}
                buttons={
                  <Button
                    className="popup_Btn success_popup_btn"
                    onClick={cardAdded}
                  >
                    Ok, All Good
                  </Button>
                }
              />
            )}
            {!errAlert && isOpenFromBottom && (
              <PlainAlertMessage
                type="Error"
                openPopup={!errAlert && !alert}
                onClose={true}
                message={addNewCard?.data?.message}
                onCloseFun={tryAgain}
                buttons={
                  <Button
                    className="popup_Btn error_popup_btn "
                    onClick={tryAgain}
                  >
                    Try again
                  </Button>
                }
              />
            )}
          </>
        ) : (
          <Box>
            <CardSection stripeErr={stripeErr} validateErr={validateErr} />
            <div className="text-center stepBtn mt-5">
              <Button
                disabled={!stripe}
                type="submit"
                className="next_button mb-3"
                variant="contained"
                onClick={handleSubmit}
              >
                Next Step
              </Button>
            </div>
            <div className="text-center">
              <hr />
              <img src={Aurthorised} alt="Aurthorised" />
            </div>
          </Box>
        )}
      </Box>
      <Loader open={open || loader} />
    </>
  );
};

export default AddCardForm;
